// Scale of temperature mapping to colors
const TemperatureColorScale = [
  '#0000ff',
  '#0032ff',
  '#0064ff',
  '#0096ff',
  '#00c8ff',
  '#00ffff',
  '#32ff00',
  '#64ff00',
  '#c8ff00',
  '#ffff00',
  '#ffc800',
  '#ff9600',
  '#ff6400',
  '#ff3200',
  '#ff0000',
];

const BatteryColorScale = [
  '#e43932',
  '#f19d38',
  '#efe83b',
  '#5dcb44',
];

export const getTemperatureColor = (temperature) => {
  if (temperature <= -40) return TemperatureColorScale[0];
  if (temperature <= -30) return TemperatureColorScale[1];
  if (temperature <= -20) return TemperatureColorScale[2];
  if (temperature <= -10) return TemperatureColorScale[3];
  if (temperature <=   0) return TemperatureColorScale[4];
  if (temperature <=  10) return TemperatureColorScale[5];
  if (temperature <=  20) return TemperatureColorScale[6];
  if (temperature <=  30) return TemperatureColorScale[7];
  if (temperature <=  40) return TemperatureColorScale[8];
  if (temperature <=  50) return TemperatureColorScale[9];
  if (temperature <=  60) return TemperatureColorScale[10];
  if (temperature <=  70) return TemperatureColorScale[11];
  if (temperature <=  80) return TemperatureColorScale[12];
  if (temperature <=  90) return TemperatureColorScale[13];
  return TemperatureColorScale[14];
};

export const getBatteryColor = (battery) => {
  if (battery <= 25) return BatteryColorScale[0];
  if (battery <= 50) return BatteryColorScale[1];
  if (battery <= 75) return BatteryColorScale[2];
  return BatteryColorScale[3];
};

export default getTemperatureColor;
