import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { fetchAuthenticated, get } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { useAccessLog } from 'hooks/logs';
import { logAction } from 'utils/logs';

import {
  Typography,
  Button,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper';

import ModalConfirm from 'components/modals/ModalConfirm';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  paper: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    marginTop: theme.main.padding.top,
    marginLeft: theme.main.padding.left,
    marginRight: theme.main.padding.right,
    marginBottom: theme.main.padding.bottom,
    borderRadius: theme.border.radius,
    alignItems: 'center',
  },
  submitButton: {
    minWidth: 125,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    boxShadow: 'unset',
    minHeight: 38,
    marginTop: 40,
  },
  text: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
    maxWidth: 800,
  }
}));

export default function DisableRF(props) {
  useAccessLog('Acesso a ferramenta de desabilitar RF');
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [enabledRfNumber, setEnabledRfNumber] = useState(0);

  const onConfirm = async () => {
    try {
      await fetchAuthenticated('post', 'equipment/command-disable-rf', {}, true);
      logAction(`Enviou comando para desativar RF`);
      emitEvent('showSnack', { message: 'Comandos RF enviados.', type: 'success' });
    } catch (e) {
      emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
      console.log(e);
    }
    setOpenModal(false);
  }

  useEffect(() => {
    const fetch = async () => {
      const enabledRfNumber = await fetchAuthenticated(get, 'equipment/rf-enabled-number');
      setEnabledRfNumber(enabledRfNumber.count);
    }
    fetch();
  }, []);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.text}>
          {
            `Esta função irá identificar todos os equipamentos configurados com RF
            ligado e irá gerar um comando para inativação preservando as outras
            configuraçães/comandos já definidos.`
          }
        </Typography>
        <Button
          className={classes.submitButton}
          type="submit"
          color="primary"
          variant="contained"
          size="small"
          onClick={() => setOpenModal(true)}
        >Desativar</Button>
        <ModalConfirm
          isOpen={openModal}
          setIsOpen={setOpenModal}
          onConfirm={onConfirm}
          title="Você tem certeza que deseja desativar RF?"
        >
          <Typography className={classes.text}>
            {`Ao confirmar, RF será desativado em ${enabledRfNumber} equipamento(s).`}
          </Typography>
        </ModalConfirm>
      </Paper>
    </div>
  )
}
