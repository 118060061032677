import React from "react";
import {
  TextField,
} from '@material-ui/core'
import Typography  from "@material-ui/core/Typography";
import MaskedInput, { conformToMask } from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import CommandPv1Gprs from './CommandPv1Gprs';
import CommandPv2Gprs from './CommandPv2Gprs';
import CommandPv1Admin from './CommandPv1Admin';
import CommandPv2Admin from './CommandPv2Admin';

import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

export const CommandGprs = (props) => {
  const { lastEvent, showStatus } = props;
  const { isVisible, setIsVisible } = props;
  let protocolVersion = lastEvent && lastEvent.protocolVersion;

  if(isVisible) {
    if(protocolVersion === 'V2') {
      return (<CommandPv2Gprs showStatus={showStatus} setIsVisible={setIsVisible} {...lastEvent} />);
    } else {
      return (<CommandPv1Gprs showStatus={showStatus} setIsVisible={setIsVisible} {...lastEvent} />);
    }
  }
  return null;
}

export const CommandAdmin = (props) => {
  const { lastEvent, showStatus } = props;
  const { isVisible, setIsVisible } = props;
  let protocolVersion = lastEvent && lastEvent.protocolVersion;

  if(isVisible) {
    if(protocolVersion === 'V2') {
      return (<CommandPv2Admin showStatus={showStatus} setIsVisible={setIsVisible} {...lastEvent} />);
    } else {
      return (<CommandPv1Admin showStatus={showStatus} setIsVisible={setIsVisible} {...lastEvent} />);
    }
  }
}

const TimeMask = [/\d/, /\d/, ':', /\d/, /\d/];
const TimeMaskInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={TimeMask}
      guide={false}
    />
  );
};

export const CommandTextField = (props) => {
  const {name, value, setValue, showStatus, timeMask, disabled} = props;
  let { currentStatus } = props;


  const useStyles = makeStyles(theme => ({
    input: {
      borderBottom: theme.input.borderStyle.borderBottom,
    },
    textField: {
      minHeight: showStatus && 40 || 68,
      marginRight: 10,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%'
      }
    },
    statusLabel: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_5,
      paddingRight: 6,
    },
    statusValue: {
      fontSize: 16,
      color: theme.palette.gray.gray_5,
    },
  }));
  const classes = useStyles();

  const InputProps = {
    classes: {
      root: classes.input,
    },
    disableUnderline: true,
  }

  if (timeMask) {
    InputProps.inputComponent = TimeMaskInput;
    setValue(String(value).padStart(4, '0'));
    if(currentStatus) {
      currentStatus = String(currentStatus).padStart(4, '0');
      currentStatus = conformToMask(currentStatus, TimeMask).conformedValue;
    }
  }

  return (
    <div>
      <TextField
        className={classes.textField}
        name={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={InputProps}
        style={{...(props.style || {})}}
        disabled={disabled}
      />
      {showStatus &&
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Typography className={classes.statusLabel}>
            Status atual
          </Typography>
          <Typography className={classes.statusValue}> {currentStatus} </Typography>
        </div>
      }
    </div>
  )
}
CommandTextField.defaultProps = {
  timeMask: false,
  disabled: false,
}

export const CommandTimePicker = (props) => {
  const {label, value, setValue, currentStatus, showStatus} = props;

  const useStyles = makeStyles(theme => ({
    inputLabel: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_4,
    },
    timePicker: {
      borderBottom: theme.input.borderStyle.borderBottom,
      minHeight: 'unset',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    statusLabel: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_5,
      paddingRight: 6,
    },
    statusValue: {
      fontSize: 16,
      color: theme.palette.gray.gray_5,
    },

  }));
  const classes = useStyles();

  return (
    <div>
      <p className={classes.inputLabel}>{label}</p>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TimePicker
          clearable
          ampm={false}
          value={value}
          onChange={(value) => {
            setValue(value);
          }}
          InputProps={{
            disableUnderline: true,
          }}
          className={classes.timePicker}
        />
      </MuiPickersUtilsProvider>
      {showStatus &&
        <div style={{display: 'flex', flexDirection: 'row', marginTop: 7}}>
          <Typography className={classes.statusLabel}>
            Status atual
          </Typography>
          <Typography className={classes.statusValue}> {currentStatus} </Typography>
        </div>
      }
    </div>
  );
}
