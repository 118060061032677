import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {TableColumn} from 'components/table/TableMenu';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from 'assets/icon/lixeira.svg';
import ReactSVG from 'react-svg'

const useStyles = makeStyles(theme => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  removeIcon: {
    width: 47,
  },
}));

export default function RemoveColumn(props) {
  const { row, onClick } = props
  const classes = useStyles();
  return (
    <TableColumn
      key={row._id}
      row={row}>
      <div className={classes.iconContainer}>
        <IconButton
          style={{ boxShadow: 'unset' }}
          title="Remover"
          className={classes.removeIcon}
          onClick={() => onClick(row._id)}>
            <ReactSVG
              src={RemoveIcon}
            />
        </IconButton>
      </div>
    </TableColumn>
  );
}

RemoveColumn.defaultProps = {
  onChange: () => null,
};
