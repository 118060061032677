import { getItem } from './local-storage';

export function validatePermission(permission) {
  if(!permission) return true;
  const permissions = getItem('permissions') || [];
  return permissions.some((p) => p.name === permission);
}

export function validatePermissions(permissions) {
  for(const permission of permissions) {
    if (validatePermission((permission))) {
      return true
    }
  }
  return false;
}

export function filterPage(page) {
  if(page.permissions) {
    return validatePermissions(page.permissions);
  } else if(page.permission) {
    return validatePermission(page.permission);
  }
  return true;
}
