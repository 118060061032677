// Form validation based on
// https://medium.com/code-monkey/client-side-form-validation-in-react-40e367de47ba
import validator from 'validator';

export const Rule = (method, args, validWhen, message, formatFn) => {
  return {
    method, args, validWhen, message,
    run(value) {
      const m = typeof method === 'string' ? validator[method] : method;
      formatFn = formatFn ? formatFn : (value) => value.toString();
      if (m(formatFn(value),...args) !== validWhen) {
        return {isInvalid: true, message: message};
      } else {
        return {isInvalid: false, message: ''};
      }
    }
   }
}

export const validate = (value, rules) => {
  for (const rule of rules) {
    const validation = rule.run(value)
    if (validation.isInvalid) { return validation }
  }
  return {isInvalid: false, message: ''}
}
