import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Link,
} from '@material-ui/core';
import { get, fetchAuthenticated, putWithErrorHandler } from 'services/fetch';
import { emitEvent } from 'utils/events';
import Modal from 'components/modals/Modal';
import { getUser, updateUser } from 'services/user';
import { useEffectSkipFirst } from 'hooks/common';
import { datetimeFormatter } from 'utils/formatters';

import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from 'assets/icon/lixeira.svg';
import ReactSVG from 'react-svg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 55,
    marginBottom: 55,
  },
  fenceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 24,
    paddingTop: 24,
    borderBottom: 'solid 1px #8084934d'
  },
  fenceLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_5,
  },
  fenceDescription: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
  },
  remove: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',
  }
}));

const ModalFence = (props) => {
  const { isVisible, setIsVisible, equipment, setHasFence } = props;
  const classes = useStyles();

  const [ fences, setFences] = useState([]);
  const [ showModal, setShowModal ] = useState(isVisible);
  const [ isLoading, setIsLoading] = useState(true);
  const [ refresh, setRefresh] = useState(false);


  useEffect(() => {
    if(equipment) {
      const fn = async () => {
        setIsLoading(true);
        const _data = await fetchAuthenticated(get, `fence/get-by-equipment/${equipment._id}`);
        setFences(_data);
        setHasFence(_data.length > 0);
        setIsLoading(false);
      }
      fn();
    }
  }, [equipment, isVisible, refresh]);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const onClose = () => {
    if(setIsVisible) {
      setIsVisible(false);
    }
  }

  const onRemove = async (fence) => {
    setIsLoading(true);
    const data = {
      'equipments': fence.equipments.filter((
        equip => equip._id !== equipment._id
      )).map(equip => equip._id),
    }

    const successMessage = 'Equipamento removido da cerca.';
    const errorMessage = 'Erro ao tentar atualizar o equipamento.';
    const logMessage = 'Desassociar equipamento de cerca.';
    const response = await putWithErrorHandler('fence/'+fence._id, data, {
      successMessage, errorMessage, logMessage
    });
    setIsLoading(false);
    setRefresh(!refresh);
  }

  return (
    <Modal
      isVisible={showModal}
      setIsVisible={setShowModal}
      title='Cercas do equipamento'
      isLoading={isLoading}
      onClose={onClose}
      hideButton
      onSuccessMessage="Follow realizado com sucesso."
    >
      <div className={classes.root}>
        <div className={classes.container}>
          {fences.length === 0 &&
            <div style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
              <ReactSVG
                src={require('assets/icon/placeholder_notifications.svg')}
                onClick={() => {
                  console.log('wrapper onClick')
                }}
              />
              <Typography style={{
                textAlign: 'center',
                fontSize: 16,
                color: '#8E96B0',
              }}>Nenhuma cerca associada à este equipamento.</Typography>
            </div>
          }
          {fences.map(fence => (
            <div key={fence._id} className={classes.fenceContainer}>
              <Typography className={classes.fenceLabel}>{fence.name}</Typography>
              <Typography className={classes.fenceDescription}>{fence.description}</Typography>
              <div className={classes.remove} onClick={() => { onRemove(fence) }}>
                <Typography className={classes.remove}>Remover associação</Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default ModalFence;
