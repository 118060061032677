import { buildQuery } from 'utils/query';
export default class FilterHandler {
  constructor(query, setQuery, location) {
    this.query = query;
    this.setQuery = setQuery;
    this.location = location;
  }

  concatFilters = newFilter => {
    const currentQueryFilter = this.query.queryObject.filter || {};
    return { ...currentQueryFilter, ...newFilter };
  };

  updateQueryFilter = newFilter => {
    newFilter = this.concatFilters(newFilter);
    this.setQuery(
      buildQuery(this.location, { ...this.query.queryObject, filter: newFilter, page: 1 }),
    );
  };

  removeFilter = key => {
    const currentQueryFilter = this.query.queryObject.filter || {};
    delete currentQueryFilter[key];
    this.setQuery(buildQuery(this.location, { ...this.query.queryObject }));
  };

  removeFilters = keys => {
    const currentQueryFilter = this.query.queryObject.filter || {};
    keys.forEach(k => delete currentQueryFilter[k]);
    this.setQuery(buildQuery(this.location, { ...this.query.queryObject }));
  };

  hasFilter = key => {
    return Object.keys(this.query.queryObject.filter || {}).indexOf(key) > -1;
  };

  getFilter = key => {
    return (this.queryObject.filter || {})[key];
  };

  addFilter = filter => {};
}
