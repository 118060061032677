import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle
} from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from './Text.field';
import MobilePhoneField from './Mobile.field';
import EmailField from './Email.field';
import { Rule, validate } from "utils/formValidator";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
}));

const EmergencyContact = forwardRef((props, ref) => {
  const classes = useStyles();
  // Name, Email, Mobile
  let [nameRef, emailRef, mobileRef] = [useRef(), useRef(), useRef()];

  // Set initial value for this field
  const setFieldValue = v => {
    nameRef.current.setFieldValue(v.name);
    emailRef.current.setFieldValue(v.email);
    mobileRef.current.setFieldValue(v.mobile);
  }

  // Run fiields validators
  const validateField = () => {
    nameRef.current.validateField();
    emailRef.current.validateField();
    mobileRef.current.validateField();
  }

  // Check if all fields are valid
  const _isInvalid = () => {
    return (
      nameRef.current.isInvalid  ||
      emailRef.current.isInvalid ||
      mobileRef.current.isInvalid
    );
  };

  // Check if all fields were already validated
  const _alreadyValidated = () => {
    return (
      nameRef.current.alreadyValidated  &&
      emailRef.current.alreadyValidated &&
      mobileRef.current.alreadyValidated
    );
  };

  // Return current field value
  const _value = () => {
    if (nameRef.current.value === '') return null;
    return ({
      name: nameRef.current.value,
      email: emailRef.current.value,
      mobile: mobileRef.current.value
    });
  };

  // Return current field value
  const _raw = () => {
    if (nameRef.current.value === '') return null;
    return ({
      name: nameRef.current.raw,
      email: emailRef.current.raw,
      mobile: mobileRef.current.raw
    });
  };

  // Exposed properties
  useImperativeHandle(ref, () => ({
    get value() { return _value(); },
    get raw() { return _raw(); },
    get isInvalid() { return _isInvalid(); },
    get alreadyValidated() { return _alreadyValidated(); },
    validateField,
    setFieldValue
  }));

  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} md={3}>
        <TextField
          label='Nome'
          ref={nameRef}
          fullWidth
          classes={{root: classes.fullWidth}}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <EmailField
          label='Email'
          ref={emailRef}
          fullWidth
          classes={{root: classes.fullWidth}}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MobilePhoneField
          label='Celular'
          ref={mobileRef}
          fullWidth
          classes={{root: classes.fullWidth}}
        />
      </Grid>
    </Grid>
  );
});

export default EmergencyContact;