import React from 'react';
import BaseList from 'components/lists/BaseList';
import { useAccessLog } from 'hooks/logs';

function ListEquipmentModule(props) {
  useAccessLog('Acesso ao cadastro de modelos');
  const { match } = props;
  return (
    <BaseList
      columns={[
        { value: row => row.name, label: 'Nome', sort: 'name' },
      ]}
      genericSearchFields={[
        { label: 'Nome', name: 'name' },
      ]}
      basePath="equipment-model"
      title="Lista de Modelos"
      addButtonText="Cadastrar Modelo"
      addUrl={`${match.path}/novo`}
    />
  );
}

export default ListEquipmentModule;
