import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography  from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { emitEvent } from 'utils/events';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import ReactSVG from 'react-svg'
import NavBar from 'components/NavBar';
import { pages } from './Home';

const useStyles = makeStyles(theme => ({
  paper: {
    ...theme.custom.paper.root,
    flex: 1,
    marginTop: 24,
    marginLeft: 36,
    marginRight: 36,
    marginBottom: 39,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ops: {
    color: theme.palette.gray.gray_4,
    fontWeight: 'bold',
    fontSize: 20,
  },
  errorMsg: {
    color: theme.palette.gray.gray_5,
    fontSize: 16,
  }
}));


function ErrorPage (props) {
  const classes = useStyles();
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', flex: 1, width: '100%',
    }}>
      <NavBar pages={pages} />
      <Paper classes={{ root: classes.paper }} elevation={0}>
        <ReactSVG
          // className={classes.cardIcon}
          src={require('assets/img/error.svg')}
        />
        <Typography className={classes.ops}>Ops!</Typography>
        <Typography className={classes.errorMsg}>Um erro ocorreu ao tentar carregar essa página.</Typography>

      </Paper>
    </div>
  )
}

ErrorPage.defaultProps = {
}

export default ErrorPage;

