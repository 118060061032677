import React from "react";
import MaskedInput, { conformToMask } from 'react-text-mask';

export const TimeMaskInput = (props) => {
  const TimeMask = [/\d/, /\d/, ':', /\d/, /\d/];
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={TimeMask}
      guide={false}
    />
  );
};

export const MaskedPercentage = (props) => {
  const PercentMask = [/\d/, /\d/, '%'];
  const getMask = (v) => {
    if(v.length === 1) {
      return [/\d/, '%'];
    } else {
      return PercentMask;

    }
  }
  return (
    <MyMaskedInput {...props} getMask={getMask}/>
  );
};

export const MaskedHour = (props) => {

  const Digit2DigitHourMask = [/\d/, /\d/, ' horas'];
  const Digit1DigitHourMask = [/\d/, ' horas'];
  const Digit1HourMask = [/\d/, ' hora'];
  const getMask = (v) => {
    if(v.length === 1) {
      if(v === '1') return Digit1HourMask;
      return Digit1DigitHourMask;
    } else {
      return Digit2DigitHourMask;

    }
  }
  return (
    <MyMaskedInput {...props} getMask={getMask}/>
  );
};

export const MaskedTrying = (props) => {

  const Digit2DigitTryingMask = [/\d/, /\d/, ' tentativas'];
  const Digit1DigitTryingMask = [/\d/, ' tentativas'];
  const Digit1TryingMask = [/\d/, ' tentativa'];
  const getMask = (v) => {
    if(v.length === 1) {
      if(v === '1') return Digit1TryingMask;
      return Digit1DigitTryingMask;
    } else {
      return Digit2DigitTryingMask;

    }
  }
  return (
    <MyMaskedInput {...props} getMask={getMask}/>
  );
};

export const MyMaskedInput = (props) => {
  const { inputRef, getMask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask= {(value) => {
        return getMask(value);
      }}
      guide={false}
    />
  );
}
