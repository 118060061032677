import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';

import CompanyForm from 'components/forms/CompanyForm';
import ModalRemove from 'components/modals/ModalRemove';
import { logAction } from 'utils/logs';
import { alertError, alertSucccess } from 'utils/alerts';

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

function EditCompany(props) {
  const classes = useStyles();
  const { history, match, setBreadcrumbs, basicEdit } = props;
  const [company, setCompany] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  // Use base company url
  const companyURL = `company/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    if(setBreadcrumbs) {
      setBreadcrumbs([
        { path: `/cadastros/empresas`, label: `Empresas` },
        { path: ``, label: `${company && company.name || ''}` }
      ]);
      return () => { setBreadcrumbs([]); }
    }
  }, [setBreadcrumbs, match.params.id, company]);

  // Load company info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');

      const response = await fetchAuthenticated('get', companyURL);

      // Any erro will redirect to companies list
      if (response.status >= 400) {
        alertError('Não foi possível realizar esta ação');
        history.push('/cadastros/empresas');
      } else {
        const data = await response.json();
        // Update fields
        data['cpf_cnpj'] = data.cpf || data.cnpj;

        setCompany(data);
        logAction(`Acesso ao cadastro do empresa ${data.name}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, companyURL])

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', companyURL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();
      data['cpf_cnpj'] = data.cpf || data.cnpj;

      setCompany(data);
      logAction(`Edição da empresa ${data.name}`);
      alertSucccess('Empresa atualizada com sucesso');
    }
  }

  // Handle cancel button click
  const handleCancel = (event) => {
    history.push('/cadastros/empresas');
  }

  // Handle remove button click
  const handleRemove = (event) => {
    setShowDialog(true);
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', companyURL);

      if (response.status >= 400) {
        const body = await response.json();
        let message = 'Não foi possível remover a empresa';
        if (body.message) {
          ({ message } = body);
        }
        alertError(message, 5000);
      } else {
        logAction(`Remoção da empresa ${company.name}`);
        alertSucccess('Empresa removida com sucesso', 5000);
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/empresas');
      }
    }
  }

  // Render component
  return (
    <div>
      <CompanyForm
        title='Detalhes da Empresa'
        data={company}
        handleSubmit={handleSubmit}
        handleCancel={!basicEdit && handleCancel}
        handleRemove={!basicEdit && handleRemove}
        basicEdit={basicEdit}
      />

      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='da empresa'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='a empresa'
        extraContent='Ao realizar está ação a empresa não estará mais disponível'
      />
    </div>
  );
}

export default EditCompany;
