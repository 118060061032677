import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import { saveAs } from 'file-saver';
import theme from 'styles/theme';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import Filter from 'components/filter/Filter';
import DatePicker from 'components/pickers/DatePicker';
import FilterAutoComplete from 'components/filter/FilterAutoComplete';

import { useQuery } from 'hooks/fetch';
import { useAccessLog } from 'hooks/logs';
import { usePaginatedData } from 'hooks/common';
import { fetchAuthenticated } from 'services/fetch';
import { updateQueryFilter } from 'utils/query';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';

import { REPORT_COLUMNS } from './columns';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
  filter: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const PATH = 'logs';
function LogsReport(props) {
  useAccessLog('Acesso ao relatórios de logs');
  const classes = useStyles();
  const [data, setData] = usePaginatedData([]);

  const [dataInitalDateFilled, setDataInitalDateFilled] = useState(false);
  const [dataFinalDateFilled, setDataFinalDateFilled] = useState(false);

  const [query, setQuery, isLoading] = useQuery(
    PATH,
    setData,
    ['description', 'user', 'ip', 'date'],
    {
      filter: { 'isSystemLog': false }, sort: { date: -1 },
    },
    q => {
      if(!dataInitalDateFilled|| !dataFinalDateFilled) {
        return false;
      }
      return true;
    }
  );
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const exportSpreadsheet = async (query) => {
    setIsGeneratingReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', { message: 'Exportação limitada a 5000 itens', type: 'info' });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/xls${query.queryString}`,
    );
    logAction('Exportou relatório de logs em XLS')
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Logs.xls');
    setIsGeneratingReport(false);
  };

  const exportPdf = async (query) => {
    setIsGeneratingReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', { message: 'Exportação limitada a 5000 itens', type: 'info' });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/pdf${query.queryString}`,
    );
    logAction('Exportou relatório de logs em PDF')
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Logs.pdf');
    setIsGeneratingReport(false);
  };

  const onDateChange = ({ date, filterHandler }, comparator) => {
    if (!(query && query.queryObject)) return;

    const filter = query.queryObject.filter;
    let filterDate = {};
    if (filter && filter.date) {
      filterDate = { ...filter.date };

      // Remove older filter
      try {
        delete filterDate[comparator];
      } catch (err) {
        console.log(err);
      }
    }

    if (date) {
      filterDate[comparator] = date;
    }

    if (Object.keys(filterDate).length) {
      filterHandler.updateQueryFilter({
        date: filterDate
      })
    } else {
      filterHandler.removeFilter('date');
    }
  };

  const onCompanyChange = ({ opt, filterHandler, fields, isLoading, setIsLoading }) => {
    fields.users.current.resetField();
    setIsLoading({ ...isLoading, users: true });

    if (!opt) {
      filterHandler.removeFilters(['user.company', 'user._id']);
    } else {
      filterHandler.updateQueryFilter({ 'user.company': opt._id})
    }
  };

  const onUserChange = ({ opt, filterHandler }) => {
    if (!opt) {
      filterHandler.removeFilter('user._id');
    } else {
      filterHandler.updateQueryFilter({ 'user._id': opt._id })
    }
  }

  const whenUserIsLoading = ({ fetchUrl, path, setFetchUrl, fields }) => {
    const companyField = fields.companies.current.value;
    if (companyField) {
      setFetchUrl(
        updateQueryFilter(fetchUrl, {
          'company._id': companyField._id,
        }),
      );
    } else {
      setFetchUrl(path);
    }
  }

  const _filters = [
    {
      name: 'companies',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{ marginTop: 5 }}
          placeholder="Empresa"
          showPlaceholder
          path="company?notPaginated=true&fields=_id,name"
          name="companies"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={onCompanyChange}
        />
      ),
    },
    {
      name: 'users',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{ marginTop: 5 }}
          placeholder="Usuário"
          showPlaceholder
          path="user?notPaginated=true"
          name="users"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando usuários..."
          noOptionsMessage="Nenhum usuário encontrado."
          onChange={onUserChange}
          whenIsLoading={whenUserIsLoading}
        />
      ),
    },
    {
      name: 'initialDate',
      component: args => (
        <DatePicker
          {...args}
          placeholder="Data ínicio"
          onChange={({ date, filterHandler }) => {
            setDataInitalDateFilled(date ? true : false);
            onDateChange({ date, filterHandler }, '$gte')
          }}
          alwaysStartOfDay
        />
      ),
    },
    {
      name: 'finalDate',
      component: args => (
        <DatePicker
          {...args}
          placeholder="Data término"
          onChange={({ date, filterHandler }) =>  {
            setDataFinalDateFilled(date ? true : false);
            onDateChange({ date, filterHandler }, '$lte')
          }}
          className={classes.filter}
          alwaysEndOfDay
        />
      ),
    },
  ];

  const _tableHeaderActions = [
    <TableHeaderAction
      title="Exportar XLS"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>XLS</Typography>}
      onClick={() => exportSpreadsheet(query)}
    />,
    <TableHeaderAction
      title="Exportar PDF"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>PDF</Typography>}
      onClick={() => exportPdf(query)}
    />,
  ];

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <Filter
          noSearch
          hideButton
          path={PATH}
          query={query}
          setQuery={setQuery}
          asyncFields={_filters}
          containerStyle={{ paddingLeft: 0, paddingTop: 0 }}
        />
      </Grid>
      <Table
        columns={REPORT_COLUMNS}
        data={data}
        query={query}
        setQuery={setQuery}
        containerStyle={{ marginTop: 0 }}
        isLoading={isLoading || isGeneratingReport}
        HeaderComponent={<TableHeader headerActions={_tableHeaderActions} />}
      />
    </Grid>
  );
}

LogsReport.propTypes = {
};

LogsReport.defaultProps = {
};

export default LogsReport;
