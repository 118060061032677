import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { TextField } from '@material-ui/core';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { Rule, validate } from "../../utils/formValidator";

const phoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const PhoneInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={phoneMask}
      guide={false}
    />
  );
};

const PhoneField = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const [ value, setValue ] = useState('');

  const [ validations, setValidations ] = useState({
    rules: [
      Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
      Rule('matches', [/\(\d\d\) \d\d\d\d-\d\d\d\d/], true, 'Formato inválido para o campo'),
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const onChange = e => { setValue(e.target.value) }
  const onBlur = () => { validateField() }

  const validateField = () => {
    let currentRules = (props.required || value) ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(value, currentRules)})
  }

  const setFieldValue = (value) => {
    if (value) {
      setValue(conformToMask(value, phoneMask).conformedValue);
    }
  }

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: value.replace(/\D/g, ''),
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue
  }));

  return (
    <TextField
      {...props}
      ref={inputRef}
      InputProps={{inputComponent: PhoneInput}}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={validations.isInvalid}
      helperText={validations.isInvalid && validations.message || ''}
    />
  )
});

export default PhoneField
