import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import 'moment/locale/pt-br';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 16,
    padding: '4px 0px 4px 15px',
  },
  input: {
    '&::placeholder': {
      fontWeight: 600,
      color: theme.palette.gray.gray_1,
      opacity: 1,
      fontSize: 14,
    },
  },
  helperText: {
    display: 'none',
  },
  formControl: {
    minHeight: 'auto',
  },
}));

const DEFAULT_PROPS = {
  invalidDateMessage: 'Formato inválido',
  autoOk: true,
  disableToolbar: true,
  format: 'DD/MM/YYYY',
  variant: 'inline',
};

function DatePicker(props) {
  const classes = useStyles();
  const { alwaysEndOfDay, alwaysStartOfDay } = props;
  const [selectedDate, setSelectedDate] = useState(props.value || null);

  const onChange = (date, value) => {
    if (date) {
      if (alwaysEndOfDay) {
        date.endOf('day');
      }
      if (alwaysStartOfDay) {
        date.startOf('day');
      }
    }
    setSelectedDate(date);

    const { onChange: _onChange, filterHandler, fields } = props;
    _onChange &&
      _onChange({
        filterHandler,
        fields,
        date,
        value,
      });
  };

  const onAccept = (date) => {
    props.onAccept(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
      <KeyboardDatePicker
        {...DEFAULT_PROPS}
        id="mui-pickers-date"
        label={props.label}
        placeholder={props.placeholder}
        value={selectedDate}
        onChange={onChange}
        onAccept={onAccept}
        maxDate={props.maxDate}
        minDate={props.minDate}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.root,
            input: classes.input,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        margin="dense"
        className={props.className}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.defaultProps = {
  onChange: (moment, text) => null,
  onAccept: date => null,
  placeholder: 'Data',
  alwaysEndOfDay: false,
  alwaysStartOfDay: false,
};

export default DatePicker;
