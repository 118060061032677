import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import {
  Close
} from '@material-ui/icons';
import { Rule, validate } from "../../utils/formValidator";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    marginTop: 12,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  buttonLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: theme.typography.fontWeight['700'],
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  textRoot: {
    minHeight: 'auto'
  }
}));

const FileUpload = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    required,
    onLoad = () => null
  } = props;

  const reader = useRef(new FileReader());
  const [file, setFile] = useState(null);
  const [validations, setValidations] = useState({
    rules: [
      Rule(
        'isEmpty', [], false,
        'Campo de preenchimento obrigatório',
         value => value ? value.name : ''
      )
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  useEffect(() => {
    reader.current.addEventListener('load', onLoad);
    return () => {
      reader.current.removeEventListener('load', onLoad);
    }
  }, []);

  useEffect(() => {
    if (file instanceof Blob) {
      reader.current.readAsDataURL(file);
    }
  }, [file]);

  const validateField = () => {
    const _rules = (required || file) ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(file, _rules)
    });
  };

  async function urltoFile({url, filename, mimetype}){
    try {
      const res = await fetch(url);
      const buf = await res.arrayBuffer();
      setFile(new File([buf], filename, {type: mimetype}));
    } catch (err) {
      console.log(err);
    }
  }

  const setFieldValue = async (value) => {
    if (value && value.url && value.mimetype && value.filename) {
      await urltoFile(value);
    } else {
      console.log(`${value} is not a valid value`);
    }
  }

  useImperativeHandle(ref, () => ({
    value: file,
    raw: !file ? {} : {filename: file.name, mimetype: file.type, url: reader.current.result},
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue
  }));

  useEffect(() => {
    validateField();
  }, [file]);

  const handleFile = async ({ target }) => {
    setFile(target.files[0]);
  };

  const removeFile = () => {
    setFile(null);
  };

  return (
    <Grid container direction="column">
      <TextField
        label='Logo da empresa'
        value={(file && file.name) || ''}
        classes={{ root: classes.textRoot }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          disableUnderline: !file,
          endAdornment: file && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={removeFile}>
                <Close />
              </IconButton>
            </InputAdornment>
          )
        }}
        inputProps={{ style: { display: !file ? 'none': 'block' } }}
        error={validations.isInvalid}
      />
      <input
        type='file'
        id='imageUpload'
        style={{ display: 'none' }}
        accept='image/*'
        onChange={handleFile}
      />
      <label htmlFor="imageUpload">
        <Button
          disableRipple={true}
          component='span'
          color='primary'
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabel,
          }}>Adicionar imagem</Button>
      </label>
    </Grid>
  );
});

export default FileUpload;
