import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Paper, Divider } from "@material-ui/core";

const formStyles = theme => ({
  root: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  paperRoot: {
    padding: "30px 40px 35px 40px"
  },
  paperRounded: {
    borderRadius: 22
  },
  formTitle: {
    marginBottom: 15,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gray.gray_4,
    fontSize: 22,
    lineHeight: 'normal',
  },
});

const _Form = (props) => {
  const { classes, paperStyle, containerStyle } = props
  return (
    <div className={classes.root} style={containerStyle}>
      <Typography
        variant='h6'
        className={classes.formTitle}
      >{ props.title }</Typography>

      <Paper
        elevation={0}
        classes={{root: classes.paperRoot, rounded: classes.paperRounded}}
        style={paperStyle}
      >
        {props.children}
      </Paper>
    </div>
  )
};
_Form.defaultProps = {
  paperStyle: {},
  containerStyle: {},
}

export const Form = withStyles(formStyles)(_Form);
export default Form;

const formSectionStyles = theme => ({
  formSectionTitle: {
    color: theme.palette.gray.gray_6,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 30,
    fontSize: 20,
  },
  formSectionDividerLight: {
    backgroundColor: theme.palette.gray.gray_6,
    opacity: 0.3,
    marginTop: 35,
    marginBottom: 35,
  },
});

const _FormSection = (props) => {
  const { classes } = props;
  return (
    <section>
      <Typography
        className={classes.formSectionTitle}
      >
      {props.title}</Typography>
      {props.children}
      {props.divider && <Divider
        light={true}
        classes={{light: classes.formSectionDividerLight}}/>
      }
    </section>
  )
};

export const FormSection = withStyles(formSectionStyles)(_FormSection);
