import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { post, put } from 'services/fetch';
import Modal from 'components/modals/Modal';
import VehiclePlateField from 'components/fields/VehiclePlate.field';
import { logAction } from 'utils/logs';
import { useFields } from "hooks/fields";
import { emitEvent } from 'utils/events';

import * as moment from 'moment';

const useStyles = makeStyles(theme => ({
}));

const ModalEditVehicle = (props) => {
  const { isVisible, setIsVisible, equipment, onSubmit } = props;
  const [vehicle, setVehicle] = useState(equipment.vehicle);
  const classes = useStyles();

  const fields = useFields(['vehicle']);

  useEffect(() => {
    setVehicle(equipment.vehicle);
  }, [equipment])


  const handleSubmit = async (f) => {
    const _fields = { ...fields };
    _fields.vehicle.current.validateField();

    if (_fields.vehicle.current.isInvalid) return false;

    setIsVisible(false);
    const vehicle = _fields.vehicle.current.raw;
    try {
      if (!vehicle) {
        logAction(`Removeu a placa do equipamento ${equipment.shortImei}`);
      } else {
        logAction(`Alterou a placa do equipamento ${equipment.shortImei} para ${vehicle}`);
      }
      const response = await put('equipment/' + equipment._id, {
        vehicle
      });
      emitEvent('showSnack', { message: "Veículo alterado com sucesso", type: 'success' });
      return true;
    } catch (e) {
      emitEvent('showSnack', { message: "Erro ao tentar alterar o veículo.", type: 'error' });
      return false;
    } finally {
      onSubmit();
    }
  }

  return (
    <Modal
      handleSubmit={() => handleSubmit(fields)}
      onSuccessMessage="Veículo alterado com sucesso"
      onErrorMessage="Erro ao tentar alterar o veículo."
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      title="Editar veículo"
      buttonLabel="Salvar"
      onClose={() => null}
      subtitle="As placas devem estar no formato brasileiro antigo [ABC-1234] ou mercosul [ABC1D23]"
      preventDefaultSubmit
    >
      <VehiclePlateField
        ref={fields.vehicle}
        label="Veículo"
        name="vehicle"
        defaultValue={equipment.vehicle}
        // onChange={onChange}
        containerStyle={{
          marginTop: 60,
        }}
      />
    </Modal>
  )
}

export default ModalEditVehicle;
