import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  btnRoot: {
    minWidth: 125,
    boxShadow: 'unset',
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    // Small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export function ButtonSubmit(props) {
  const { disabledCondition, onSubmit, label } = props;
  const classes = useStyles();

  return (
    <Button
      classes={{ root: classes.btnRoot }}
      type="submit"
      color="primary"
      variant="contained"
      size="small"
      onClick={onSubmit}
      disabled={disabledCondition}
    >
      {label}
    </Button>
  );
}
ButtonSubmit.defaultProps = {
  label: 'Salvar',
}
