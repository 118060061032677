import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { post, put } from 'services/fetch';
import Modal from 'components/modals/Modal';
import { Grid, Button, InputAdornment, Typography } from '@material-ui/core';
import { AutocompleteField, RadioGroupField } from '../fields';
import { useFetch, useAuthenticated } from 'hooks/fetch';
import { useFields } from "hooks/fields";
import { TextField } from '../fields'

const useStyles = makeStyles(theme => ({
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
    marginBottom: 10,
  },
}));

const ModalEquipmentEdit = (props) => {
  const { equipments, onSubmit, setVisibleModal } = props;
  const [ isVisible, setIsVisible] = useState(props.isVisible);
  const classes = useStyles();

  const [ isLoading, setIsLoading ] = useState({
    mobileOperators: false,
    colors: false,
    exemplaries: false,
    packings: false,
    models: false,
  });

 const fields = useFields([
    'color',
    'packing',
    'model',
    'exemplary',
    'antiJamming',
    'battery',
    'mobileOperator',
  ])

  useEffect(() => {
    setIsVisible(props.isVisible);
  }, [props.isVisible]);

  // Load colors
  const { data: colors } = useAuthenticated(useFetch, 'equipment-color/list', {
    beforeFetch() { setIsLoading({...isLoading, colors: true}); },
    afterFetch() { setIsLoading({...isLoading, colors: false}); },
    defaultValue: [],
  });

  // Load exemplaries
  const { data: exemplaries } = useAuthenticated(useFetch, 'equipment-exemplary/list', {
    beforeFetch() { setIsLoading({...isLoading, exemplaries: true}); },
    afterFetch() { setIsLoading({...isLoading, exemplaries: false}); },
    defaultValue: [],
  });

  // Load colors
  const { data: models} = useAuthenticated(useFetch, 'equipment-model/list', {
    beforeFetch() { setIsLoading({...isLoading, models: true}); },
    afterFetch() { setIsLoading({...isLoading, models: false}); },
    defaultValue: [],
  });

  // Load packings
  const { data: packings } = useAuthenticated(useFetch, 'equipment-packing/list', {
    beforeFetch() { setIsLoading({...isLoading, packings: true}); },
    afterFetch() { setIsLoading({...isLoading, packings: false}); },
    defaultValue: [],
  });

  // Load mobile operators
  const { data: mobileOperators } = useAuthenticated(useFetch, 'equipment-mobile-operator/list', {
    beforeFetch() { setIsLoading({...isLoading, mobileOperators: true}); },
    afterFetch() { setIsLoading({...isLoading, mobileOperators: false}); },
    defaultValue: [],
  });

  const handleSubmit = async () => {
    setIsVisible(false);
    setVisibleModal('Edit', false);

    // Get all fiels
    const data = {}
    for (const [key, value] of Object.entries(fields)) {
      if(value.current.raw) {
        // remove empty fields
        data[key] = value.current.raw;
      }
    }
    data.ids = equipments;

    try {
      const response = await put('equipment/updateMany', data);
      return true;
    } catch(e) {
      console.log(e);
      return false;
    } finally {
      if(onSubmit) {
        onSubmit();
      }
    }
  }
  const onClose = () => {
    setVisibleModal('Edit', false);
  }
  return (
    <Modal
      handleSubmit={handleSubmit}
      onSuccessMessage="Equipamentos editados com sucesso."
      onErrorMessage="Erro ao tentar editar equipamentos."
      isVisible={isVisible} setIsVisible={setIsVisible} title="Editar equipamentos em massa" buttonLabel="Salvar"
      onClose={onClose}
    >
      <Typography className={classes.subheader}>
        Os campos vazios serão desconsiderados.
      </Typography>

      <Grid container justify='flex-start' spacing={2} style={{width: '100%'}}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.exemplary}
            label='Exemplar de equipamento'
            name='exemplary'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={exemplaries}
            isLoading={isLoading.exemplaries}
            noOptionsMessage={() => 'Nenhum exemplar encontrado'}
            loadingMessage={() => 'Carregando exemplares...'}
            isClearable={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.model}
            label='Modelo'
            name='model'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={models}
            isLoading={isLoading.models}
            noOptionsMessage={() => 'Nenhum modelo encontrado'}
            loadingMessage={() => 'Carregando modelos...'}
            isClearable={true}
          />
        </Grid>
      </Grid>
      <Grid container justify='flex-start' spacing={2} style={{width: '100%'}}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.packing}
            label='Embalagem'
            name='packing'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={packings}
            isLoading={isLoading.packings}
            noOptionsMessage={() => 'Nenhuma embalagem encontrada'}
            loadingMessage={() => 'Carregando embalagem...'}
            isClearable={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.color}
            label='Cor'
            name='color'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={colors}
            isLoading={isLoading.colors}
            noOptionsMessage={() => 'Nenhuma cor encontrada'}
            loadingMessage={() => 'Carregando cores...'}
            isClearable={true}
          />
        </Grid>
      </Grid>
      <Grid container justify='flex-start' spacing={2} style={{width: '100%'}}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.mobileOperator}
            label='Operadora'
            name='mobileOperator'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={mobileOperators}
            isLoading={isLoading.mobileOperators}
            noOptionsMessage={() => 'Nenhuma operadora encontrada'}
            loadingMessage={() => 'Carregando operadoras...'}
            isClearable={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            ref={fields.battery}
            fullWidth
            className={classes.fullWidthField}
            label='Bateria (mAh)'
            name='battery'
          />
        </Grid>
      </Grid>
      <Grid container justify='flex-start' spacing={2} style={{width: '100%'}}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <RadioGroupField
            ref={fields.antiJamming}
            row
            title="Anti-Jammer"
            options={[
              { value: 'Não', label: 'Não' },
              { value: 'Sim', label: 'Sim' },
            ]}
            labelProps={{
              style: {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
              },
            }}
            formControlLabelProps={{
              labelPlacement: 'start',
            }}
          />
        </Grid>
     </Grid>
    </Modal>
  )
}

export default ModalEquipmentEdit;
