import * as moment from 'moment';

export const datetimeFormatter = (datetime, format = 'DD/MM/YYYY HH:mm') => {
  if (datetime) {
    return moment(datetime).format(format);
  }
  return '-';
};

export const durationFormatter = (duration, format = 'HH:mm', unit = 'minutes') => {
  if (duration) {
    const _duration = moment.duration(Number(duration), unit);
    return moment.utc(_duration.as('milliseconds')).format(format);
  }
  return '-';
};

export const percentageFormatter = (percentage) => {
  if (percentage || percentage == 0) {
    return Number(percentage).toLocaleString('pt-BR', {
      style: 'percent',
    });
  }
  return '-';
};

export const temperatureFormatter = (temperature) => {
  if (temperature || temperature == 0) {
    return `${parseFloat(temperature).toFixed(1)}ºC`;
  }
  return '-';
};

export const latlongFormatter = (latitude, longitude) => {
  latitude = String(latitude || '-').substring(0, 8);
  longitude = String(longitude || '-').substring(0, 8);
  return `${latitude} / ${longitude}`;
}

export const speedFormatter = (speed) => {
  if (speed || speed == 0) {
    return `${parseFloat(speed).toFixed(1)}Km/h`;
  }
  return '-';
};

export const defaultFormatter = (value, defaultValue = '-') => {
  if (typeof value === 'undefined' || value === null) {
    return defaultValue;
  }
  return value;
};

export const mapPoffStatus = (poffCommand) => {
  if (poffCommand) {
    switch (poffCommand.status) {
      case 'COMPLETED':
        return 'Expirado';
      case 'RECEIVED':
        return 'Agendado';
      case 'SCHEDULED':
        return 'Aguardando ativação';
      case 'CANCELED':
        return 'Cancelado';
      case 'RUNNING':
        return 'Em execução';
      default:
        return '-';
    }
  } else {
    return '-';
  }
};

export const imeiFormatter = (equipment) => {
  if (!equipment) return '-';
  return equipment.imei.substring(equipment.imei.length, equipment.imei.length - 8);
}

export const poffFormatter = equipment => {
  if (!equipment) return '- / -';
  if (!equipment.poffCommands || !equipment.poffCommands.length) return '- / -';
  return `${equipment.poffCommands[0].runningDays}/${equipment.poffCommands[0].totalDays}`
}
