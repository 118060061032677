import React, { useState, useEffect } from 'react';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import withStyles from '@material-ui/core/styles/withStyles';

import { subscribeEvent } from '../utils/events';

function Snack(props) {
  const { classes } = props;
  const [showSnack, setSnack] = useState({
    isOpen: false,
    message: '',
    type: 'info',
    autoHide: 2500,
  });

  useEffect(() => {
    subscribeEvent('showSnack', (e) => {
      setSnack({
        isOpen: true,
        message: e.detail.message,
        type: e.detail.type,
        autoHide: e.detail.autoHide || 2500,
      });
    });
  }, []);

  const closeSnack = (event, reason) => {
    setSnack({ isOpen: false, message: '' });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showSnack.isOpen}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      onClose={closeSnack}
      autoHideDuration={showSnack.autoHide}
    >
      <SnackbarContent
        className={classes[showSnack.type || 'info']}
        message={(
          <span className={classes[showSnack.type || 'info']} id="message-id">
            {showSnack.message}
          </span>
)}
      />
    </Snackbar>
  );
}

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
});

export default withStyles(styles)(Snack);
