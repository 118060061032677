import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export function StatusBall(props) {
  const { status, containerStyle } = props;
  const colorStatus = {
    active: '#5bf293',
    suspect: '#ffc500',
    emergency: '#e44a4a',
  };
  return (
    <div
      style={{
        height: 20,
        width: 20,
        marginRight: 5,
        backgroundColor: colorStatus[status],
        borderRadius: '50%',
        ...containerStyle,
      }}
    />
  )
}

StatusBall.defaultProps = {
  containerStyle: {},
}

export default function StatusColumn(props) {
  const { status, edit } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <StatusBall status={status}/>
      {edit &&
        <ExpandMoreIcon />
      }
    </div>
  );
}

StatusColumn.defaultProps = {
  status: 'emergency',
  edit: true,
};
