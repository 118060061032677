import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import SimpleTable from "components/table/Table";
import Filter from "components/filter/Filter";
import FilterAutoComplete from "components/filter/FilterAutoComplete";
import { InputAdornment } from "@material-ui/core";
import Search from "@material-ui/icons/Search";

import { useFetch, useAuthenticated } from "hooks/fetch";
import { buildUrl, defaultQuery } from "utils/query";
import { emitEvent } from "utils/events";

const styles = theme => ({
  searchIcon: {
    color: theme.palette.gray.gray_5
  }
});

function EventReport(props) {
  const { classes, columns, location } = props;
  const path = "equipment-event";

  const [query, setQuery] = useState(defaultQuery);
  const [fetchPath, setFetchPath] = useState(buildUrl(path, location));

  // Fetch and re-fetch data every time `fetchPath` changes
  const { data } = useAuthenticated(useFetch, fetchPath, {
    beforeFetch: () => {
      emitEvent("showGlobalLinearProgress");
    },
    afterFetch: () => {
      emitEvent("hideGlobalLinearProgress");
    }
  });

  useEffect(() => {
    // prevents from doing 2 fetch`s
    if (query.queryString) {
      setFetchPath(`${path}${query.queryString}`);
    }
  }, [query]);

  return (
    <div>
      <Filter
        hideLabel={true}
        showPlaceholder={true}
        searchPlaceHolder="Buscar"
        path={path}
        query={query}
        setQuery={setQuery}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.searchIcon}>
              <Search />
            </InputAdornment>
          )
        }}
        genericSearchFields={[
          { label: "Imei", name: "imei" },
          { label: "Local", name: "local" }
        ]}
      />
      <SimpleTable
        columns={columns}
        path={path}
        setQuery={setQuery}
        query={query}
        data={data}
        containerStyle={{
          marginTop: 0,
          marginLeft: 34,
          marginRight: 25
        }}
      />
    </div>
  );
}

export default withRouter(withStyles(styles)(EventReport));
