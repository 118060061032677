import React, { useEffect } from 'react';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';

import CompanyForm from 'components/forms/CompanyForm';
import { logAction } from 'utils/logs';

export default function NewCompany(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/empresas', label: 'Empresas' },
      { path: '', label: 'Nova Empresa' }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs])

  // Handle form submit
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('post', 'company', data);


    if (!response.ok) {
      throw new Error('');
    } else {
      const data = await response.json();
      emitEvent('showSnack', { message: 'Empresa criada com sucesso', type: 'success' });
      logAction(`Criação da empresa ${data.name}`);
      history.push(`/cadastros/empresas/${data._id}`);
    }
  }

  // Handle cancel click
  const handleCancel = async () => {
    history.push('/cadastros/empresas');
  }

  return (
    <div>
      <CompanyForm
        title='Nova Empresa'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
