import React from 'react';

import SubRoutes from "components/SubRoutes";
import NewCompany from "pages/Records/Companies/New";
import EditCompany from "pages/Records/Companies/Edit";
import ListCompany from "pages/Records/Companies/List";

export default function Companies (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Empresas",
      path: '/nova',
      component: NewCompany,
    },
    {
      name: "Empresas",
      path: '/:id',
      component: EditCompany,
    },
    {
      name: "Empresas",
      path: '',
      component: ListCompany,
    },
  ]
  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
