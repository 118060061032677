import React, { useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Slider from '@material-ui/core/Slider';
import Popover from '@material-ui/core/Popover';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { TextField, RadioGroupField } from '../fields';
import ButtonLink from 'components/buttons/ButtonLink';
import Modal from 'components/modals/Modal';

import { useFields } from "hooks/fields";
import { fetchAuthenticated, get } from 'services/fetch';
import { emitEvent } from 'utils/events';

import { Stage, Layer, Rect, Text, Image, Circle, Line } from "react-konva";
import ReactSVG from 'react-svg'
import useImage from 'use-image';

const useStyles = makeStyles(theme => ({
  listItemLabel: {
    fontSize: 16,
    color: "#41475a",
    fontWeight: "bold"
  },
  fullWidthField: {
    maxWidth: '85%',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    borderRadius: 8,
  },
  popoverContainer: {
    width: 248,
  },
  popoverHeader: props => ({
    width: '100%',
    backgroundColor: props.color,
    height: 68,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  popoverContent: {
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 28,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popoverIcon: {
  },
  popoverBlueLabel: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  popoverLabel: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.gray.gray_5,
  },
  popoverValue: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
    fontWeight: '600',
  },
  popoverAction: {
    borderTop: `solid 1px rgba(128, 132, 146, 0.3)`,
    width: '100%',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popoverCloseIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'white',
  },
}));

const MySlider = withStyles({
  root: {
    paddingBottom: 20,
  },
  thumb: {
    boxShadow: '0 2px 7px 0 #0000004e',
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    // marginTop: -8,
    // marginLeft: -12,
    // '&:focus,&:hover,&$active': {
      // boxShadow: 'inherit',
    // },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
    // top: -22,
    marginTop: 57,
    color: '#41475a',
    fontSize: 16,
    fontWeight: '600',
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 6,
    borderRadius: 3,
    color: '#548cc1',
  },
  rail: {
    height: 6,
    borderRadius: 3,
    color: '#d9dade',
  },
})(Slider);

const circleRadius = 7;

const Icons = {
  'Cavalo': require('assets/icon/cavalo.svg'),
  'Carreta': require('assets/icon/carreta.svg'),
  'Isca': require('assets/icon/pin.svg'),
}

const circles = [
  {_id: 1, x: 69, y: 168,  radius: circleRadius, fill: '#b2b7c7'},
  {_id: 2, x: 305, y: 210, radius: circleRadius, fill: '#b2b7c7'},
  {_id: 3, x: 310, y: 110, radius: circleRadius, fill: '#b2b7c7'},
  {_id: 4, x: 350, y: 110, radius: circleRadius, fill: '#b2b7c7'},
  {_id: 5, x: 390, y: 110, radius: circleRadius, fill: '#b2b7c7'},
];

const getHeaderColor = (equip1, equip2) => {
  if(!equip1 || !equip2) {
    return '#41475a';
  }
  return equip1._id === equip2._id && '#548cc1' || '#41475a';
}


const BeaconTruck = (props) => {
  const { equipment, possibleEquipments, setIsLoading } = props;
  const [beacons, setBeacons] = useState([]);
  const [image] = useImage(require('assets/img/truck.png'));

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverPosition, setIsPopoverPosition] = useState({x: 0, y: 0});
  const [currentPopoverEquipment, setCurrentPopoverEquipment] = useState();
  const [currentPopoverBeacon, setCurrentPopoverBeacon] = useState();
  const [showPopoverSlider, setShowPopoverSlider] = useState(false);
  const [popoverDistance, setPopoverDistance] = useState(50);

  /* used to keep position in screen information */
  const [possibleEquipmentsWithPosition, setPossibleEquipmentsWithPosition] = useState([]);
  const [beaconsWithPosition, setBeaconsWithPosition] = useState([]);
  const [equipmentWithPosition, setEquipmentWithPosition ] = useState({
    position: {x: -10, y: -10}
  });

  const classes = useStyles({
    color: getHeaderColor(currentPopoverEquipment, equipment),
  });

  const slotsCounter = useRef(0);

  const positions = {
    'Cavalo': () =>  { return { x: 69, y: 168 }},
    'Carreta': () => { return { x: 305, y: 210 }},
    'Isca': (counterRef) => {
      return {
        x: 310 + (counterRef.current++ * 40), y: 110
      }
    },
  }

  /* calculate position in screen */
  useEffect(() => {
    slotsCounter.current = 0;

    try {
      const position = positions[equipment.equipmentType.name](slotsCounter);
      setEquipmentWithPosition({
        ...equipment, position,
      });

      const _beacons = [];
      possibleEquipments.forEach((equip) => {
        _beacons.push({
          ...equip,
          position: positions[equip.equipmentType.name](slotsCounter),
        })
      });
      setPossibleEquipmentsWithPosition(_beacons);
    } catch (e) {
      console.log('Equipment type is not one of ["Cavalo", "Carreta","Isca"]');
      console.log(e);
    }
  }, [possibleEquipments]);

  // Load beacons
  useEffect(() => {
    if (equipment) {
      loadBeacons();
    }
  }, [equipment]);

  useEffect(() => {
    setBeaconsWithPosition(beacons.map(beacon => {
      const destinyPosition = possibleEquipmentsWithPosition.filter(e => e._id === beacon.beacon)[0];
      return {
        ...beacon,
        position: destinyPosition.position,
      }
    }));
  }, [beacons]);

  const loadBeacons = async () => {
    setIsLoading(true);
    try {
      (async () => {
        const response = await fetchAuthenticated(
          'get',
          `beacons/${equipment._id}`
        );
        if (!response.ok) {
          setBeacons([]);
        } else {
          const data = await response.json();
          setBeacons(data);
        }
      })();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const SlotsPlaceholder = (props) => {
    return (
      <>
        {circles.map((circle) => (
          <Circle
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
            onClick={() => {}}
            key={circle._id}
            {...circle}
          ></Circle>
        ))}
      </>
    )
  }

  const onAnchor = async (equip) => {
    setIsLoading(true);
    // console.log(popoverDistance);
    const beacon = {
      type: 'system',
      beacon: equip.imei,
      distance: popoverDistance,
      equipment: equipment.imei,
    }
    const response = await fetchAuthenticated('post', 'beacons', beacon);
    if(response.ok) {
      emitEvent('showSnack', { message: 'Equipamento ancorado.', type: 'success' });
    }
    setIsPopoverOpen(false);
    loadBeacons();
  }

  const handleRemoveBeacon = async (beaconToRemove) => {
    try {
      setIsLoading(true);
      const response = await fetchAuthenticated(
        `delete`,
        `beacons/${beaconToRemove._id}`
      );
      if (response.ok) {
        emitEvent('showSnack', { message: 'Beacon removido.', type: 'success' });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsPopoverOpen(false);
      loadBeacons();
    }
  };

  /* reset everything when popup is closed */
  useEffect(() => {
    if(!isPopoverOpen) {
      setShowPopoverSlider(false);
      setCurrentPopoverBeacon();
      // setCurrentPopoverEquipment();
      setShowPopoverSlider(false);
    }
  }, [isPopoverOpen]);

  const onPopoverClose = () => {
    setIsPopoverOpen(false);
  }

  const onCircleClick = (e, equip) => {
    setIsPopoverPosition({
      x: e.evt.clientX,
      y: e.evt.clientY,
    })
    setIsPopoverOpen(true);
    setCurrentPopoverEquipment(equip);
    const currentBeacon = beacons.filter(b => b.beacon === equip._id);
    if(currentBeacon.length) {
      setCurrentPopoverBeacon(currentBeacon[0]);
    }
  }

  const renderPopooverContent = (props) => {
    if(currentPopoverEquipment && currentPopoverEquipment._id === equipment._id) {
      return (
        <>
          <Typography className={classes.popoverBlueLabel}>Este equipamento</Typography>
          <Typography className={classes.popoverLabel}>IMEI</Typography>
          <Typography className={classes.popoverValue} style={{marginBottom: 18}}>
            {currentPopoverEquipment.shortImei}
          </Typography>
        </>
      )
    }

    if(currentPopoverBeacon) {
      return (
        <>
          <Typography className={classes.popoverLabel}>IMEI</Typography>
          <Typography className={classes.popoverValue}>{currentPopoverEquipment.shortImei}</Typography>
          <Typography className={classes.popoverLabel} style={{paddingTop: 16}}>DISTÂNCIA</Typography>
          <Typography className={classes.popoverValue}>{currentPopoverBeacon.distance}</Typography>
          <div className={classes.popoverAction}>
            <ButtonLink
              onClick={() => {
                handleRemoveBeacon(currentPopoverBeacon);
              }}
              title="Desancorar"
            />
          </div>
        </>
      )
    }

    if(!showPopoverSlider) {
      return (
        <>
          <Typography className={classes.popoverLabel}>IMEI</Typography>
          <Typography className={classes.popoverValue}>{currentPopoverEquipment.shortImei}</Typography>
          <div className={classes.popoverAction}>
            <ButtonLink
              onClick={() => {
                setShowPopoverSlider(true);
              }}
              title="Ancorar"
            />
          </div>
        </>

      )
    }

    if(showPopoverSlider) {
      return (
        <>
          <Typography className={classes.popoverLabel}>RAIO MÁXIMO (METROS)</Typography>
          <MySlider
            defaultValue={popoverDistance}
            onChange={(e, value) => {
              setPopoverDistance(value);
            }}
            // getAriaValueText={valuetext}
            valueLabelDisplay="on"
            min={0}
            max={10000}
          />
          <div className={classes.popoverAction}>
            <ButtonLink
              onClick={() => {
                onAnchor(currentPopoverEquipment);
              }}
              title="Salvar"
            />
          </div>
        </>
      )
    }
  }

  return (
    // <div>
     <div className={classes.center} >
      <Stage width={558} height={281}>
        <Layer>
          <Image image={image} ></Image>
            {beaconsWithPosition.map((beacon) => (
              <Line
                key={beacon._id}
                stroke="#548cc1"
                points={[
                  beacon.position.x, beacon.position.y,
                  equipmentWithPosition.position.x, equipmentWithPosition.position.y,
                ]}
              />
            ))}
             {/* // bezier */}
            {/* // <Line */}
            {/* //   points={[100, 20, 200, 80, 230, 200, 250, 20, 500, 90]} */}
          <SlotsPlaceholder/>
          <Circle
            onMouseEnter={() => {
              document.body.style.cursor = "pointer";
            }}
            onMouseLeave={() => {
              document.body.style.cursor = "default";
            }}
            onClick={(e) => {
              onCircleClick(e, equipment);
            }}
            x={equipmentWithPosition.position.x}
            y={equipmentWithPosition.position.y}
            radius={circleRadius}
            fill="#548cc1"
          />

          {possibleEquipmentsWithPosition.map(((equip, index) => (
            <Circle
              key={equip._id + index}
              onMouseEnter={() => {
                document.body.style.cursor = "pointer";
              }}
              onMouseLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={(e) => {
                onCircleClick(e, equip);
              }}
              x={equip.position.x}
              y={equip.position.y}
              radius={circleRadius}
              fill="#41475a"
            />
          )))}

       </Layer>
      </Stage>

      <Popover
        open={isPopoverOpen}
        anchorReference="anchorPosition"
        anchorPosition={{ top: popoverPosition.y, left: popoverPosition.x }}
        classes={{
          paper: classes.paper,
        }}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/* <Paper > */}
        {currentPopoverEquipment &&
          <div className={classes.popoverContainer}>
            <div className={classes.popoverHeader}>
              <ReactSVG
                className={classes.popoverIcon}
                src={Icons[currentPopoverEquipment.equipmentType.name]}
              />

              <IconButton className={classes.popoverCloseIcon} onClick={onPopoverClose}>
                <CloseIcon />
              </IconButton>
              {/* <img className={classes.popoverIcon} ></img> */}
            </div>
            <div className={classes.popoverContent}>
              {renderPopooverContent()}
            </div>
          </div>
        }
        {/* </Paper> */}
      </Popover>
    </div>
  );
}
BeaconTruck.defaultProps = {

};

function ModalBeacon({ setIsAnchored, equipment, isVisible, ...rest }) {
  const [isLoading, setIsLoading] = useState(true);
  const [possibleEquipments, setPossibleEquipments] = useState([]);


   // Load possible beacons
  useEffect(() => {
    if (equipment && equipment.vehicle) {
      (async () => {
        const response = await get(
          'equipment?notPaginated=true&fields=_id,imei,shortImei,equipmentType',
          {
            'currentCompany._id': {value: equipment.currentCompany._id, toObjectId: false},
            $expr: { $ne: ["$_id", equipment._id]},
            'equipmentType.name': { $in: ['Cavalo', 'Carreta', 'Isca']},
            vehicle: {$exists: true, $eq: equipment.vehicle},
            // vehicle: equipment.vehicle,
            // vehicle: {$exists: true}
            // vehicle: {
            //   $ne: undefined,
            //   $ne: null,
            //   $ne: '',
            //   $eq: equipment.vehicle ,
            // },
          },
          '&',
        );
        setPossibleEquipments(response.data);
      })();
    }
  }, [equipment]);

  useEffect(() => {
    const loadBeacons = async () => {
      try {
        (async () => {
          if(equipment) {
            const response = await fetchAuthenticated(
              'get',
              `beacons/${equipment._id}`
            );
            const data = await response.json();
            if(data.length > 0) {
              setIsAnchored(true);
            }
          }
       })();
      } catch (err) {
        console.log(err);
      }
    }
    loadBeacons();
  }, [equipment]);


  return (
    <Modal
      title={`Veículo ${equipment.vehicle || ''}`}
      hideButton
      isLoading={isLoading}
      isVisible={isVisible}
      ModalHeaderProps={{
        style: {
          marginBottom: 35
        }
      }}
      ModalButtonContainerProps={{
        style: {
          marginTop: 35
        }
      }}
      {...rest}
    >
      <Typography
        style={{
          color: '#41475a',
          fontSize: 16,
          marginTop: -35,
          marginBottom: 50,
        }}
        >
        <Typography>Somente equipamentos do tipo: <b>Cavalo, Carreta e Isca</b> podem ser ancorados</Typography>
        <b>{possibleEquipments.length}</b>
          {' equipamentos encontrados'}
        </Typography>
      <BeaconTruck
        equipment={equipment}
        possibleEquipments={possibleEquipments}
        setIsLoading={setIsLoading}
      />
    </Modal>
  );
}

ModalBeacon.defaultProps = {
};

export default ModalBeacon;
