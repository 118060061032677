import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';

import FenceForm from 'components/forms/FenceForm';
import ModalRemove from 'components/modals/ModalRemove';
import { logAction } from 'utils/logs';

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

function EditFence(props) {
  const classes = useStyles();
  const { history, match, setBreadcrumbs } = props;
  const [fence, setFence] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  // Use base fence url
  const fenceURL = `fence/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: `/fence`, label: `Cercas` },
      { path: ``, label: `${fence && fence.name || ''}` }
    ]);
    return () => { setBreadcrumbs([]); }
  }, [setBreadcrumbs, match.params.id, fence]);

  // Load fence info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');

      const response = await fetchAuthenticated('get', fenceURL);

      // Any erro will redirect to companies list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cercas');
      } else {
        const data = await response.json();

        setFence(data);
        logAction(`Acesso ao cadastro do cerca ${data.name}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, fenceURL])

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', fenceURL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();
      setFence(data);
      logAction(`Edição da cerca ${data.name}`);
      emitEvent('showSnack', { message: 'Cerca atualizada com sucesso', type: 'success' });
    }
  }

  // Handle cancel button click
  const handleCancel = (event) => {
    history.push('/cercas');
  }

  // Handle remove button click
  const handleRemove = (event) => {
    setShowDialog(true);
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', fenceURL);

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover a cerca', type: 'error' });
      } else {
        logAction(`Remoção da cerca ${fence.name}`);
        emitEvent('showSnack', { message: 'Cerca removida com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cercas');
      }
    }
  }

  // Render component
  return (
    <div>
      <FenceForm
        title='Detalhes da Cerca'
        fence={fence}
        setFence={setFence}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
      />

      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='da cerca'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='a cerca'
        extraContent='Ao realizar está ação a cerca não estará mais disponível.'
      />
    </div>
  );
}

export default EditFence;
