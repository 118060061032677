import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditEquipmentMobileOperator from "pages/Records/EquipmentMobileOperators/Edit";
import ListEquipmentMobileOperator from "pages/Records/EquipmentMobileOperators/List";
import NewEquipmentMobileOperator from "pages/Records/EquipmentMobileOperators/New";

export default function EquipmentMobileOperators (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Operadoras",
      path: '/novo',
      component: NewEquipmentMobileOperator,
    },
    {
      name: "Operadoras",
      path: '/:id',
      component: EditEquipmentMobileOperator,
    },
    {
      name: "Operadoras",
      path: '',
      component: ListEquipmentMobileOperator
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
