import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from 'components/Breadcrumbs';
import { filterPage } from 'services/permissions';

const styles = theme => ({
  root: {},
  appBar: {
    backgroundColor: theme.palette.background.white,
    paddingLeft: theme.main.padding.left,
  },
  menuLink: {
    textDecoration: 'none',
    color: theme.palette.gray.gray_1,
    fontSize: 16,
    fontWeight: 'bold',
    border: 4,
    paddingBottom: 20,
  },
  item: {
    alignItems: 'flex-start',
    paddingRight: 45,
  },
  active: {
    borderBottom: `solid 2px ${theme.palette.text.blue.activeNavBar}`,
  },
  linearProgress: {
    marginTop: -2,
    left: 0,
    backgroundColor: theme.palette.gray.gray_2,
  },
});

function SubBar(props) {
  const { classes, match, breadcrumbs } = props;
  let { items } = props;

  items = items.filter(item => !item.hidden && filterPage(item));

  return (
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.appBar} position="static">
        {breadcrumbs && breadcrumbs.length ? (
          <Toolbar disableGutters={true}>
            <Breadcrumbs crumbs={breadcrumbs} />
          </Toolbar>
        ) : (
          <Toolbar disableGutters={true}>
            {items.map(item => (
              <Typography key={item.path} variant="h6" className={classes.item}>
                <NavLink
                  to={`${match.path}${item.path}`}
                  activeClassName={classes.active}
                  className={classes.menuLink}
                >
                  {item.name}
                </NavLink>
              </Typography>
            ))}
          </Toolbar>
        )}
      </AppBar>
    </div>
  );
}

SubBar.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default withStyles(styles)(SubBar);
