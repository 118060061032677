import { emitEvent } from 'utils/events';

const EVENT = 'showSnack';

const alert = (message, type, autoHide) => {
  emitEvent(EVENT, { message, type, autoHide });
};

export const alertError = (message, time = 2500) => {
  if (!message) throw new Error('Missing required message');
  alert(message, 'error', time);
};

export const alertSucccess = (message, time = 2500) => {
  if (!message) throw new Error('Missing required message');
  alert(message, 'success', time);
};

export const alertInfo = (message, time = 2500) => {
  if (!message) throw new Error('Missing required message');
  alert(message, 'info', time);
};
