import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { conformToMask } from 'react-text-mask';
import {
  PLATE_MODEL_1,
  PLATE_MODEL_2,
} from 'components/fields/VehiclePlate.field';

import Map from '../components/map/Map';

import { emitEvent } from 'utils/events';
import { getField, getPositionIndicator, getStatus, getBatteryStatus, boolToString, isRfOn, minutesToTimeString } from 'utils/helpers';
import { datetimeFormatter, poffFormatter } from 'utils/formatters';
import { useFetch, useAuthenticated } from "../hooks/fetch";
import { fetchAuthenticated, get } from 'services/fetch';

import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import AnchorIcon from '../assets/icon/anchor1.png';
import AnchorWhiteIcon from '../assets/icon/anchor2.png';
import FenceIcon from '../assets/icon/fence.png';
import FenceWhiteIcon from '../assets/icon/fence_white.png';
import FollowIcon from '../assets/icon/follow.png';
import FollowWhiteIcon from '../assets/icon/follow_white.png';
import CommandIcon from '../assets/icon/admin_command.png';
import LocationIcon from '../assets/icon/location.png';
import LocationWhiteIcon from '../assets/icon/location_white.png';
import HistoryIcon from '../assets/icon/history.png';
import ObsIcon from '../assets/icon/obs.png';
import ModalObs from 'components/modals/ModalObs';
import ModalVehicle from 'components/modals/ModalVehicle';
import ModalFollow from 'components/modals/ModalFollow';
import ModalFence from 'components/modals/ModalFence';
import ModalEquipmentHistory from 'components/modals/ModalEquipmentHistory';
import ModalBeacon from 'components/modals/ModalBeacon';
import Permission from 'components/permissions/Permission';
import { CommandGprs } from "../components/commands/Commands";
import CircularLoading from 'components/loading/CircularLoading';
import SubBar from 'components/SubBar';
import { getBreadcrumbsFor } from 'utils/breadcrumbs';
import { useAccessLog } from 'hooks/logs';

import {
  imeiFormatter,
} from 'utils/formatters';

const styles = theme => ({
  root: {
    position: 'relative',
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    paddingTop: theme.main.padding.top,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    fontSize: theme.text.header.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  headerIcon: {
    borderRadius: 14,
    color: theme.palette.gray_1,
    backgroundColor: theme.palette.background.white,
    width: 40,
    height: 40,
    padding: 0,
    marginRight: 10,
    boxShadow: '0 2px 12px 3px rgba(0, 0, 0, 0.08)',
  },
  activeHeaderIcon: {
    borderRadius: 14,
    color: theme.palette.gray_1,
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    padding: 0,
    marginRight: 10,
    boxShadow: '0 2px 12px 3px rgba(0, 0, 0, 0.08)',
  },
  mapGrid: {
    marginTop: theme.main.padding.top,
  },
  cardHeader: {
    paddingLeft: 27,
    paddingRight: 27,
    paddingBottom: 0,
  },
  cardContent: {
    paddingLeft: 27,
    paddingTop: 0,
    display: 'inline-grid',
  },
  cardHeaderTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  cardLineContainer: {
    display: 'inline-flex',
    marginTop: 10,
  },
  cardFieldLabel: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_5,
  },
  cardField: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.gray.gray_4,
  },
  cardInfo: {
    borderRadius: theme.border.radius,
    boxShadow: '0 4px 10px 7px rgba(0, 0, 0, 0.03)',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  cardHighlighted: {
    borderTopLeftRadius: theme.border.radius,
    borderTopRightRadius: theme.border.radius,
    width: '100%',
    height: 42,
    display: 'flex',
    alignItems: 'center',
  }
});

const requestEseyeAndCellIDLocation = async (equipment, setIsLoading, setEquipment) => {
  const response = await (await fetchAuthenticated('post', 'api/xglobal-locations', {
    equipment:  {
      lastEvent: equipment.lastEvent,
      _id: equipment._id,
    },
    iccid: equipment.iccid,
  })).json();
  if (!response.statusCode) {
    emitEvent('showSnack', { message: 'Posição eseye solicitada.', type: 'success' });
    setEquipment(response);
  } else {
    emitEvent('showSnack', { message: 'Posição eseye indisponível', type: 'error' });
  }
  setIsLoading(false);
}


function Header(props) {
  const { classes, equipment, setEquipment, showXglobal, setShowXglobal, setCurrentEvent } = props;
  const [isObsVisible, setIsObsVisible] = useState(false);
  const [isCommandGprsVisible, setIsCommandGprsVisible] = useState(false);
  const [isFollowVisible, setIsFollowVisible] = useState(false);
  const [isFenceVisible, setIsFenceVisible] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isEquipmentHistoryVisible, setIsEquipmentHistoryVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFence, setHasFence] = useState(false);

  const [isAnchorVisible, setIsAnchorVisible] = useState(false);
  const [isAnchored, setIsAnchored] = useState(false);

  const icons = [
    {
      permissions: ['ViewFence', 'ViewAllFences'],
      onClick: () => {
        setIsFenceVisible(!isFenceVisible);
      },
      icon: hasFence ? FenceWhiteIcon : FenceIcon,
      tooltip: 'Cercas',
      active: hasFence,
      imgStyle: { width: 20 }
    },
    {
      permissions: ['ViewEquipmentBeacon'],
      onClick: () => {
        setIsAnchorVisible(!isAnchorVisible);
      }, icon: isAnchored ? AnchorIcon : AnchorWhiteIcon, tooltip: 'Ancoragem', active: isAnchored, imgStyle: { width: 20 },
    },
    {
      onClick: () => {
        setIsFollowVisible(!isFollowVisible);
      }, icon: isFollowed && FollowWhiteIcon || FollowIcon, tooltip: 'Follow', active: isFollowed, imgStyle: { width: 20 }
    },
    {
      permissions: ['SendGPRSCommand'],
      onClick: () => {
        setIsCommandGprsVisible(!isCommandGprsVisible);
      }, icon: CommandIcon, tooltip: 'Comandos GPRS', imgStyle: { width: 15 }
    },
    {
      onClick: async () => {
        if (!showXglobal) {
          setIsLoading(true);
          requestEseyeAndCellIDLocation(equipment, setIsLoading, setEquipment);
        } else {
          setCurrentEvent(equipment.lastEvent);
        }
        setShowXglobal(!showXglobal);
      }, icon: showXglobal && LocationWhiteIcon || LocationIcon, active: showXglobal, tooltip: 'Posição XGlobal', imgStyle: { width: 15 }
    },
    {
      permissions: ['EditEquipmentObservations'],
      onClick: () => {
        setIsObsVisible(true);
      }, icon: ObsIcon, tooltip: 'Observações', imgStyle: { width: 20 }
    },
    {
      permissions: ['ViewEquipmentHistory', 'ViewAllEquipmentHistory'],
      onClick: () => {
        setIsEquipmentHistoryVisible(true);
      },
      icon: HistoryIcon,
      noMargin: true,
      tooltip: 'Histórico',
      imgStyle: { width: 20 }
    },
  ];
  return (
    <div>
      {icons.map((icon, index) => {
        return (
          <Permission key={index} names={icon.permissions}>
            <IconButton
              title={icon.tooltip}
              className={icon.active && classes.activeHeaderIcon || classes.headerIcon}
              style={icon.noMargin && { marginRight: 0 } || {}}
              onClick={icon.onClick}>
              <img src={icon.icon} style={icon.imgStyle || {}} alt={icon.tooltip} />
            </IconButton>
          </Permission>
        )
      })}
      <ModalFence
        setEquipment={setEquipment}
        equipment={equipment}
        isVisible={isFenceVisible}
        setIsVisible={setIsFenceVisible}
        setHasFence={setHasFence}
      />
      <ModalBeacon
        equipment={equipment}
        setIsVisible={setIsAnchorVisible}
        isVisible={isAnchorVisible}
        setIsAnchored={setIsAnchored}
      />
      <ModalObs
        equipment={equipment}
        isVisible={isObsVisible}
        setIsVisible={setIsObsVisible}
      />
      <ModalFollow
        equipment={equipment}
        setIconActive={setIsFollowed}
        isVisible={isFollowVisible}
        setIsVisible={setIsFollowVisible}
      />
      <CommandGprs
        showStatus={true}
        isVisible={isCommandGprsVisible}
        setIsVisible={setIsCommandGprsVisible}
        lastEvent={equipment.lastEvent}
      />
      <ModalEquipmentHistory
        setEquipment={setEquipment}
        equipment={equipment}
        isVisible={isEquipmentHistoryVisible}
        setIsVisible={setIsEquipmentHistoryVisible}
      />
      <CircularLoading isLoading={isLoading} />
    </div>
  )
}

function CardField(props) {
  const { classes, label, value, icon } = props;
  let fieldStyle = props.fieldStyle || {};
  if (props.marginLeft) {
    fieldStyle.marginLeft = 45;
  }
  return (
    <div style={fieldStyle}>
      <Typography className={classes.cardFieldLabel}>
        {label}
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography className={classes.cardField}>
          {value}
        </Typography>
        {icon &&
          <IconButton
            style={{ width: 20, height: 20, boxShadow: 'unset', marginLeft: 10 }}
            title={icon.title}
            className={classes.headerIcon}
            onClick={icon.onClick}>
            <img src={icon.icon} style={{ width: 16, height: 16 }} />
          </IconButton>
        }
      </div>

    </div>
  );
}


function SummaryCard(props) {
  const { classes, equipment, currentEvent, setRefresh } = props;
  const cardStyle = props.cardStyle || {};

  const [openVehicleEdit, setOpenVehicleEdit] = useState(false);

  const highlightColors = {
    'XGlobal': '#548cc1',
    'Google': '#673E9A',
    'CellID': '#8E96B0',
    'GPS': '#41475a',
    'Eseye': '#37B0C9',
    'Safeloggy': '#548cc1',
  }

  return (
    <Card className={classes.cardInfo} style={cardStyle}>
      <div className={classes.cardHighlighted} style={{ backgroundColor: highlightColors[getPositionIndicator(currentEvent)] }}>
        <Typography style={{
          fontSize: 16,
          color: 'white',
          fontWeight: '600',
          paddingLeft: 27,
        }}>
          {getPositionIndicator(currentEvent)}
        </Typography>

      </div>
      <ModalVehicle
        equipment={equipment}
        isVisible={openVehicleEdit}
        setIsVisible={setOpenVehicleEdit}
        onSubmit={() => {
          setRefresh(true);
        }}
      />
      <CardHeader
        title={"Equipamento: " + (equipment && equipment.shortImei || '')}
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardLineContainer}>
          <CardField classes={classes} label="EMPRESA" value={getField(equipment, 'currentCompany.name')} />
          <CardField
            classes={classes}
            label="VEÍCULO"
            value={
              equipment.vehicle
              ? equipment.vehicle[4].match(/[a-z]/i)
                ? conformToMask(equipment.vehicle, PLATE_MODEL_2).conformedValue
                : conformToMask(equipment.vehicle, PLATE_MODEL_1).conformedValue
              : ''
            }
            fieldStyle={{ marginLeft: 45 }}
            icon={{
              icon: ObsIcon,
              title: 'Editar veículo',
              onClick: () => {
                setOpenVehicleEdit(true);
              },
            }}
          />
        </div>
        <div className={classes.cardLineContainer}>
          <CardField classes={classes} label="DATA" value={datetimeFormatter(currentEvent.timestamp)} />
          <CardField classes={classes} label="NÍVEL BATERIA" value={getField(currentEvent, 'internalBatteryPercentage', 'Indisponível', ' %')} fieldStyle={{ marginLeft: 45 }} />
        </div>
        <CardField classes={classes} label="LOCAL" value={currentEvent.local} fieldStyle={{ marginTop: 10 }} />
        <div className={classes.cardLineContainer}>
          <CardField classes={classes} label="RF" value={isRfOn(currentEvent)} />
          <CardField classes={classes} label="TEMPO DE POSIÇÃO FPP" value={minutesToTimeString(currentEvent.positionSendInterval, 'Indisponível')} fieldStyle={{ marginLeft: 45 }} />
        </div>
        <div className={classes.cardLineContainer}>
          <CardField classes={classes} label="TIPO COORDENADA" value={getPositionIndicator(currentEvent)} />
          <CardField classes={classes} label="STATUS" value={getStatus(equipment)} fieldStyle={{ marginLeft: 45 }} />
        </div>
      </CardContent>
    </Card>
  );
}

function InfoCard(props) {
  const { classes, equipment, currentEvent } = props;
  const cardStyle = props.cardStyle || {};
  return (
    <Card className={classes.cardInfo} style={cardStyle}>
      <CardHeader
        title="Localização"
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardLineContainer}>
          <CardField classes={classes} label="DATA" value={datetimeFormatter(currentEvent.timestamp)} />
          <CardField classes={classes} label="LOCAL" value={currentEvent.local} marginLeft={true} />
          <CardField classes={classes} label="LATITUDE" value={currentEvent.latitude} marginLeft={true} />
          <CardField classes={classes} label="LONGITUDE" value={currentEvent.longitude} marginLeft={true} />
          <CardField classes={classes} label="RF" value={isRfOn(currentEvent)} marginLeft={true} />
          <CardField 
            classes={classes}
            label="FREQ. 434Mhz"
            value={
              getField(currentEvent, 'status.transmitter434Mhz', (value) => boolToString(value, 'Ligado', 'Desligado', 'Indisponível'))
            }
            marginLeft={true}
          />
          <CardField 
            classes={classes}
            label="FREQ. 928Mhz"
            value={
              getField(currentEvent, 'status.transmitter928Mhz', (value) => boolToString(value, 'Ligado', 'Desligado', 'Indisponível'))
            }
            marginLeft={true}
          />
          <CardField classes={classes} label="CURSO GPS" value={getField(currentEvent, 'gpsDirection', 'Indisponível')} marginLeft={true} />
          <CardField classes={classes} label="QUANTIDADE SATÉLITE" value={getField(currentEvent, 'gpsSatelliteNumber', 'Indisponível')} marginLeft={true} />
          <CardField classes={classes} label="VELOCIDADE GPS" value={getField(currentEvent, 'gpsSpeed', 'Indisponível')} marginLeft={true} />
          <CardField classes={classes} label="KM RODADO" value={getField(currentEvent, 'kmDistance', 'Indisponível')} marginLeft={true} />
        </div>
      </CardContent>
      <CardHeader
        title="Status"
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardLineContainer}>
          <CardField classes={classes} label="NÍVEL BATERIA" value={getField(currentEvent, 'internalBatteryPercentage', 'Indisponível', ' %')} />
          <CardField classes={classes} label="TENSÃO BATERIA" value={currentEvent.internalBatteryVoltage || 'Indisponível'} marginLeft={true} />
          <CardField classes={classes} label="CARREGANDO" value={getBatteryStatus(currentEvent)} marginLeft={true} />
          <CardField classes={classes} label="TEMPERATURA" value={currentEvent.temperature || 'Indisponível'} marginLeft={true} />
          <CardField classes={classes} label="TEMPERATURA BAÚ" value={getField(currentEvent, 'humidityTemperature.temperature', 'Indisponível')} marginLeft={true} />
          <CardField classes={classes} label="UMIDADE BAÚ" value={getField(currentEvent, 'humidityTemperature.humidity', 'Indisponível')} marginLeft={true} />
          <CardField classes={classes} label="NÍVEL DE SINAL GSM" value={currentEvent.gsmModelSignal || 'Indisponível'} marginLeft={true} />
          <CardField classes={classes} label="FIRMWARE" value={currentEvent.firmwareVersion || 'Indisponível'} marginLeft={true} />
          <CardField classes={classes} label="STATUS" value={getStatus(equipment)} marginLeft={true} />
          <CardField classes={classes} label="EXPIRAÇÃO" value={poffFormatter(equipment)} marginLeft={true} />
          {/* <CardField classes={classes} label="HORA ATIVAÇÃO" value={"-x-"} marginLeft={true}/> */}
        </div>
      </CardContent>
      <CardHeader
        title="Transmissão"
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardLineContainer}>
          <CardField classes={classes} label="TIPO DE TRANSMISSÃO" value={getPositionIndicator(currentEvent)} />
          {/* <CardField classes={classes} label="TIPO LBS" value={currentEvent.positionIndicator[1]} marginLeft={true}/> */}
          {/* <CardField classes={classes} label="TIPO DE COORDENADA" value="-x-" marginLeft={true}/> */}
          <CardField classes={classes} label="TEMPO DE POSIÇÃO" value={minutesToTimeString(currentEvent.positionSendInterval, 'Indisponível')} marginLeft={true} />
        </div>
      </CardContent>
      <CardHeader
        title="Informações técnicas"
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardLineContainer}>
          <CardField classes={classes} label="CÓDIGO" value={equipment.code} />
          <CardField classes={classes} label="OPERADORA" value={getField(equipment, 'mobileOperator.name')} marginLeft={true} />
          {/* <CardField classes={classes} label="DESCRIÇÃO" value={""} marginLeft={true}/> */}
          <CardField classes={classes} label="TIPO" value={getField(equipment, 'baitType.name')} marginLeft={true} />
          <CardField classes={classes} label="MODELO" value={getField(equipment, 'model.name')} marginLeft={true} />
          <CardField classes={classes} label="LINHA" value={equipment.lineNumber} marginLeft={true} />
          <CardField classes={classes} label="IMEI" value={equipment.imei} marginLeft={true} />
          <CardField classes={classes} label="ICCID" value={equipment.iccid} marginLeft={true} />
        </div>
      </CardContent>
    </Card>
  );
}

const BREADCRUMBS_MAP = {
  '/mapa': [{
    path: '/mapa',
    label: 'Mapa'
  }],
  '/dashboard/estaveis': [{
    path: '/dashboard',
    label: 'Dashboard',
  }, {
    path: '/dashboard/estaveis',
    label: 'Equipamentos estáveis',
  }],
  '/dashboard/bateria-baixa': [{
    path: '/dashboard',
    label: 'Dashboard',
  }, {
    path: '/dashboard/bateria-baixa',
    label: 'Equipamentos com bateria baixa',
  }],
  '/dashboard/sem-posicionar': [{
    path: '/dashboard',
    label: 'Dashboard',
  }, {
    path: '/dashboard/sem-posicionar',
    label: 'Equipamentos sem posicionar',
  }],
  '/dashboard/posicoes-atrasadas': [{
    path: '/dashboard',
    label: 'Dashboard',
  }, {
    path: '/dashboard/posicoes-atrasadas',
    label: 'Equipamentos posições atrasadas ',
  }]
}

function EquipmentDetail(props) {
  useAccessLog('Acesso ao detalhe do equipamento');
  const { classes, location, match, history } = props;
  const [showXglobal, setShowXglobal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [data, setData] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const fetchPath = `equipment/${match.params.id}`;
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (refresh) {
      const _fetch = async () => {
        const response = await fetchAuthenticated(get, fetchPath);
        setData(response);
      }
      _fetch();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    const from = location.state && location.state.from || { pathname: '/dashboard' }
    setBreadcrumbs([
      ...getBreadcrumbsFor(from.pathname),
      { path: '', label: data.shortImei || '' }
    ])

  }, [location, data]);

  useEffect(() => {
    if (data) {
      setCurrentEvent(data.lastEvent);
    }
  }, [data]);

  const onEventClick = (equipmentEvent) => {
    setCurrentEvent(equipmentEvent);
  }

  const mapHeight = 360;

  return (
    <div>
      <SubBar breadcrumbs={breadcrumbs} items={[]} match={props.match} />
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography className={classes.headerText}>Monitoramento</Typography>
          <Header
            setEquipment={setData}
            showXglobal={showXglobal}
            setShowXglobal={setShowXglobal}
            equipment={data}
            classes={classes}
            setCurrentEvent={setCurrentEvent}
          />
        </div>
        <Grid container className={classes.mapGrid}>
          <Grid item xs={3}>
            <SummaryCard
              equipment={data}
              currentEvent={currentEvent}
              classes={classes}
              cardStyle={{ height: mapHeight }}
              setRefresh={setRefresh}
            />
          </Grid>
          <Grid item xs={9}>
            <Map
              showXglobal={showXglobal}
              onEventClick={(equipmentEvent) => onEventClick(equipmentEvent)}
              currentEquipment={data}
              equipments={[data]}
              // disableExpand={true}
              disablePopup={true}
              height={mapHeight}
              containerStyle={{
                marginLeft: 15,
              }}
              showCurrentEquipmentFences={true}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.mapGrid}>
          <Grid item xs={12}>
            <InfoCard
              equipment={data}
              classes={classes}
              currentEvent={currentEvent}
              cardStyle={{ marginBottom: 16 }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default withRouter(withStyles(styles)(EquipmentDetail));
