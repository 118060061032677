import React from 'react';
import BaseList from 'components/lists/BaseList';
import { useAccessLog } from 'hooks/logs';

function ListFence(props) {
  useAccessLog('Acesso as cercas');
  const { match } = props;
  return (
    <BaseList
      columns={[
        { value: row => row.name, label: 'Nome', sort: 'name' },
        { value: row => row.description, label: 'Descrição', sort: 'description' },
        { value: row => row.company && row.company.name, label: 'Empresa'},
      ]}
      genericSearchFields={[
        { label: 'Nome', name: 'name' },
        { label: 'Descrição', name: 'description' },
      ]}
      basePath="fence"
      title="Lista de Cercas"
      addButtonText="Cadastrar Cerca"
      addUrl={`${match.path}/nova`}
    />
  );
}

export default ListFence;
