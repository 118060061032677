import React from 'react';
import BaseList from 'components/lists/BaseList';
import { useAccessLog } from 'hooks/logs';

function ListUser(props) {
  useAccessLog('Acesso ao cadastro de usuários');
  const { match } = props;
  return (
    <BaseList
      columns={[
        { value: (row) => row.name, label: 'Nome', sort: 'name' },
        { value: (row) => row.username, label: 'Usuário', sort: 'username' },
        { value: (row) => row.company && row.company.name, label: 'Empresa', sort: 'company.name' },
        { value: (row) => row.email, label: 'Email', sort: 'email' },
      ]}
      genericSearchFields={[
        { label: 'Nome', name: 'name', },
        { label: 'Usuário', name: 'username', },
        { label: 'Nome da Empresa', name: 'company.name', },
        { label: 'Email', name: 'email', },
      ]}
      basePath='user'
      title='Lista de Usuários'
      addButtonText='Cadastrar Usuário'
      addUrl={`${match.path}/novo`}
    />
  );
}

export default ListUser;
