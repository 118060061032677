import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { emitEvent } from 'utils/events';
import AutoCompleteMap from "components/fields/AutoCompleteMap";
import { distanceTo } from 'geolocation-utils';

import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import './Map.css';

const expandedHeight = 700;

function setupTranslations () {
  L.drawLocal.draw.toolbar.buttons.polygon = 'Desenhe um polígono';
  L.drawLocal.draw.toolbar.buttons.rectangle = 'Desenhe um retângulo';
  L.drawLocal.draw.toolbar.buttons.circle = 'Desenhe um círculo';
  L.drawLocal.edit.toolbar.buttons.edit = 'Editar desenho';
  L.drawLocal.edit.toolbar.buttons.editDisabled = 'Nada para editar';
  L.drawLocal.edit.toolbar.buttons.remove= 'Remover desenho';
  L.drawLocal.edit.toolbar.buttons.removeDisabled = 'Nada para remover';
}

const drawBounds = (bounds, map, currentRect, setCurrentRect) => {
  if(currentRect) {
    currentRect.removeFrom(map);
  }

  const centerPoint = [
    (bounds[0][0] + bounds[1][0]) / 2,
    (bounds[0][1] + bounds[1][1]) / 2,
  ];

  const distance = distanceTo(bounds[0], bounds[1]);
  const radius = Math.ceil(distance/2);
  const rect = L.circle(centerPoint, radius, {
    color: 'purple',
    weight: 2,
    dashArray: [5, 10]
  });
  rect.addTo(map);
  rect.bindTooltip("Esta é a região encontrada com base na sua pesquisa.<br>Utilize-a como referência para definição da cerca.", {className: 'tooltip'});
  setCurrentRect(rect);
}

const restoreGeoJSON = (map, drawnItems, geojson, setLayers) => {
  const layers = L.geoJSON(geojson);
  layers.eachLayer(layer => {
    drawnItems.addLayer(layer);
  })
  layers.addTo(map);
  setLayers(layers);
}

function setupDrawLayers (map, onLayerChange, setDrawnItems, setDrawnControlEditOnly, setDrawnControlFull, setLayers) {
  // Setup drawers
  const drawnItems = new L.FeatureGroup();
  setDrawnItems(drawnItems);
  map.addLayer(drawnItems);

  setupTranslations();
  const drawnControlFull = new L.Control.Draw({
    position: 'bottomleft',
    draw: {
      marker: false,
      circlemarker: false,
      circle: false,
      polyline: false,
      polygon: {
        allowIntersection: false,
        showArea: true
      },
    },
    edit: {
      featureGroup: drawnItems,
      allowIntersection: false,
      remove: true,
    }
  });
  setDrawnControlFull(drawnControlFull);

  const drawnControlEditOnly = new L.Control.Draw({
    position: 'bottomleft',
    draw: false,
    edit: {
      featureGroup: drawnItems,
      allowIntersection: false,
      remove: true,
    },
  });
  setDrawnControlEditOnly(drawnControlEditOnly);

  map.addControl(drawnControlFull);

  map.on(L.Draw.Event.CREATED, function (e) {
    onLayerChange( {...e, needParse: true});
    /* should be that easy to restore right */
    /* drawnItems.addLayer(e.layer); */
    restoreGeoJSON(map, drawnItems, e.layer.toGeoJSON(), setLayers);
    drawnControlFull.remove(map);
    drawnControlEditOnly.addTo(map)
  });

  map.on(L.Draw.Event.DELETED, function(e) {
    onLayerChange({});
    if (drawnItems.getLayers().length === 0){
      drawnControlEditOnly.remove(map);
      drawnControlFull.addTo(map);
    };
  });


}

function Map (props) {
  const { classes, disableExpand, onLayerChange, fence } = props;
  const height = props.height || 400;
  const containerStyle = props.containerStyle || {};

  const [toggleMap, setToggleMap] = useState(false);
  const [mapHeight, setMapHeight] = useState(height);
  const [mapInstance, setMapInstance] = useState();
  const [drawnItems, setDrawnItems] = useState();
  const [drawnControlFull, setDrawnControlFull] = useState();
  const [drawnControlEditOnly, setDrawnControlEditOnly] = useState();
  const [layers, setLayers] = useState();
  const [addressPreviewRectangle, setAddressPreviewRectangle] = useState();

  // const centerMap = (lat, long, zoom=12) => {
  const centerMap = (bounds) => {
    mapInstance.fitBounds(bounds, {maxZoom: 15});
    // mapInstance.setView(L.latLng(lat, long), { animation: true });
  }

  const toogleMapSize = () => {
    setMapHeight((toggleMap && height) || expandedHeight);
    setToggleMap(!toggleMap);
  }

  useEffect(() => {
    const map = L.map('map', {
      zoom: 1,
      zoomControl: true,
    }).setView([
      -23.5489, -46.6388
    ], 6);
    // create map
    map.zoomControl.setPosition('bottomright');

    // Setup drawing layers and menu stuff
    setupDrawLayers(map, onLayerChange, setDrawnItems, setDrawnControlEditOnly, setDrawnControlFull, setLayers);

    // setup layers
    const layer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    })
    layer.on('loading', () => {
      emitEvent('showGlobalLinearProgress')
    })
    layer.on('load', () => {
      emitEvent('hideGlobalLinearProgress');
    });
    layer.addTo(map);
    setMapInstance(map);

  }, [])

  useEffect(() => {
    if(drawnItems && mapInstance && fence && Object.keys(fence).length > 0) {
      /* Prevent from drawing again the same layer*/
      if(layers) { return; }
      restoreGeoJSON(mapInstance, drawnItems, fence.geoJSON, setLayers);
      drawnControlFull.remove(mapInstance);
      drawnControlEditOnly.addTo(mapInstance);


      /* fit bounds */
      const _bounds = fence.layer.bounds[0];
      const northEast = [_bounds._northEast.lat, _bounds._northEast.lng];
      const southWest = [_bounds._southWest.lat, _bounds._southWest.lng];
      const bounds = new L.LatLngBounds([northEast, southWest]);
      mapInstance.fitBounds(bounds.pad(0.5), {maxZoom: 15});
    }
  }, [drawnItems, mapInstance, fence, drawnControlFull, drawnControlEditOnly]);

  // re renders when map height is changed
  useEffect(() => {
    if(mapInstance) {
      mapInstance.invalidateSize();
    }
  }, [mapHeight]);

  return (
    <div className={classes.root} style={{...containerStyle}} >
      <div style={{ height: mapHeight, position: 'relative'}} >
        <div id='map' className={props.mapClass || classes.map}/>
        <AutoCompleteMap
          placeholder='Buscar endereço'
          showPlaceholder={true}
          hideLabel={true}
          loadingMessage="Buscando endereços..."
          noOptionsMessage="Nenhuma endereço encontrado."
          onChange={(opt) => {
            if(opt) {
              centerMap(opt.bounds);
              // centerMap(opt.y, opt.x, 15);
              drawBounds(opt.bounds, mapInstance, addressPreviewRectangle, setAddressPreviewRectangle);
            }
          }}
        />
      </div>

      {!disableExpand &&
        <IconButton disableRipple={true} id="expandMapIcon" className={classes.toggleMap} classes={{colorSecondary: 'white'}} onClick={toogleMapSize}>
          <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
        </IconButton>
      }
    </div>
  );
}
const styles = theme => ({
  root: {
    position:'relative',
  },
  map: {
    height: "100%",
    borderRadius: theme.border.radius,
    marginBottom: 45,
  },
  toggleMap: {
    right: 36,
    top: 25,
    zIndex: 700,
    position: 'absolute',
    borderRadius: 14,
    color: theme.palette.gray_1,
    backgroundColor: theme.palette.background.white,
    width: 43,
    height: 43,
  },
})

export default withStyles(styles)(Map);
