import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';


import ModalRemove from 'components/modals/ModalRemove';
import EquipmentMobileOperatorForm from 'components/forms/EquipmentMobileOperatorForm';

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

export default function EditEquipmentMobileOperators(props) {
  const classes = useStyles();
  const { match, history, setBreadcrumbs } = props;
  const [equipmentMobileOperators, setEquipmentMobileOperators] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  // Use base equipmentMobileOperators url
  console.log(match);
  const equipmentMobileOperatorsURL = `equipment-mobile-operator/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/operadoras', label: 'Operadoras' },
      { path: '', label: `${equipmentMobileOperators && equipmentMobileOperators.name || ''}` }
    ]);
    return () => { setBreadcrumbs([]); }
  }, [setBreadcrumbs, match.params.id, equipmentMobileOperators]);

  // Load equipmentMobileOperators info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const response = await fetchAuthenticated('get', equipmentMobileOperatorsURL);

      // Any error will redirect to equipmentMobileOperatorss list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/empresas');
      } else {
        const data = await response.json();

        // Update protocol version to expected format

        setEquipmentMobileOperators(data);
        logAction(`Acesso ao cadastro da operadora ${data.name}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, equipmentMobileOperatorsURL]);

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', equipmentMobileOperatorsURL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();
      setEquipmentMobileOperators(data);
      logAction(`Edição da operadora ${data.name}`);
      emitEvent('showSnack', { message: 'Operadora atualizada com sucesso', type: 'success' });
    }
  }

  // Handle cancel button click
  const handleCancel = () => {
    history.push('/cadastros/operadoras');
  }

  // Handle remove button click
  const handleRemove = () => {
    console.log('Handle remove');
    setShowDialog(true);
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  // Handle remove confirmation
  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', equipmentMobileOperatorsURL);

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover a operadora', type: 'error' });
      } else {
        logAction(`Remoção da operadora ${equipmentMobileOperators.name}`);
        emitEvent('showSnack', { message: 'Operadora removida com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/operadoras');
      }
    }
  }

  return (
    <div>
      <EquipmentMobileOperatorForm
        title='Detalhes da Operadora'
        data={equipmentMobileOperators}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
      />

      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='da operadora'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='a operadora'
        extraContent='Ao realizar está ação a operadora não estará mais disponível'
      />

    </div>
  );
}
