import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { TextField } from '@material-ui/core';
import { Rule, validate } from "../../utils/formValidator";
import validator from 'validator';

const ConfirmPasswordField = forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const { passwordRef, ...rest } = props;


  const [ value, setValue ] = useState('');

  const [ validations, setValidations ] = useState({
    rules: [
      Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const onChange = e => { setValue(e.target.value) }
  const onBlur = () => { validateField() }

  const validateField = () => {
    let currentRules = (props.required || value) ? validations.rules.concat(
      Rule('equals', [passwordRef.current && passwordRef.current.value || ''], true, 'Valor deve ser igual ao campo senha')
    ) : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(value, currentRules)})
  }

  const resetField = () => {
    setValue('')
    setValidations({
      ...validations,
      alreadyValidated: false,
      isInvalid: false,
      message: ''
    })
  }

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: validator.trim(value),
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    resetField
  }));

  return (
    <TextField
      {...rest}
      type={'password'}
      ref={inputRef}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={validations.isInvalid}
      helperText={validations.isInvalid && validations.message || ' '}
      style={{minHeight: 68}}
    />
  )
});

export default ConfirmPasswordField
