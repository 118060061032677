import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useEffectSkipFirst } from 'hooks/common';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    position: 'unset',
  },
  summaryRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  summaryContent: {
    alignItems: 'center',
    flexGrow: 'unset',
  },
  heading: {
    fontSize: theme.text.subheader.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  checkboxLabel: {
    fontSize: theme.text.content.fontSize,
    fontWeight: theme.text.content.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  checkbox: {
    width: 28,
    height: 28,
    borderRadius: 7,
    marginRight: 21,
  },
  detailCheckbox: {
    width: 28,
    height: 28,
    borderRadius: 7,
    marginRight: 10,
  },
  detailContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 24,
  }
}));

export function MyCheckbox(props) {
  const {value, setValue, header, labelStyle, checkboxStyle, containerStyle } = props;
  const classes = useStyles();
  return (
    <div style={{display:'flex', flexDirection: 'row', alignItems: 'center', ...containerStyle}}>
      <Checkbox
        onChange={ (e) => {
          setValue(e.target.checked)
        }}
        className={classes.checkbox}
        color="primary"
        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} />}
        checkedIcon={<CheckBoxIcon style={{ fontSize: 28 }} />}
        checked={value}
        value={value}
        style={checkboxStyle}
      />
      <Typography className={classes.heading} style={labelStyle}>{header}</Typography>
    </div>
  )
}
MyCheckbox.defaultProps = {
  labelStyle: {},
  checkboxStyle: {},
  containerStyle: {},
}

export default function CheckboxList(props) {
  const classes = useStyles();
  const { data, setData, getName, containerStyle, labelStyle, showCheckAll, refreshCheckAll } = props;

  const [checkAll, setCheckAll] = useState(props.checkAll);

  const handleCheckboxOnChange = (e, item) => {
    item.checked = e.target.checked;
    const newData = data.map((el) => {
      // gambiarra no _id pra deixar atualizar sem dar erro
      return {...el, checked: item._id === el._id && item.checked || el.checked};
    })
    setData(newData);
  }

  useEffectSkipFirst(() => {
    setCheckAll(false);
  }, [refreshCheckAll]);


  useEffectSkipFirst(() => {
    const newData = data.map((e) => {
      // gambiarra no _id pra deixar atualizar sem dar erro
      return {...e, checked: checkAll};
    })
    setData(newData);
  }, [checkAll]);

  const getValue = (item) => {
    return item.checked;
  }

  return (
    <div className={classes.root} style={{...containerStyle}}>
      {showCheckAll &&
        <MyCheckbox value={checkAll} setValue={setCheckAll} header="Todos" containerStyle={{marginRight: 24}}></MyCheckbox>
      }
      {data.map((item, index) => {
        return (
          <div className={classes.detailContainer} key={Math.random()}>
            <Checkbox
              onChange={ (e) => {
                handleCheckboxOnChange(e, item);
              }}
              className={classes.checkbox}
              color="primary"
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize: 28 }} />}
              checked={getValue(item)}
              value={getValue(item)}
            />
            <Typography className={classes.heading} style={{...labelStyle}}>{getName(item)}</Typography>
          </div>
        )
      })}
   </div>
  );
}

CheckboxList.defaultProps = {
  containerStyle: {},
  labelStyle: {},
  checkAll: false,
  showCheckAll: false,
  refreshCheckAll: false,
}
