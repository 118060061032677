import React, { useState, useEffect } from 'react';
import {
  Grid,
} from '@material-ui/core';
import Form, { FormSection } from "components/forms/Form";
import { useFields } from "hooks/fields";
import { emitEvent } from 'utils/events';
import { withStyles } from '@material-ui/core';
import { DynamicField } from 'components/fields';
import { ButtonsForm } from 'components/buttons/ButtonsForm';

import {
  TextField,
} from 'components/fields';

const EquipmentModelForm = (props) => {
  const { classes } = props;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDisabled, setDisabled ] = useState(false);
  const [ submitForm, setSubmitForm ] = useState(false);

  // Declare form fields
  const fields = useFields([
    'name',
    'names',
  ]);

  /**======================
   * LOAD INITIAL FORM DATA
   * ====================== */
  useEffect(() => {
    if(props.data) {
      setIsLoading(false);
      setDisabled(true);
      for (const [key, value] of Object.entries(props.data)) {
        if (fields[key] && fields[key].current) {
          if (fields[key].current.setFieldValue) {
            fields[key].current.setFieldValue(value);
      } } }
      setIsLoading(false);
      setDisabled(false);
    }
 }, [props.data])

  /**================
   * FORM SUBMISSION
   * ================ */

  // Validate all fields when form is submited
  const validateForm = async (event) => {
    event.preventDefault();
    const nonValidatedFields = Object.values(fields).filter(f => f.current && !f.current.alreadyValidated);
    nonValidatedFields.map(f => f.current.validateField());
    setSubmitForm(true);
    console.log('Validating');
  }

  // Handle form submission
  useEffect(() => { if (submitForm) { (async () => { await handleSubmit() })(); } }, [submitForm]);
  const handleSubmit = async () => {

    // Check for invalid fields
    const invalidFields = Object.values(fields).filter(f => f.current && f.current.isInvalid);
    if (invalidFields.length > 0) {
      emitEvent('showSnack', {message: 'Erro ao validar formulário', type: 'error'});
      setSubmitForm(false)
    }

    // All fields are valid
    // Commit changes to the server
    else {
      setDisabled(true);
      setIsLoading(true);

      // Get all fields
      const data = {}
      for (const [key, value] of Object.entries(fields)) {
        if(value.current)
        data[key] = value.current.raw;
      }

      // Send to server
      try {
        await props.handleSubmit(data);
        setDisabled(false);
        setIsLoading(false);
        setSubmitForm(false);
      } catch (err) {
        console.log(err);
        emitEvent('showSnack', {message: 'Erro ao processar formulário', type: 'error'});
        setDisabled(false);
        setIsLoading(false);
        setSubmitForm(false);
      }
    }
  };

  return (
    <Form title={props.title || 'Novo Modelo'}>
      <form onSubmit={validateForm} noValidate>
        <FormSection divider={true} justify="flex-start">
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {props.data &&
                <TextField
                  required
                  ref={fields.name}
                  fullWidth
                  className={classes.fullWidthField}
                  label='Nome'
                  name='name'
                />
              }
              {!props.data &&
                <DynamicField
                  ref={fields.names}
                  refField='ref'
                  label='Nome'
                  name='names'
                  component={TextField}
                  componentStyle={{maxWidth: '100%'}}
                  required
                />
              }
            </Grid>
          </Grid>
        </FormSection>
        <ButtonsForm {...props} formDisabled={isDisabled}/>
      </form>
    </Form>
  )
};

const styles = theme => ({
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
});

export default withStyles(styles)(EquipmentModelForm);
