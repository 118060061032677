import React, { useState } from 'react';
import { put } from 'services/fetch';
import { emitEvent } from "utils/events";
import ModalStatus from 'components/modals/ModalStatus';
import ModalConfirm from 'components/modals/ModalConfirm';
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { StatusBall } from 'components/table/columns/StatusColumn';
import { logAction } from 'utils/logs';

const STATUS_MAP = {
  emergency: 'emergência',
  suspect: 'suspeito',
  active: 'ativo'
}
export const getEquipmentRowActions = (callback) => {
  return [
    {
      label: 'Ativo',
      labelComponent: (<StatusBall containerStyle={{ marginRight: 10 }} status='active' />),
      component: (props) => {
        return (
          <ModalStatus status='ativo' onSubmit={(equipment, data) => {
            updateStatus(equipment, { status: 'active', ignoreTime: data.ignoreTimeMoment, description: data.description }, callback);
          }} isVisible={true} equipment={props} />
        );
      },
    },
    {
      label: 'Em suspeita',
      labelComponent: (<StatusBall containerStyle={{ marginRight: 10 }} status='suspect' />),
      component: (props) => {
        return (
          <ModalStatus status='suspeito' onSubmit={(equipment, data) => {
            updateStatus(equipment, { status: 'suspect', ignoreTime: data.ignoreTimeMoment, description: data.description }, callback);
          }} isVisible={true} equipment={props} />
          // <StatusModalConfirm  content='Ao alterar o status para "Em suspeita" os usuários pré-definidos serão notificados.' onConfirm={() => { updateStatus(equipment, { status: 'suspect' }, callback); }}/>
        )
      },
    },
    {
      label: 'Em emergência',
      labelComponent: (<StatusBall containerStyle={{ marginRight: 10 }} status='emergency' />),
      component: (props) => {
        return (
          <ModalStatus status='emergência' onSubmit={(equipment, data) => {
            updateStatus(equipment, { status: 'emergency', ignoreTime: data.ignoreTimeMoment, description: data.description }, callback);
          }} isVisible={true} equipment={props} />
          // <StatusModalConfirm  content='Ao alterar o status para "Em emergência" os usuários pré-definidos serão notificados.' onConfirm={() => { updateStatus(equipment, { status: 'emergency' }, callback); }}/>
        )
      }
    },
  ]
}

const updateStatus = async (equipment, data, callback) => {
  const path = 'equipment/update-status/';
  try {
    const response = await put(path + equipment._id, data);
    if (callback) { callback(); }
    logAction(`Alterou status do equipamento ${equipment.shortImei} para ${STATUS_MAP[data.status]}`)
    emitEvent('showSnack', { message: 'Status alterado.', type: 'success' });
  } catch (e) {
    emitEvent('showSnack', { message: 'Erro ao tentar alterar o status', type: 'error' });
  }
}

const StatusModalConfirm = ({ content, onConfirm }) => {
  const [openModal, setOpenModal] = useState(true);

  const useStyles = makeStyles(theme => ({
    text: {
      fontSize: 16,
      color: theme.palette.gray.gray_4,
      maxWidth: 800,
    }
  }));
  const classes = useStyles();

  const _onConfirm = () => {
    setOpenModal(false);
    onConfirm();
  }

  return (
    <ModalConfirm
      isOpen={openModal}
      setIsOpen={setOpenModal}
      onConfirm={_onConfirm}
      title="Tem certeza que deseja alterar o status?"
    >
      <div>
        <Typography className={classes.text}>
          {content}
        </Typography>
      </div>
    </ModalConfirm>
  )
}
