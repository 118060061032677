import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography,
} from '@material-ui/core';
import ModalConfirm from 'components/modals/ModalConfirm';

export default function ModalRemove(props) {
  const {
    isVisible,
    setIsVisible,
    titleLabel,
    onCancel,
    onSubmit,
    buttonLabel,
    isLoading,
    removeLabel,
    extraContent,
  } = props;

  const handleSubmit = () => {
    if(onSubmit) {
      onSubmit();
    }
    setIsVisible(false);
  }

  const handleCancel = () => {
    if(onCancel) {
      onCancel();
    }
    setIsVisible(false);
  }

  return (
    <ModalConfirm
      title={`Confirmar exclusão ${titleLabel}`}
      isOpen={isVisible}
      setIsOpen={setIsVisible}
      onCancel={handleCancel}
      onConfirm={handleSubmit}
      confirmButtonLabel={buttonLabel || 'Sim, confirmar'}
      isLoading={isLoading}
    >
      <Typography style={{
        fontSize: 16,
        color: '#41475a',
      }}>Tem certeza que deseja excluir {removeLabel}?</Typography>
      <Typography style={{
        fontSize: 16,
        color: '#41475a',
      }}>{extraContent}</Typography>
    </ModalConfirm>
  )
}
ModalRemove.defaultProps = {
  isLoading: false,
  extraContent: '',
}
