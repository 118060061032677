import React, { useEffect } from 'react';

import { fetchAuthenticated, get } from 'services/fetch';
import { emitEvent } from 'utils/events';

import EquipmentModelForm from 'components/forms/EquipmentModelForm';
import { logAction } from 'utils/logs';

export default function NewEquipmentModel(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/modelos', label: 'Modelos' },
      { path: '', label: 'Novo Modelo' }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs])

  // Handle form submit
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('post', 'equipment-model', data);

    if (!response.ok) {
      throw new Error('');
    } else {
      const data = await response.json();
      if (data) {
        for (const m of data) {
          logAction(`Criação do modelo ${m.name}`);
        }
      }
      emitEvent('showSnack', { message: 'Modelo criado com sucesso', type: 'success' });
      history.push(`/cadastros/modelos`);
    }
  }

  // Handle cancel click
  const handleCancel = async () => {
    history.push('/cadastros/modelos');
  }


  return (
    <div>
      <EquipmentModelForm
        title='Novo Modelo'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
