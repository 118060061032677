import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import * as moment from 'moment';

import { useDelayedEffect } from 'hooks/common';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 16,
    border: '1px solid transparent',
    padding: '4px 0px 4px 15px',
  },
  rootError: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 16,
    border: '1px solid #ff00009e',
    color: '#ff00009e',
    padding: '4px 0px 4px 15px',
  },
  input: {
    '&::placeholder': {
      fontWeight: 600,
      color: theme.palette.gray.gray_1,
      opacity: 1,
      fontSize: 14,
    },
  },
  helperText: {
    display: 'none',
  },
  formControl: {
    minHeight: 'auto',
  },
}));

const DateTimePicker = (props) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [dateTime, setDateTime] = useState(props.value || null);

  const _onChange = (date) => {
    setDateTime(date);
  };

  useDelayedEffect(() => {
    if (dateTime.isValid()) {
      if (props.onChange) {
        const { filterHandler, fields } = props;
        props.onChange({ filterHandler, fields, date: dateTime });
      }
    }
  }, 1000, [dateTime]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
      <KeyboardDateTimePicker
        {...props}
        InputProps={{
          disableUnderline: true,
          classes: { root: error ? classes.rootError : classes.root, input: classes.input },
        }}
        InputLabelProps={{ shrink: true }}
        FormHelperTextProps={{ classes: { root: classes.helperText }}}
        value={dateTime}
        onError={setError}
        onChange={_onChange}
      />
    </MuiPickersUtilsProvider>
  );
};

DateTimePicker.defaultProps = {
  format: 'DD/MM/YYYY HH:mm',
  variant: 'inline',
  margin: 'dense',
  hideTabs: true,
  autoOk: true,
  ampm: false,
};

export default DateTimePicker;
