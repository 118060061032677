import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditEquipmentPacking from "pages/Records/EquipmentPackings/Edit";
import ListEquipmentPacking from "pages/Records/EquipmentPackings/List";
import NewEquipmentPacking from "pages/Records/EquipmentPackings/New";

export default function EquipmentPackings (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Embalagens",
      path: '/novo',
      component: NewEquipmentPacking,
    },
    {
      name: "Embalagens",
      path: '/:id',
      component: EditEquipmentPacking,
    },
    {
      name: "Embalagens",
      path: '',
      component: ListEquipmentPacking
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
