import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  Link,
  Fade,
  IconButton
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import * as shortid from 'shortid';

const useStyles = makeStyles((theme) => ({
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
  icon: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
  }
}));

function DynamicFields (props, ref) {
  const classes = useStyles();
  const {
    component: Component,
    refField,
    componentStyle = {},
    disabled,
    ...rest
  } = props;
  const [ fields, setFields ] = useState([{id: shortid.generate(), ref: useRef(null)}]);
  const [ updateInitial, setUpdateInitial ] = useState(false);
  const [ initialValue, setInitialValue ] = useState([]);

  // Add a new reference to the fields
  const handleAddOne = (event) => {
    event.preventDefault();
    setFields([...fields, {id: shortid.generate(), ref: React.createRef(null)}]);
  }

  const handleRemoveOne = (index, list) => {
    if (list.length === 1) {
      setFields([{id: shortid.generate(), ref: React.createRef(null)}]);
    } else {
      const newFields = [...list];
      newFields.splice(index, 1);
      setFields(newFields);
    }
  }

  const validateField = () => {
    fields.forEach(f => f.ref.current.validateField());
  }

  const setFieldValue = (values) => {
    let refs = [];
    for (let i = 0; i < values.length; i++) refs.push({id: shortid.generate(), ref: React.createRef(null)});
    if (refs.length > 0) {
      setFields(refs);
      setInitialValue(values);
      setUpdateInitial(true);
    }
  }

  const resetField = () => {
    fields.map(f => f.ref.current.resetField());
    setFields([React.createRef(null)]);
  }

  useEffect(() => {
    if (updateInitial) {
      // Update values
      for (let i = 0; i < initialValue.length; i++) fields[i].ref.current.setFieldValue(initialValue[i]);
      setUpdateInitial(false);
    }
  }, [fields, initialValue, updateInitial]);

  useImperativeHandle(ref, () => ({
    get value() {
      return fields.map(f => f.ref.current.value).filter((f, i, e) => f && e.indexOf(f) === i);
    },
    get raw() {
      return fields.filter(f => f.ref.current.raw).map(f => f.ref.current.raw).filter((f, i, e) => f && e.indexOf(f) === i);
    },
    get isInvalid() {
      return fields.map(f => f.ref.current.isInvalid).some(e => e)
    },
    get alreadyValidated() {
      return fields.map(f => f.ref.current.alreadyValidated).every(e => e);
    },
    validateField,
    setFieldValue,
    resetField
  }));


  // Render a list of fields
  const renderFields = () => {
    return fields.map((f, index) => (
      <Fade in key={f.id}>
        <Grid container wrap="nowrap">
        <FormControl
          fullWidth
          className={classes.fullWidthField}
          style={componentStyle}
        >
          <Component disabled={disabled} { ...rest  } {...{[refField]: f.ref}} />
        </FormControl>
        <IconButton title="Remover item" classes={{root: classes.icon}} onClick={() => handleRemoveOne(index, fields)}><Delete /></IconButton>
        </Grid>
      </Fade>
    ));
  }

  return (
    <Grid container direction='column' style={{flex: 1}}>
      <Grid container direction={ `${props.direction || "row"}` } >
        { renderFields() }
      </Grid>
      <Grid container>
        <Link
          onClick={(event) => disabled ? null : handleAddOne(event)}
          style={{fontSize: 16, fontWeight: 'bold', cursor: 'pointer'}}
          color='primary'
          underline='none'
        >{`${props.addOneText || "Adicionar outro"}`} </Link>
      </Grid>
    </Grid>
  );
}

export default forwardRef(DynamicFields);
