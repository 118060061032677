import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import PaginationActions from './PaginationActions';
import CircularLoading from 'components/loading/CircularLoading';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
// import TableMenu from './TableMenu';

import { buildQuery, defaultQuery } from '../../utils/query';
import { useEffectSkipFirst } from 'hooks/common';

function SimpleTable(props) {
  const {
    classes,
    location,
    data,
    query,
    setQuery,
    HeaderComponent,
    containerStyle,
    paperStyle,
    columnAlign,
    showPagination,
    rowsPerPageOptions,
    isLoading,
    EmptyTableComponent,
  } = props;

  let { columns } = props;

  if (showPagination && !props.query && !props.setQuery) {
    throw new Error('query and setQuery props are required when pagination is True');
  }

  const defaultLimit = parseInt(query.queryObject.limit || props.limit || 10, 10);
  const defaultPage = parseInt(query.queryObject.page || props.page || 1, 10);
  const defaultSort = query.queryObject.sort || {}

  const [limit, setLimit] = useState(defaultLimit);
  const [page, setPage] = useState(defaultPage);
  const [sort, setSort] = useState(defaultSort);
  const [popover, setPopover] = useState({});

  // update query when limit or page changes
  useEffectSkipFirst(() => {
    setQuery(
      buildQuery(location, {
        ...query.queryObject,
        limit,
        page,
        sort,
      }),
    );
  }, [page]);

  useEffectSkipFirst(() => {
    setQuery(
      buildQuery(location, {
        ...query.queryObject,
        limit,
        page: 1,
        sort,
      }),
    );
    setPage(1)
  }, [limit, sort]);

  const handleRowsPerPage = e => setLimit(e.target.value);

  const handlePage = (e, p) => {
    setPage(p + 1);
  };

  columns =
    columns.length > 0
      ? columns
      : data && data.data.length > 0
        ? Object.keys(data.data[data.data.length - 1]).sort()
        : [];
  const handleTableCellClick = (row, col) => {
    if (props.onRowClick) {
      props.onRowClick(row, col);
    }
  };

  const onSortClick = (col) => {
    const _direction = sort[col.sort] ? sort[col.sort] * -1 : 1;
    setSort({ [col.sort]: _direction });
  };

  const handlePopoverOpen = (event, row) => {
    setPopover({...popover, [row._id]: event.currentTarget});
  };

  const handlePopoverClose = (event, row) => {
    delete popover[row._id];
    setPopover({...popover});
  };

  if (!data) {
    return (
      <div className={classes.center}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root} style={{ ...containerStyle }}>
      <Paper className={classes.paper} style={{ ...paperStyle }} elevation={0}>
        {HeaderComponent}

        {data && data.data && data.data.length ? (
          <>
        <PerfectScrollbar style={{flex: 1}}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => {
                  if (!col.label) {
                    return <TableCell key={index} style={{ padding: 0 }} />;
                  }
                  if (typeof col.label === 'function') {
                    return col.label(index);
                  }
                  return (
                    <TableCell
                      style={{
                        color: '#41475a',
                        fontWeight: 'bold',
                        fontSize: 14,
                        ...(col.headerStyle || {}),
                      }}
                      align={columnAlign}
                      key={col.label}
                      sortDirection={sort[col.sort] === 1 ? 'asc' : 'desc'}
                      classes={{ root: classes.tableCellRoot }}
                    >
                      {(col.sort && (
                        <TableSortLabel
                          active={true}
                          direction={sort[col.sort] === 1 ? 'asc' : 'desc'}
                          onClick={() => {
                            onSortClick(col);
                          }}
                        >
                          <Typography className={classes.header}>{col.label}</Typography>
                        </TableSortLabel>
                      )) ||
                        <Typography className={classes.header}>{col.label}</Typography>
                      }
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.data &&
                data.data.map(row => (
                  <TableRow
                    hover={(props.onRowClick && true) || false}
                    key={row._id}
                    style={{ cursor: (props.onRowClick && 'pointer') || 'default' }}
                    onMouseEnter={(event) => props.popover && handlePopoverOpen(event, row)}
                    onMouseLeave={(event) => props.popover && handlePopoverClose(event, row)}
                  >
                    {props.popover && props.isPopoverVisible(row) &&
                      <Popover
                        className={classes.popover}
                        classes={{
                          paper: classes.popoverPaper,
                        }}
                        open={Boolean(popover[row._id])}
                        anchorEl={popover[row._id]}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        onClose={(event) => handlePopoverClose(row)}
                        disableRestoreFocus
                        disableAutoFocus
                      >
                        {props.popover(row)}
                      </Popover>
                    }

                    {columns.map((col, index) => {
                      if (col.component) {
                        return col.component(row, col);
                        // <TableMenu row={row} rowActions={col.rowActions}></TableMenu>
                      }

                      return (
                        <TableCell
                          onClick={() => handleTableCellClick(row, col)}
                          align={columnAlign}
                          key={row._id + index}
                          classes={{ root: classes.tableCellRoot }}
                          style={{ ...(col.cellStyle || {}) }}
                        >
                          <div className={classes.textContainer}>
                          <Typography className={classes.text}>{col.value && col.value(row)}</Typography>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </PerfectScrollbar>
        <TablePagination
          hidden={!showPagination}
          rowsPerPageOptions={rowsPerPageOptions}
          labelRowsPerPage="Itens por página"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          component="div"
          count={data.totalItems || 0}
          rowsPerPage={limit}
          page={(data.page || page) - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handlePage}
          onChangeRowsPerPage={handleRowsPerPage}
          ActionsComponent={PaginationActions}
          classes={{
            root: classes.paginationRoot,
            spacer: classes.spacer,
            toolbar: classes.paginationToolbar,
          }}
        />
        {!showPagination &&
          <div style={{paddingTop: 16}}></div>
        }
        </>
        ) : EmptyTableComponent}
        <CircularLoading isLoading={isLoading} />
      </Paper>
    </div>
  );
}
SimpleTable.defaultProps = {
  rowsPerPageOptions: [5, 10, 15],
};

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    borderRadius: theme.border.radius,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: theme.main.padding.bottom,
    marginTop: theme.main.padding.top,
  },
  paper: {
    position: 'relative',
    overflowX: 'auto',
    borderRadius: theme.border.radius,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: theme.main.padding.top,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    paddingBottom: theme.main.padding.bottom,
  },
  table: {
    /** */
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  spacer: {
    flex: 0,
  },
  paginationRoot: {
    marginTop: 10,
  },
  paginationToolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    paddingLeft: 22,
    paddingRight: 22,
    paddingTop: 19,
    paddingBottom: 19,
    borderRadius: 16,
    maxWidth: 400,
  },
  header: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  text: {
    fontWeight: '500',
    fontSize: 12,
  },
  textContainer: {
    // maxHeight: 36,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkitLineClamp' : 2,
    '-webkit-box-orient': 'vertical',
  },
});

SimpleTable.defaultProps = {
  HeaderComponent: null,
  columns: [],
  containerStyle: {},
  query: defaultQuery,
  columnAlign: 'left',
  showPagination: true,
  isLoading: false,
  paperStyle: {},
};

export default withRouter(withStyles(styles)(SimpleTable));
