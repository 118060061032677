import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { TextField } from '@material-ui/core';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { Rule, validate } from "../../utils/formValidator";
import validator from 'validator';
import CommandPv1GprsFields from "components/commands/CommandPv1GprsFields";

const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/ ,/\d/, /\d/ ,/\d/, '-', /\d/, /\d/];

const CpfCnpjInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask= {(value) => {
        if (value.length <= 14)
          return cpfMask;
        else
          return cnpjMask;
        }
      }
      guide={false}
    />
  );
};


const DEFAULT_RULES = [
  Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
  Rule('matches', [/(^$)|\d\d\d\.\d\d\d\.\d\d\d-\d\d|\d\d\.\d\d\d\.\d\d\d\/\d\d\d\d-\d\d/], true, 'Formato inválido para o campo'),
]

const CpfCnpjField = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const [ value, setValue ] = useState('');

  const [ validations, setValidations ] = useState({
    rules: props.required ? DEFAULT_RULES : DEFAULT_RULES.slice(1, DEFAULT_RULES.length),
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const onChange = e => { setValue(e.target.value) }
  const onBlur = () => { validateField() }

  const validateField = () => {
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(value, validations.rules)})
  }


  const setFieldValue = (value) => {
    let mask = value.length <= 11 ? cpfMask : cnpjMask;
    setValue(conformToMask(value, mask).conformedValue);
  }

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: value.replace(/\D/g, ''),
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue
  }));

  return (
    <TextField
      {...props}
      ref={inputRef}
      InputProps={{inputComponent: CpfCnpjInput}}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={validations.isInvalid}
      helperText={validations.isInvalid && validations.message || ''}
    />
  )
});

export default CpfCnpjField
