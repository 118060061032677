import React from 'react';
import PropTypes from 'prop-types';
import { AutocompleteField } from 'components/fields';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  /** */
  container: {
    minHeight: 0,
    width: 300,
    marginRight: 17,
  },
  placeholder: {
    opacity: 1,
    fontSize: 14,
    paddingLeft: 18,
    fontWeight: 600,
    color: theme.palette.gray.gray_5,
  },
  input: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 16,
    minHeight: 38,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 10,
    paddingBottom: 0,
    cursor: 'pointer',
  },
}));

function SelectFilter(props) {
  const classes = useStyles();
  const {
    options,
    getOptionLabel,
    getOptionValue,
    noOptionsMessage,
    filterHandler,
    onChange,
    isClearable,
    value,
  } = props;
  const { placeholder, label } = props;

  return (
    <AutocompleteField
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={noOptionsMessage}
      placeholder={placeholder}
      showPlaceholder={Boolean(placeholder)}
      label={label}
      hideLabel={!label}
      isClearable={isClearable}
      InputProps={{
        disableUnderline: true,
        classes: {
          input: classes.input,
        },
      }}
      menuContainerStyle={{
        marginTop: 5,
      }}
      InputContainerClass={classes.container}
      classes={{ placeholder: classes.placeholder }}
      onChange={opt => onChange({ opt, filterHandler })}
      value={value}
    />
  );
}

SelectFilter.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
};

SelectFilter.defaultProps = {
  isClearable: false,
  options: [],
  noOptionsMessage: () => 'Nenhuma opção encontrada',
};

export default SelectFilter;
