import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { MyCheckbox as Checkbox } from 'components/lists/CheckboxList';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 'solid 1px #e3e4e8',
    marginTop: 15,
    flex: 1,
    paddingBottom: 20,
  },
  labelStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_5,
  },
  labelStatusIsOn: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.gray.gray_5,
    marginTop: 5,
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  labelIsOn: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.gray.gray_6,
    marginTop: 5,
  },
}));

const CommandToggle = (props) => {
  const classes = useStyles();
  const { data, status, setStatus, isEnabled, toggleEnabled } = props;
  // const [ checked, setChecked ] = useState(status[data.name] && status[data.name] || false);
  let { containerProps, currentStatus, showStatus } = props;

  if (!data.isOnLabel) {
    data.isOnLabel = 'Ligado';
  }
  if (!data.isOffLabel) {
    data.isOffLabel = 'Desligado';
  }
  if (!containerProps) {
    containerProps = {};
  }

  // useEffect(() => {
  // setStatus({...status, [data.name] : checked});
  // }, [checked]);

  return (
    <div className={classes.container} style={{ opacity: !isEnabled ? 0.4 : 1 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {toggleEnabled && (
          <div>
            <Checkbox setValue={toggleEnabled} checked={isEnabled} value={isEnabled} />
          </div>
        )}
        <div style={{ width: 253 }}>
          <div className={classes.label}>{data.label}</div>
          <div className={classes.labelIsOn}>
            {(status[data.name] && data.isOnLabel) || data.isOffLabel}
          </div>
        </div>
        {showStatus && (
          <div>
            <div className={classes.labelStatus}>Status atual</div>
            <div className={classes.labelStatusIsOn}>
              {(currentStatus && data.isOnLabel) || data.isOffLabel}
            </div>
          </div>
        )}
      </div>
      <Switch
        checked={(status[data.name] !== undefined && status[data.name]) || false}
        onChange={e => setStatus({ ...status, [data.name]: e.target.checked })}
        value={status[data.name]}
        color="primary"
        disabled={!isEnabled}
      />
    </div>
  );
};
CommandToggle.defaultProps = {
  isEnabled: true,
  toggleEnabled: null,
};
export default CommandToggle;

export const CommandSimpleToggle = (props) => {
  const classes = useStyles();
  const {
    data, state, setState, isEnabled, toggleEnabled,
  } = props;
  let { containerProps, currentStatus, showStatus } = props;

  if (!data.isOnLabel) {
    data.isOnLabel = 'Ligado';
  }
  if (!data.isOffLabel) {
    data.isOffLabel = 'Desligado';
  }
  if (!containerProps) {
    containerProps = {};
  }

  return (
    <div
      className={classes.container}
      {...containerProps}
      style={{ ...(containerProps.style || {}), opacity: !isEnabled ? 0.4 : 1 }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {toggleEnabled && (
          <div>
            <Checkbox setValue={toggleEnabled} checked={isEnabled} value={isEnabled} />
          </div>
        )}
        <div style={{ width: 253 }}>
          <div className={classes.label}>{data.label}</div>
          <div className={classes.labelIsOn}>{(state && data.isOnLabel) || data.isOffLabel}</div>
        </div>
        {showStatus && (
          <div>
            <div className={classes.labelStatus}>Status atual</div>
            <div className={classes.labelStatusIsOn}>
              {(currentStatus && data.isOnLabel) || data.isOffLabel}
            </div>
          </div>
        )}
      </div>
      <Switch
        checked={state}
        onChange={e => setState(e.target.checked)}
        value={state}
        color="primary"
        disabled={!isEnabled}
      />
    </div>
  );
};

CommandSimpleToggle.defaultProps = {
  isEnabled: true,
  toggleEnabled: null,
};
