import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { fetchAuthenticated } from 'services/fetch';

import { emitEvent } from "utils/events";
import UserForm from "components/forms/UserForm";
import ModalRemove from 'components/modals/ModalRemove';
import { logAction } from 'utils/logs';

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

function EditUser(props) {
  const classes = useStyles();
  const { history, match, setBreadcrumbs } = props;
  const [user, setUser] = useState();
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  // User base user url
  const userURL = `user/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: `/cadastros/usuarios`, label: `Usuários` },
      { path: ``, label: `${user && user.name || ''}` }
    ]);
    return () => {
      setBreadcrumbs([]);
    }
  }, [setBreadcrumbs, match.params.id, user]);

  // Load user info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');

      const response = await fetchAuthenticated('get', userURL);

      // Any erro will redirect to companies list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/usuarios');
      } else {
        const _user = await response.json();
        setUser(_user);
        logAction(`Acesso ao cadastro do usuário ${_user.username}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, userURL])

  // Handle form submit
  const handleSubmit = async (data) => {
    try {
      const response = await fetchAuthenticated('put', userURL, data);
      if (!response.ok) {
        throw new Error('')
      } else {
        const _user = await response.json();
        logAction(`Edição do usuário ${_user.username}`);
        emitEvent('showSnack', { message: 'Usuário atualizado com sucesso', type: 'success' });
      }
    } catch (err) {
      throw err;
    }
  }

  const handleCancel = async (event) => {
    history.push('/cadastros/usuarios');
  }

  const handleRemove = async (event) => {
    setConfirmRemove(true);
  }

  const handleRemoveConfirm = async (event) => {
    setModalLoading(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', userURL)

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover o usuário', type: 'error' });
      } else {
        logAction(`Remoção do usuário ${user.username}`);
        emitEvent('showSnack', { message: 'Usuário removido com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setModalLoading(false);
      setConfirmRemove(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/usuarios');
      }
    }
  }

  const handleRemoveCancel = async (event) => {
    setModalLoading(false);
  }

  return (
    <div>
      <UserForm
        title='Detalhes do Usuário'
        data={user}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
        edit={true}
      />

      <ModalRemove
        isVisible={confirmRemove}
        setIsVisible={setConfirmRemove}
        titleLabel='do usuário'
        onSubmit={handleRemoveConfirm}
        onCancel={handleRemoveCancel}
        isLoading={modalLoading}
        removeLabel='o usuário'
        extraContent='Ao realizar está ação o usuário não terá mais acesso ao sistema'
      />

    </div>
  );
}

export default EditUser;
