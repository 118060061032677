import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Logo from '../assets/img/logo_xglobal_menu.png';
import { getItem } from '../services/local-storage';
import { filterPage } from 'services/permissions';
import { Menu, MenuItem } from '@material-ui/core';
import { emitEvent } from '../utils/events';
import { logoutUser } from '../services/user';
import { withRouter } from 'react-router';
import Close from '@material-ui/icons/Close';
import Lock from '@material-ui/icons/Lock';
import Edit from '@material-ui/icons/Edit';
import Permission from 'components/permissions/Permission';

import ModalChangePassword from './modals/ModalChangePassword';

const styles = theme => ({
  root: {},
  appBar: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: theme.palette.background.white,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    boxShadow: '0 2px 10px 0 rgba(201, 208, 240, 0.28)',
    position: 'relative',
  },
  appBarDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
    overflowX: 'scroll',
    // Hide scrollbar
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  pageItem: {
    marginRight: 56,
  },
  logo: {
    marginRight: 56,
    height: 56,
    width: 50,
  },
  avatar: {
    marginLeft: 10,
    width: 71,
    height: 71,
    borderRadius: 18,
    backgroundColor: '#a6c6f4',
    fontWeight: 'bold',
  },
  userGreeting: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_1,
  },
  menuLinkActive: {
    textDecoration: 'none',
  },
  menuLink: {
    textDecoration: 'none',
    color: theme.palette.gray.navBar,
    fontSize: 16,
    fontWeight: 600,
  },
  active: {
    color: theme.palette.text.blue.activeNavBar,
    fontWeight: 'bold',
    fontSize: 20,
  },
});

function NavBar(props) {
  const [menuEl, setMenuEl] = useState(null);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const { classes, history } = props;
  let { pages } = props;
  pages = pages.filter(page => !page.hidden && filterPage(page));

  const user = getItem('user');

  const toggleMenu = (event) => {
    if (menuEl) {
      setMenuEl(null);
    } else {
      setMenuEl(event.currentTarget);
    }
  };

  const logout = async () => {
    emitEvent('showGlobalLinearProgress');
    await logoutUser();
    emitEvent('hideGlobalLinearProgress');
    history.push('/login');
  };

  const goToUserProfile = async () => {
    history.push('/editar-perfil/'+user._id);
    setMenuEl(null);
  }

  const goToEditCompany = async () => {
    history.push('/editar-empresa/'+user.company._id);
    setMenuEl(null);
  }

  useEffect(() => {
    if (showPasswordChange) {
      setMenuEl(null);
    }
  }, [showPasswordChange]);

  return (
    <div className={classes.root}>
      <AppBar elevation={2} className={classes.appBar} position="static">
        <Toolbar className={classes.toolBar}>
          <div className={classes.appBarDiv}>
            <Link to="/">
              <img alt="Logo XGlobal" className={classes.logo} src={Logo} />
            </Link>
            {pages.map(page => (
              <Typography key={page.path} variant="h6" className={classes.pageItem}>
                <NavLink
                  to={{ pathname: page.path}}
                  activeClassName={classes.active}
                  className={classes.menuLink}
                >
                  {page.name}
                </NavLink>
              </Typography>
            ))}
          </div>
          <div
            className={classes.appBarDiv}
            style={{ cursor: 'pointer' }}
            onClick={e => toggleMenu(e, menuEl)}
          >
            <Typography variant="subtitle2" className={classes.userGreeting}>
              Olá, {(user.name || user.username).split(' ')[0].split('@')[0]}
            </Typography>
            <Avatar
              alt="Imagem do usuário"
              className={classes.avatar}
              src={user.company && user.company.logo && user.company.logo.url}
            >
              {user.company && user.company.name.split(' ')[0].substr(0, 1)}
            </Avatar>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        id="user-actions-menu"
        open={Boolean(menuEl)}
        anchorEl={menuEl}
        onClose={() => setMenuEl(null)}
      >
        <MenuItem onClick={goToUserProfile} style={{ display: 'flex', justifyContent: 'space-between' }}>
          Editar perfil
        </MenuItem>
        <Permission names={["EditCompany", "EditFullCompany"]}>
          <MenuItem onClick={goToEditCompany} style={{ display: 'flex', justifyContent: 'space-between' }}>
            Editar empresa
          </MenuItem>
        </Permission>
        <MenuItem
          style={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={() => setShowPasswordChange(true)}
        >
          Alterar senha
        </MenuItem>
        <MenuItem onClick={logout} style={{ display: 'flex', justifyContent: 'space-between' }}>
          Sair
        </MenuItem>
      </Menu>
      {showPasswordChange && <ModalChangePassword setIsVisible={setShowPasswordChange} />}
    </div>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(NavBar));
