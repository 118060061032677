import React, { useState, useEffect, useReducer } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  ButtonSubmit,
  ButtonRemove,
  ButtonCancel
} from "components/buttons/ButtonsForm";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import SimpleTable from "components/table/Table";
import {TableColumn} from 'components/table/TableMenu';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import Add from '@material-ui/icons/Add';
import RemoveColumn from 'components/table/columns/RemoveColumn';
import ModalEmergencyContact from 'components/modals/ModalEmergencyContact';

import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from 'assets/icon/lixeira.svg';
import EditIcon from 'assets/icon/edit.svg';
import ReactSVG from 'react-svg'

import FormControl from "@material-ui/core/FormControl";
import { getItem, setItem } from "../../services/local-storage";
import { emitEvent } from "../../utils/events";
import { useFields } from "../../hooks/fields";
import { fetchAuthenticated } from "services/fetch";
import { validatePermission } from "services/permissions";

import Form, { FormSection } from "./Form";
import {
  TextField as MyTextField,
  PhoneField as MyPhoneField,
  MobilePhoneField as MyMobilePhoneField,
  EmailField,
  ZipcodeField,
  AutocompleteField,
  CpfCnpjField,
  RadioGroupField,
  FileUpload,
  EmergencyContact
} from "../fields";

import { DynamicField } from "components/fields";



const CompanyForm = props => {
  const { classes } = props;
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState({
    cities: false,
    grs: false,
    states: false,
    form: false
  });
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [grCompanies, setGRCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editContact, setEditContact] = useState();
  const [emergencyContacts, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'init':
        return { data: [...action.payload]};
      case 'add':
        return { data: [...state.data, action.payload]};
      case 'remove':
        return { data: state.data.filter(item => item._id !== action.payload) };
      case 'edit':
        return {
          data: state.data.map(item => {
            if(item._id === action.payload._id) return action.payload;
            return item;
          }),
        };
      default:
        return state;
    }
  }, { data: []});

  useEffect(() => {
    if(props.data && Object.keys(props.data).length > 0) {
      if(props.data.contacts.length > 0){
        dispatch({
          type: 'init',
          payload: props.data.contacts,
        });
      }
    }
    // if(props.data && props.data.contacts && props.data.contacts.length > 0) {
  }, [props.data]);

  const fields = useFields([
    "cpf_cnpj",
    "name",
    "stateRegistration",
    "clientCode",
    "email",
    "phone",
    "mobile",
    "address",
    "addressHouseNumber",
    "addressAdditionalInfo",
    "addressZipcode",
    "addressCity",
    "addressNeighborhood",
    "addressState",
    "type",
    "companies",
    "status",
    "logo",
  ]);

  // Load states from IBGE if it is not found on local storage
  useEffect(() => {
    setIsLoading({ ...isLoading, states: true });
    let statesFromIBGE = getItem("statesFromIBGE");
    if (!statesFromIBGE) {
      console.debug("Loding states from IBGE");
      const fn = async () => {
        const response = await fetch(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        );
        statesFromIBGE = await response.json();
        statesFromIBGE = statesFromIBGE.sort((a, b) =>
          a.nome > b.nome ? 1 : -1
        );
        setItem("statesFromIBGE", statesFromIBGE);
        setStates(statesFromIBGE);
        setIsLoading({ ...isLoading, states: false });
      };
      fn();
    } else {
      setStates(statesFromIBGE);
      setIsLoading({ ...isLoading, states: false });
    }
  }, []);

  // Load cities from IBGE every time a state is selected
  useEffect(() => {
    if (isLoading.cities) {
      // Clear options
      let citiesFromIBGE = [];
      setCities(citiesFromIBGE);

      if (fields.addressState.current.value) {
        console.log("Loading cities from IBGE");
        setCities(citiesFromIBGE);
        setIsLoading({ ...isLoading, cities: true });

        const fn = async () => {
          const result = await fetch(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${fields.addressState.current.value.id}/municipios`
          );
          citiesFromIBGE = await result.json();
          citiesFromIBGE = citiesFromIBGE.sort((a, b) =>
            a.nome > b.nome ? 1 : -1
          );

          setCities(citiesFromIBGE);
          setIsLoading({ ...isLoading, cities: false });
        };
        fn();
      } else {
        setIsLoading({ ...isLoading, cities: false });
      }
    } else {
      setIsLoading({ ...isLoading, cities: false });
    }
  }, [isLoading.cities]);

  // Load list of GRs
  useEffect(() => {
    const fn = async () => {
      setIsLoading({ ...isLoading, grs: true });
      const data = await (await fetchAuthenticated(
        "get",
        'company?notPaginated=true&fields=_id,name&filter={"type":"GR"}'
      )).json();
      setGRCompanies(data.data);
      setIsLoading({ ...isLoading, grs: false });
    };
    fn();
  }, []);

  /** ======================
   * LOAD INITIAL FORM DATA
   * ====================== */
  useEffect(() => {
    setFormDisabled(true);
    if (props.data) {
      Object.entries(props.data).forEach(([key, value]) => {
        if (fields[key] && fields[key].current) {
          if (fields[key].current.setFieldValue) {
            fields[key].current.setFieldValue(value);
          }
        }
      });
      // for (const [key, value] of Object.entries(props.data)) {
      //   if (fields[key] && fields[key].current) {
      //     if (fields[key].current.setFieldValue) {
      //       fields[key].current.setFieldValue(value);
      //     }
      //   }
      // }
    }
    setFormDisabled(false);
  }, [props.data]);

  const validateForm = async event => {
    event.preventDefault();
    const nonValidatedFields = Object.values(fields).filter(
      f => !f.current.alreadyValidated
    );
    nonValidatedFields.forEach(f => {
      console.log("Validating form");
      f.current.validateField();
    });
    setSubmitForm(true);
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Check for invalid fields
    const invalidFields = Object.values(fields).filter(
      f => f.current.isInvalid
    );
    if (invalidFields.length > 0) {
      emitEvent("showSnack", {
        message: "Erro ao validar formulário",
        type: "error"
      });
      setSubmitForm(false);
    } else {
      // All fields are valid
      // Commit changes to the server
      setFormDisabled(true);
      setIsLoading({ ...isLoading, form: true });
      // Get all fields
      const data = {};
      Object.entries(fields).forEach(([key, value]) => {
        data[key] = value.current.raw;
      });
      // for (const [key, value] of Object.entries(fields)) { data[key] = value.current.raw; }

      // Update person type fields

      data.personType = data.cpf_cnpj.length === 11 ? "PF" : "PJ";

      data[data.personType === "PF" ? "cnpj" : "cpf"] = "";
      data[data.personType === "PF" ? "cpf" : "cnpj"] = data.cpf_cnpj;

      // Delete unecessary entries
      delete data.cpf_cnpj;
      delete data.clientCode;

      try {

        const contacts = emergencyContacts.data.map (contact => {
          let { _id, ...rest } = contact;
          return rest;
        });
        await props.handleSubmit({ ...data, contacts});
        setFormDisabled(false);
        setIsLoading({ ...isLoading, form: false });
        setSubmitForm(false);
      } catch (err) {
        emitEvent("showSnack", {
          message: "Erro ao processar formulário",
          type: "error"
        });
        setFormDisabled(false);
        setIsLoading({ ...isLoading, form: false });
        setSubmitForm(false);
      }
    }
  };
  useEffect(() => {
    if (submitForm) {
      (async () => {
        await handleSubmit();
      })();
    }
  }, [submitForm]);

  // useEffect(() => {
  //   if(props.data) {
  //     setEmergencyContacts(props.data.emergencyContacts || []);
  //   }
  // }, [props.data]);

  const getNotificationSettingsLabel = (notification) => {
    let label = ''
    if(!notification) {
      return label;
    }
    if(notification.sms) {
      label = 'SMS';
    }
    if(notification.email) {
      if(label) { label += '/Email'}
      else { label = 'Email'}
    }
    return label;
  }

  const columns = [
    { label:'Nome', value: (row) => row.name },
    { label:'Email', value: (row) => row.email },
    { label:'Celular', value: (row) => row.mobile },
    { label:'Cercas', value: (row) => getNotificationSettingsLabel(row.fence) },
    // { label:'Ancoragem', value: (row) => getNotificationSettingsLabel(row.beacon)},
    { label:'Suspeita', value: (row) => getNotificationSettingsLabel(row.suspect)},
    { label:'Emergência', value: (row) => getNotificationSettingsLabel(row.emergency)},
    { component: (row) => (
      (validatePermission('EditFullCompany') || validatePermission('EditCompany')) &&
      <TableColumn
        key={row._id + 'EditRemove'}
        row={row}>
        <div className={classes.iconContainer}>
            <IconButton
            style={{ boxShadow: 'unset', width: 47 }}
            title="Editar"
            className={classes.removeIcon}
            onClick={() => {
              setEditContact(row);
              setShowModal(true);
            }}>
              <ReactSVG
                src={EditIcon}
              />
          </IconButton>
          <IconButton
            style={{ boxShadow: 'unset', width: 47,marginLeft: 15 }}
            title="Remover"
            className={classes.removeIcon}
            onClick={() => {
              dispatch({
                type: 'remove',
                payload: row._id,
              })
            }}>
              <ReactSVG
                src={RemoveIcon}
              />
          </IconButton>
        </div>
      </TableColumn>
      || null
    )},
  ];

  const onAddedContact = (contact) => {
    dispatch({
      type: 'add',
      payload: contact,
    })
  }

  const onEditContact = (contact) => {
    dispatch({
      type: 'edit',
      payload: contact,
    })
  }

  return (
    <Form
      paperStyle={{backgroundColor: 'transparent', padding: 0}}
      containerStyle={{padding: 0}}
    >
      <form onSubmit={validateForm} noValidate>
        <Form title={props.title}>
          <FormSection title="Informações administrativas" divider>
            <Grid container alignItems="flex-start">
              <Grid item>
                <RadioGroupField
                  ref={fields.status}
                  row
                  required
                  title="Status"
                  default="Ativa"
                  options={[{ name: "Ativa" }, { name: "Inativa" }]}
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt.name}
                  labelProps={{
                    style: {
                      transform: "translate(0, 1.5px) scale(0.75)",
                      transformOrigin: "top left"
                    }
                  }}
                  formControlLabelProps={{
                    labelPlacement: "start"
                  }}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item className={classes.radioGroup}>
                <RadioGroupField
                  ref={fields.type}
                  row
                  required
                  title="Tipo de Empresa"
                  default="Cliente"
                  options={[
                    { value: "Cliente", label: "Cliente" },
                    { value: "GR", label: "Gerenciadora de Risco" }
                  ]}
                  labelProps={{
                    style: {
                      transform: "translate(0, 1.5px) scale(0.75)",
                      transformOrigin: "top left"
                    }
                  }}
                  formControlLabelProps={{
                    labelPlacement: "start"
                  }}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item className={classes.radioGroup}>
                <FileUpload
                  ref={fields.logo}
                  disabled={!validatePermission('EditFullCompany') && !validatePermission('EditCompany')}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection title="Informações gerais" divider>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CpfCnpjField
                  required
                  ref={fields.cpf_cnpj}
                  label="CPF/CNPJ"
                  name="cpf_cnpj"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyTextField
                  required
                  ref={fields.name}
                  label="Razão Social"
                  name="name"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyTextField
                  required
                  ref={fields.stateRegistration}
                  label="Insc. Estadual"
                  name="stateRegistration"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyTextField
                  disabled
                  ref={fields.clientCode}
                  label="Código Cliente"
                  name="clientCode"
                  type="number"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyPhoneField
                  required
                  ref={fields.phone}
                  label="Telefone"
                  name="phone"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyMobilePhoneField
                  required
                  ref={fields.mobile}
                  label="Celular"
                  name="mobile"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <EmailField
                  required
                  ref={fields.email}
                  label="Email"
                  name="email"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection title="Endereço" divider>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyTextField
                  required
                  ref={fields.address}
                  label="Endereço"
                  name="address"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyTextField
                  required
                  ref={fields.addressHouseNumber}
                  label="Número"
                  name="addressHouseNumber"
                  type="number"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyTextField
                  ref={fields.addressAdditionalInfo}
                  label="Complemento"
                  name="addressAdditionalInfo"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ZipcodeField
                  required
                  ref={fields.addressZipcode}
                  label="CEP"
                  name="addressZipcode"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MyTextField
                  required
                  ref={fields.addressNeighborhood}
                  label="Bairro"
                  name="addressNeighborhood"
                  fullWidth
                  className={classes.fullWidthField}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth className={classes.fullWidthField}>
                  <AutocompleteField
                    innerRef={fields.addressState}
                    required
                    label="UF"
                    name="addressState"
                    options={states}
                    isLoading={isLoading.states}
                    getOptionLabel={opt => `[${opt.sigla}] ${opt.nome}`}
                    getOptionValue={opt => opt.id}
                    onChange={() => {
                      fields.addressCity.current.resetField();
                      setIsLoading({ ...isLoading, cities: true });
                    }}
                    disabled={formDisabled || !validatePermission('EditFullCompany')}
                    noOptionsMessage={() => "Nenhum estado encontrado"}
                    loadingMessage={() => "Carregando estados..."}
                    isClearable
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth className={classes.fullWidthField}>
                  <AutocompleteField
                    required
                    innerRef={fields.addressCity}
                    label="Cidade"
                    name="addressCity"
                    options={cities}
                    isLoading={isLoading.cities}
                    getOptionLabel={opt => `${opt.nome}`}
                    getOptionValue={opt => opt.id}
                    loadingMessage={() => "Carregando cidades..."}
                    noOptionsMessage={() => "Nenhuma cidade encontrada"}
                    isClearable
                    disabled={formDisabled || !validatePermission('EditFullCompany')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormSection>

          <FormSection title="Gerênciadoras de Risco" divider>
            <Grid item xs={12} md={3}>
              <Grid container justify="flex-start">
                <DynamicField
                  ref={fields.companies}
                  component={AutocompleteField}
                  refField="innerRef"
                  label="Gerenciadora de Risco"
                  name="gr_companie"
                  options={grCompanies}
                  isLoading={isLoading.grs}
                  getOptionLabel={opt => `${opt.name}`}
                  getOptionValue={opt => opt._id}
                  loadingMessage={() => "Carregando empresas..."}
                  noOptionsMessage={() => "Nenhuma empresa encontrada"}
                  isClearable
                  getRawValue={opt => opt._id}
                  disabled={formDisabled || !validatePermission('EditFullCompany')}
                />
              </Grid>
            </Grid>
          </FormSection>

          {/* <FormSection title="Contatos de emergência" divider>
            <DynamicField
              ref={fields.contacts}
              refField="ref"
              component={EmergencyContact}
              componentStyle={{ maxWidth: "100%" }}
            />
          </FormSection> */}
        </Form>
        <Form containerStyle={{paddingTop: 0}}>
          <ModalEmergencyContact
            onSubmit={onAddedContact}
            onEdit={onEditContact}
            isVisible={showModal}
            setIsVisible={setShowModal}
            contact={editContact}
            onClose={() => {
              setEditContact(null);
            }}
          />
          <SimpleTable
            columns={columns}
            showPagination={false}
            data={emergencyContacts}
            containerStyle={{
              padding: 0,
              margin: 0,
            }}
            EmptyTableComponent={<Typography style={{paddingTop: 40, paddingBottom: 40, fontSize: 15}}>Nenhum contato de emergência cadastrado</Typography>}
            paperStyle={{
              padding: 0,
            }}
            HeaderComponent={(
              <TableHeader
                headerFilters={[
                  <Typography className={classes.header} key='headerFilter'>
                    Contatos de emergência
                  </Typography>
                ]}
                headerActions={(validatePermission('EditFullCompany') || validatePermission('EditCompany')) && [
                  <TableHeaderAction
                    title="Adicionar contato de emergência"
                    Icon={Add}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                ] || []}
                headerActionsContainerStyle = {{ marginBottom: 5 }}
              >
              </TableHeader>
            )}
          />
        </Form>
        <Grid container justify="flex-end" spacing={1} className={classes.buttonsContainer}>
          {props.handleCancel && (
            <Grid item xs={12} md="auto">
              <ButtonCancel
                onClick={props.handleCancel}
                disabledCondition={formDisabled}
              ></ButtonCancel>
            </Grid>
          )}

          {props.handleRemove && (
            <Grid item xs={12} md="auto">
              <ButtonRemove
                onClick={props.handleRemove}
                disabledCondition={formDisabled}
              ></ButtonRemove>
            </Grid>
          )}

          {props.handleSubmit && (
            <Grid item xs={12} md="auto">
              <ButtonSubmit disabledCondition={formDisabled}></ButtonSubmit>
            </Grid>
          )}
        </Grid>
      </form>
    </Form>
  );
};

const styles = theme => ({
  btnRoot: {
    minWidth: 125,
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
    // Small screens
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  fullWidthField: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "85%"
    }
  },
  radioGroup: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 50
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 16,
      marginLeft: 0
    }
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  buttonsContainer: {
    paddingBottom: 25,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

export default withStyles(styles)(CompanyForm);
