import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { emitEvent } from 'utils/events';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  paper: props => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    display: "flex",
    flexDirection: "column",
    width: '100%',
    minWidth: props.minWidth || 932,
    borderRadius: 22,
  }),
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 300,
  },
  headerText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  submitButton: {
    boxShadow: 'unset',
    minWidth: 145,
    minHeight: 45,
    fontSize: 17,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    marginTop: 10,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF88',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 700,
  },
}));



function Modal(props) {
  const {
    title,
    subtitle,
    buttonLabel,
    onSuccessMessage,
    onErrorMessage,
    isVisible,
    setIsVisible,
    disableButton,
    hideButton,
    maxWidth,
    minWidth,
    isLoading,
    preventDefaultSubmit,
    ModalHeaderProps,
    ModalButtonContainerProps,
  } = props;
  const classes = useStyles({ minWidth: minWidth });

  const closeModal = () => {
    setIsVisible(false);
    if (props.onClose) {
      props.onClose();
    }
  }

  const handleRemove = async (e) => {
    setIsVisible(false);
    if (props.handleRemove) {
      props.handleRemove();
    }
  }

  const handleSubmit = async (e) => {
    if (!preventDefaultSubmit) {
      setIsVisible(false);
    }
    const success = await props.handleSubmit();

    if (!preventDefaultSubmit) {

      if (success) {
        if (onSuccessMessage) {
          emitEvent('showSnack', { message: onSuccessMessage, type: 'success' });
        }
      } else {
        if (onErrorMessage) {
          emitEvent('showSnack', { message: onErrorMessage, type: 'error' });
        }
      }

    }
  }

  return (
    <Dialog
      open={isVisible}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      maxWidth={maxWidth}
      fullWidth={true}
      classes={{ paper: classes.paper }}
      style={{ minWidth: minWidth }}
    >
      <PerfectScrollbar>
        {isLoading &&
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        }
        <Grid container direction="column" style={{marginBottom: 16}}>
          <div className={classes.modalHeader} {...ModalHeaderProps}>
            <Typography className={classes.headerText} id="modal-title">
              {title}
            </Typography>
            <IconButton onClick={closeModal}><CloseIcon></CloseIcon></IconButton>
          </div>
          <p style={{ marginTop: 0, fontSize: 16, color: '#41475a' }}>{subtitle}</p>
        </Grid>
        {props.children}
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="baseline"
          {...ModalButtonContainerProps}
        >
          {!hideButton &&
            <Button
              className={classes.submitButton}
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              onClick={handleSubmit}
              disabled={disableButton}
            >
              {buttonLabel}
            </Button>
          }
        </Grid>
      </PerfectScrollbar>
    </Dialog>
  )
}

Modal.defaultProps = {
  setIsVisible: (arg) => null,
  disableButton: false,
  hideButton: false,
  maxWidth: 'md',
  isLoading: false,
  preventDefaultSubmit: false,
  ModalHeaderProps: {},
  ModalButtonContainerProps: {},
}

export default Modal;

