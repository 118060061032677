import React, {useState} from "react";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from "react-router-dom";
import ListFence from './List';
import NewFence from './New';
import EditFence from './Edit';
import SubRoutes from "components/SubRoutes";

import  Typography  from "@material-ui/core/Typography";

function Cercas(props) {
  const { classes, match } = props;
  const [ breadcrumbs, setBreadcrumbs ] = useState([]);
  const pages = [
    {
      name: "Cercas",
      path: '/nova',
      component: NewFence,
    },
    {
      name: "Cercas",
      path: '/:id',
      component: EditFence,
    },
    {
      name: "Cercas",
      path: '',
      component: ListFence,
    },
  ]
  return (
    // <div className={classes.root}>
      // {/* Para definir as rotas precisamos importar o compoenente SubRoutes */}
      <SubRoutes setBreadcrumbs={setBreadcrumbs} defaultPage={0} pages={pages} match={match}></SubRoutes>
    // </div>
  );
};

const styles = theme => {/** */};
export default withStyles(styles)(Cercas);
