import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Rule, validate } from '../../utils/formValidator';


const useStyles = makeStyles(() => ({
  labelPlacementStart: {
    marginLeft: -16,
  },
}));

function RadioGroupField(props, ref) {
  const classes = useStyles();
  const inputRef = useRef(null);

  // Default props do no work with `fowardRef`
  const {
    getOptionValue = opt => opt.value,
    getOptionLabel = opt => opt.label,
    labelProps = {},
    formControlLabelProps = {},
    options = [],
    row = false,
    marginLeft = 16,
    disabled,
  } = props;

  const [value, setValue] = useState(props.default || '');

  const [validations, setValidations] = useState({
    rules: [
      Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const validateField = (_value) => {
    const v = _value || value;
    const currentRules = (props.required || v) ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(v, currentRules),
    });
  };

  const resetField = () => {
    setValue('')
    setValidations({
      ...validations,
      alreadyValidated: false,
      isInvalid: false,
      message: ''
    })
  }

  const setFieldValue = (value) => {
    setValue(value);
  };

  const onChange = (event) => {
    setValue(event.target.value);
    validateField(event.target.value);
    if (props.onChange) { props.onChange(event); }
  };

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: value,
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue,
    resetField,
  }));

  return (
    <FormControl component="fieldset" error={validations.isInvalid}>
      <FormLabel component="legend" {...labelProps}>{props.title}</FormLabel>
      <RadioGroup
        aria-label="Tipo de Empresa"
        name="type"
        row={row}
        value={value}
        onChange={onChange}
      >
        {options.map((opt, index) => (
          <FormControlLabel
            key={index}
            label={getOptionLabel(opt)}
            value={getOptionValue(opt)}
            control={<Radio color="primary" disabled={disabled}/>}
            style={{ marginLeft: index > 0 ? marginLeft : 0 }}
            {...formControlLabelProps}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default forwardRef(RadioGroupField);
