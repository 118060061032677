import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
import { post } from 'services/fetch';
import Modal from 'components/modals/Modal';
import { getUser } from 'services/user';

import * as moment from 'moment';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  textarea: {
    borderRadius: 22,
    border: 'solid 1px #808492',
    resize: 'none',
    outline: 'none',
    padding: 22,
    fontFamily: 'Raleway',
    fontSize: 15,
    width: '100%',
    fontWeight: '400',
  },
  lastObs: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  obsText: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
    wordWrap: 'break-word',
  },
  obsDate: {
    fontSize: 15,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
}));

const ModalObs = (props) => {
  const { isVisible, setIsVisible, equipment, onSubmit } = props;
  const classes = useStyles();
  const [lastObs, setLastObs] = useState({});
  const [text, setText] = useState("");

  const [showModal, setShowModal] = useState(isVisible);

  useEffect(() => {
    if (equipment && equipment.lastObservation) {
      setLastObs(equipment.lastObservation);
    }
  }, [equipment])

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleSubmit = async () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
    const user = await getUser();
    const response = await post('equipment-observation', {
      text,
      equipment: equipment._id,
      user: user._id,
    });
    const newObs = await response.json();
    if (newObs) {
      logAction(`Adicionou uma observação ao equipamento ${equipment.shortImei}`)
      if (onSubmit) {
        onSubmit();
      }
      setLastObs(newObs);
      return true;
    }
  }
  const onChange = (e) => {
    setText(e.target.value);
  }
  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
  }
  return (
    <Modal
      handleSubmit={handleSubmit}
      onSuccessMessage="Observação salva com sucesso."
      isVisible={showModal} setIsVisible={setShowModal} title="Observações" buttonLabel="Salvar"
      onClose={onClose}
    >
      <textarea onChange={onChange} placeholder="Observação sobre o equipamento" className={classes.textarea} rows="7">
      </textarea>
      <div style={{ paddingTop: 34 }}>
        <Typography className={classes.lastObs}>Última observação</Typography>
        <Typography className={classes.obsText}>{lastObs.text || 'Nenhuma observação foi realizada.'}</Typography>
        <Typography className={classes.obsDate}>{lastObs.date && moment(lastObs.date).format('DD/MM/YY HH:mm')}</Typography>
      </div>
    </Modal>
  )
}

export default ModalObs;
