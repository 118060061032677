// General imports
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import SubBar from "components/SubBar";

// Material-ui imports
import {
  Typography,
  Button,
  Grid,
  Paper,
  withStyles,
} from '@material-ui/core';

// icon imports
import StableIcon from 'assets/icon/dashboard/stable.png';
import PositionIcon from 'assets/icon/dashboard/position.png';
import BatteryIcon from 'assets/icon/dashboard/battery.png';
import EmergencyIcon from 'assets/icon/dashboard/emergency.png';
import NoPositionIcon from 'assets/icon/dashboard/no_position.png';

// filter imports
import { InputAdornment } from '@material-ui/core';
import Filter from 'components/filter/Filter';
import FilterAutoComplete from "components/filter/FilterAutoComplete";
import Search from '@material-ui/icons/Search';
import { buildUrl, defaultQuery } from 'utils/query';
import Permission from 'components/permissions/Permission';

// hook imports
import { useAuthenticated, useFetch } from 'hooks/fetch';
import { useEffectSkipFirst } from 'hooks/common';

// utils
import { updateQueryFilter } from 'utils/query';
import { getEquipmentRowActions } from 'utils/equipment';
import {
  datetimeFormatter,
  percentageFormatter,
  latlongFormatter,
  poffFormatter,
} from 'utils/formatters'

// general project imports
import { CommandGprs, CommandAdmin } from 'components/commands/Commands';
import SimpleTable from 'components/table/Table';
import { StatusColumn } from 'components/table/columns';
import ModalObs from 'components/modals/ModalObs';
import TableMenu, {TableColumn} from 'components/table/TableMenu';
import { isRfOn, getPositionIndicator, minutesToTimeString } from 'utils/helpers';
import { emitEvent } from 'utils/events';
import { getBreadcrumbsFor } from 'utils/breadcrumbs';
import { useAccessLog } from 'hooks/logs';


const EquipmentList = (props) => {
  const { classes, location, match, history } = props;
  const situation = match.params.situation;
  const situationInfo = {
    'estaveis': { situation: 'STABLE', label: 'Equipamentos estáveis', icon: StableIcon, color: "#5bf293", },
    'bateria-baixa': { situation: 'LOW_BATTERY', label: 'Equipamentos com bateria baixa', icon: BatteryIcon, color: "#fbe622", },
    'sem-posicionar': { situation: 'NO_POSITIONING', label: 'Equipamentos sem posicionar', icon: NoPositionIcon, color: "#ffc500", },
    'posicoes-atrasadas': { situation: 'DELAYED_POSITIONS', label: 'Equipamentos com posições atrasadas', icon: PositionIcon, color: "#fd8f44", },
    'jammer-detectado': { situation: 'JAMMING_DETECTED', label: 'Equipamentos em emergência', icon: EmergencyIcon, color: "#e44a4a", }
  };

  useAccessLog(`Acesso a lista de ${situationInfo[situation].label.toLowerCase()}`)

  const breadcrumbs = getBreadcrumbsFor(location.pathname);

  const [reload, setReload] = useState(false);
  const onModalObsSubmit = () => { setReload(!reload); }

  // set default query
  const queryObject = {
    filter: {
      situation: situationInfo[situation].situation,
      "currentCompany._id": { $exists: true},
      lastEvent: { $exists: true},
    },
  };
  const [query, setQuery] = useState({
    queryString: '?filter=' + JSON.stringify(queryObject.filter),
    queryObject,
  });

  const basePath = 'equipment/situation';
  const [fetchPath, setFetchPath] = useState(buildUrl(basePath, location) + query.queryString);

  // Load equipments from server
  const { data } = useAuthenticated(useFetch, fetchPath, {
    beforeFetch: () => { emitEvent('showGlobalLinearProgress'); },
    afterFetch: () => { emitEvent('hideGlobalLinearProgress'); },
    reload,
  });

  // Update query string
  // necessary to filters to work
  useEffectSkipFirst(() => {
    if (query.queryString) {
      setFetchPath(`${basePath}${query.queryString}`);
    }
  }, [query])


  const onMenuItemClick = () => { setReload(!reload) }
  const rowStatusActions = getEquipmentRowActions(onMenuItemClick);
  const rowActions = [
    { label: 'Comandos GPRS', component: (props) => { return (<CommandGprs showStatus={true} isVisible={true} {...props} />) } },
    { label: 'Comandos Administrativos', component: (props) => { return (<CommandAdmin showStatus={true} isVisible={true} {...props} />) } },
    { label: 'Observações', component: (props) => { return (<ModalObs onSubmit={onModalObsSubmit} equipment={props} isVisible={true} {...props} />) } },
  ];

  const columns = [
    { label:'Equip.', value: (row) => row.shortImei, sort: 'shortImei'},
    { label:'Placa', value: (row) => row.vehicle, sort: 'vehicle'},
    { label:'Tipo', value: (row) => row.equipmentType && row.equipmentType.name, sort: 'equipmentType.name'},
    { label:'Empresa', value: (row) => row.currentCompany && row.currentCompany.name, sort: 'currentCompany.name' },
    { label:'Bat.', value: row => ( `${percentageFormatter( row.lastEvent && Number(row.lastEvent.internalBatteryPercentage / 100) || 0)}`), sort: 'lastEvent.internalBatteryPercentage', },
    { label:'Exp.', value: (row) => poffFormatter(row)},
    { label:'Transmissão', value: (row) => row.lastEvent && getPositionIndicator(row.lastEvent, true)},
    { label:'Sinal', value: (row) => row.lastEvent && row.lastEvent.gsmModelSignal &&  `${Math.round(parseInt(row.lastEvent.gsmModelSignal, 10)/31 * 100)}%`, sort: 'lastEvent.gsmModelSignal'},
    { label:'Data/hora', value: row => row.lastEvent && `${datetimeFormatter(row.lastEvent.timestamp)}` || '', sort: 'lastEvent.timestamp'},
    { label:'RF', value: (row) => isRfOn(row.lastEvent), },
    { label:'FPP.', value: (row) => row.lastEvent && minutesToTimeString(row.lastEvent.positionSendInterval), sort: 'lastEvent.positionSendInterval'},
    { label:'Local', value: (row) => row.lastEvent && row.lastEvent.local, sort: 'lastEvent.local'},
    { label:'Lat/Long', value: row => ( row.lastEvent && `${latlongFormatter(row.lastEvent.latitude, row.lastEvent.longitude)}` || ''), sort: '', },
    { label:'Status', component: (row) => (
      <Permission key={'Status'+ row._id} name='EditEquipmentStatus' ifNoPermission={
        <TableColumn>
          <StatusColumn edit={false} status={row.handlingStatus}/>
        </TableColumn>
      }>
        <TableMenu
          row={row}
          rowActions={rowStatusActions}
          icon={(<StatusColumn status={row.handlingStatus} />)}
        />
      </Permission>
    )},
    { label:'', component: (row, col) => (
      <Permission key={'Config' + row._id} names={['SendGPRSCommand', 'SendAdminCommand', 'EditEquipmentObservations']}>
        <TableMenu row={row} rowActions={rowActions}>
        </TableMenu>
      </Permission>
    )},
  ];

  const filterAsyncFields = [{
    name: 'companies',
    component: (args) => {
      return (
        <FilterAutoComplete {...args}
          menuContainerStyle={{
            marginTop: 5
          }}
          placeholder='Empresa'
          showPlaceholder={true}
          hideLabel={true}
          path='company?notPaginated=true'
          name='companies'
          getOptionLabel={(opt) => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={
            (args) => {
              const { opt, fields, setIsLoading, isLoading, filterHandler } = args;
              // reset equipments filter
              fields.equipments.current.resetField();
              setIsLoading({ ...isLoading, equipments: true });
              if (!opt) {
                filterHandler.removeFilter("currentCompany._id");
              } else {
                // update equipments available for filtering
                filterHandler.updateQueryFilter({
                  "currentCompany._id": { value: opt._id, toObjectId: false }
                });
              }
            }
          }
        />
      )
    },
  },
  {
    name: 'equipments',
    component: (args) => {
      return (
        <FilterAutoComplete {...args}
          menuContainerStyle={{
            marginTop: 5
          }}
          placeholder='Equipamentos'
          showPlaceholder={true}
          hideLabel={true}
          path={'equipment/list?fields=_id,imei&' + query.queryString.substr(1)}
          name='equipments'
          getOptionLabel={(opt) => opt.imei}
          loadingMessage="Carregando equipamentos..."
          noOptionsMessage="Nenhum equipamento encontrado."
          onChange={
            (args) => {
              const { opt, filterHandler } = args;
              if (!opt) {
                filterHandler.removeFilter("_id");
              } else {
                filterHandler.updateQueryFilter({
                  _id: opt._id
                });
              }
            }
          }
          whenIsLoading={({ fetchUrl, path: defaultPath, setFetchUrl, fields }) => {
            const companyField = fields.companies.current.value;
            if (companyField) {
              setFetchUrl(updateQueryFilter(fetchUrl, {
                "currentCompany._id": { value: companyField._id, toObjectId: false }
              }));
            } else {
              setFetchUrl(defaultPath);
            }
          }}
        />
      )
    },
  }
  ];



  return (
    <div>
      <SubBar breadcrumbs={breadcrumbs} items={[]} match={match}></SubBar>
      <div style={{ backgroundColor: situationInfo[situation].color, height: 5, width: '100%' }}></div>
      <div className={classes.root}>
        {/* <div className={classes.header}> */}
        {/* <img src={situationInfo[situation].icon} className={classes.cardIcon}></img> */}
        {/* <Typography className={classes.headerText}>{situationInfo[situation].label}</Typography> */}
        {/* </div> */}
        <Filter
          searchPlaceHolder="Buscar"
          query={query}
          setQuery={setQuery}
          containerStyle={{ paddingLeft: 0 }}
          asyncFields={filterAsyncFields}
          inputProps={{
            startAdornment: <InputAdornment position="start" className={classes.searchIcon}><Search /></InputAdornment>,
          }}
          showPlaceholder={true}
          hideLabel={true}
          genericSearchFields={[
            { label: 'Imei', name: 'imei', },
            { label: 'Código', name: 'code', },
            { label: 'Descrição', name: 'description', },
            { label: 'Tipo', name: 'equipmentType.name', },
            { label: 'Nome da Empresa', name: 'currentCompany.name', },
            { label: 'Cidade', name: 'currentCompany.addressCity.nome', },
            { label: 'Estado', name: 'currentCompany.addressState.nome', },
            { label: 'Veículo', name: 'vehicle', },
          ]}
        />
        <Paper elevation={0} className={classes.paper}>
          <SimpleTable
            columns={columns}
            data={data}
            query={query}
            setQuery={setQuery}
            onRowClick={(row, col) => { history.push(`/detalhes-equipamento/${row._id}`, { from: location }) }}
          />
        </Paper>
      </div>
    </div>
  )
};

const styles = theme => ({
  root: {
    // paddingTop: theme.main.padding.top,
    // paddingBottom: theme.main.padding.bottom,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gray.gray_4,
    fontSize: 22,
    lineHeight: 'normal',
  },
  paper: {
    display: 'flex',
    borderRadius: theme.border.radius,
  },
  button: {
    textTransform: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: theme.main.padding.bottom,
  },
  headerText: {
    marginLeft: 16,
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_1,
  },
  cardIcon: {
    width: 34,
    height: 34,
  },
  searchIcon: {
    color: theme.palette.gray.gray_5,
  }
});

export default withRouter(withStyles(styles)(EquipmentList));
