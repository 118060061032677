import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import AlertIcon from '../../assets/img/alert.png';
import Card from './Card';

const styles = theme => ({
  dashboardCardsContainer: {
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
});

function CardContainer(props) {
  const { classes, refresh } = props;
  const { cardContainerRef } = props;

  const cardWidth = props.cardWidth || 224;
  const cardHeight = props.cardHeight || 296;

  const [horizontalMargin, setHorizontalMargin] = useState(1);
  const [verticalMargin] = useState(25);
  const [cardsPerRow, setCardsPerRow] = useState(5);

  const [cards] = useState(props.cards);

  const CARDS_NUMBER = cards.length;
  const [totalHeight, setTotalHeight] = useState({ height: cardHeight });

  useEffect(() => {
    const handleResize = () => {
      const { width } = cardContainerRef.current.getBoundingClientRect();

      // calculate number of cards that fit in one row
      let cardsNumberThatFit = parseInt(width / cardWidth, 10);
      if (cardsNumberThatFit > CARDS_NUMBER) {
        cardsNumberThatFit = CARDS_NUMBER;
      }
      setCardsPerRow(cardsNumberThatFit);

      // calculate margin between cards
      const margin = (width - cardsNumberThatFit * cardWidth) / (cardsNumberThatFit - 1);
      setHorizontalMargin(margin);

      // calculate number of rows
      const rows = Math.ceil(CARDS_NUMBER / cardsNumberThatFit);

      // Since we are use position absolute the height must be set manually
      const height = cardHeight * rows + (rows - 1) * verticalMargin;
      setTotalHeight({ height });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [cardContainerRef.current, cardWidth, cardHeight]);

  return (
    <div className={classes.dashboardCardsContainer} style={totalHeight}>
      {cards.map((item, index) => {
        const row = parseInt(index / cardsPerRow, 10);
        const column = parseInt(index % cardsPerRow, 10);
        return (
          <Card
            refresh={refresh}
            cardWidth={cardWidth}
            cardHeight={cardHeight}
            cardStyle={{
              marginTop: 10,
              position: 'absolute',
              top: verticalMargin * row + cardHeight * row,
              left: column * horizontalMargin + cardWidth * column,
            }}
            {...item}
            // linkTo={item.linkTo}
            key={item._id}
          />
        );
      })}
    </div>
  );
}

export default withStyles(styles)(CardContainer);
