import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import { AutocompleteField } from 'components/fields';
import { useFetch, useAuthenticated } from 'hooks/fetch';
import {
  InputAdornment,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';



const inputHeight = 38;

const styles = theme => ({
  inputContainer: {
    marginRight: 17,
    borderRadius: 16,
    backgroundColor: '#eef0f5',
    minHeight: inputHeight,
    width: '100%',
    paddingRight: 10,
  },
  placeholder: {
    fontWeight: 600,
    color: theme.palette.gray.gray_5,
    opacity: 1,
    fontSize: 14,
    paddingLeft: 50,
  },
  input: {
    paddingLeft: 20,
    height: inputHeight,
  },
  inputRoot: {
    marginTop: '0px !important',
  },
  inputLabel: {
    paddingLeft: 20,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.gray.gray_5,
    marginTop: -12,
  },
  shrink: {
    fontSize: 17,
    marginTop: -17,
  },
  searchIcon: {
    color: theme.palette.gray.gray_5,
  }
});

const FilterAutoComplete = (props) => {
  const {
    classes,
    label,
    fetchUrl,
    getOptionLabel,
    noOptionsMessage,
    loadingMessage,
    onChange,
    placeholder,
    showPlaceholder,
    hideLabel,
    containerStyle,
  } = props;

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Load data
  const { data } = useAuthenticated(useFetch, fetchUrl, {
      afterFetch: () => {setIsLoading(false)}
   });

  // Sort  data
  useEffect(() => {
    // const c = data && data.data.sort((a, b) => a.name > b.name ? 1 : -1) || [];
    if (Array.isArray(data)) {
      setOptions(data);
    } else {
      setOptions((data && data.data) || []);
    }
  }, [data]);

  return (
    <div style={{...containerStyle}}>
    <AutocompleteField
      label={!hideLabel && label}
      placeholder={(showPlaceholder && placeholder) || ''}
      options={options}
      isLoading={isLoading}
      getOptionLabel={opt => getOptionLabel(opt)}
      getOptionValue={opt => opt._id}
      onChange={(opt) => {
        onChange({ opt });
      }}
      noOptionsMessage={() => noOptionsMessage}
      loadingMessage={() => loadingMessage}
      isClearable={true}
      menuContainerStyle={{
        ...props.menuContainerStyle,
        zIndex: 999,
      }}
      disableUnderline={true}
      fullWidth={false}
      classes={{ placeholder: classes.placeholder }}
      InputProps={{
        ...props.inputProps,
        disableUnderline: true,
        className: classes.input,
        classes: {
          root: classes.inputRoot,
          // input: classes.input,
        },

        startAdornment: <InputAdornment position="start" className={classes.searchIcon}><Search /></InputAdornment>,
      }}
      InputLabelProps={{
        className: classes.inputLabel,
        classes: {
          shrink: classes.shrink,
        },
      }}
      InputContainerClass={classes.inputContainer}
    />
    </div>
  );
};

export default withRouter(withStyles(styles)(FilterAutoComplete));

FilterAutoComplete.defaultProps = {
  containerStyle: {},
}
