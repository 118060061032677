import React from "react";
import { IconButton } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    borderRadius: 8,
    backgroundColor: theme.palette.gray.gray_8,
    marginLeft: 5,
    "&:disabled": {
      opacity: "0.25",
      backgroundColor: theme.palette.gray.gray_8
    }
  },
  iconNoHover: {
    borderRadius: 8,
    backgroundColor: theme.palette.gray.gray_8,
    marginLeft: 5,
    "&:disabled": {
      opacity: "0.25",
      backgroundColor: theme.palette.gray.gray_8
    },
    "&:hover": {
      backgroundColor: theme.palette.gray.gray_8
    }
  }
}));

const VariantFontColor = new Proxy(
  {
    dark: "#FFFF",
    light: "#0000008A"
  },
  {
    get(target, name) {
      return name in target ? target[name] : target["dark"];
    }
  }
);

function TableHeaderAction(props) {
  const classes = useStyles();
  const { Icon, IconProps, variant, noHover, ...rest } = props;

  const { style = {}, ...RestIconProps } = IconProps;
  return (
    <IconButton
      classes={{ root: noHover ? classes.iconNoHover : classes.icon }}
      {...rest}
    >
      <Icon
        style={{ color: VariantFontColor[variant], ...style }}
        {...RestIconProps}
      />
    </IconButton>
  );
}

TableHeaderAction.defaultProps = {
  Icon: HelpOutline,
  IconProps: {},
  variant: "dark",
  noHover: false
};

export default TableHeaderAction;
