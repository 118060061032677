import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
import { post } from 'services/fetch';
import Modal from 'components/modals/Modal';
import Button from "@material-ui/core/Button";

import * as moment from 'moment';

const useStyles = makeStyles(theme => ({
  textarea: {
    borderRadius: 22,
    border: 'solid 1px #808492',
    resize: 'none',
    outline: 'none',
    padding: 22,
    fontFamily: 'Raleway',
    fontSize: 15,
    width: '100%',
    fontWeight: '400',
  },
  lastObs: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  obsText: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
    wordWrap: 'break-word',
  },
  obsDate: {
    fontSize: 15,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
}));

const ModalEseye = (props) => {
  const { isVisible, setIsVisible, equipment } = props;
  const classes = useStyles();
  const [showModal, setShowModal] = useState(isVisible);
  const [ response , setResponse] = useState("");

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleSubmit = async () => {
    const response = await post('api/eseye', {});
    setResponse(response);
  }
  const onClose = () => {
    if(setIsVisible) {
      setIsVisible(false);
    }
  }
  return (
    <Modal
      handleSubmit={handleSubmit}
      isVisible={showModal} setIsVisible={setShowModal} title="Testes eseye" buttonLabel="Get location"
      onClose={onClose}
      hideButton={true}
    >
      <div style={{paddingTop: 34}}>
        <Typography className={classes.lastObs}>{JSON.stringify(response)}</Typography>
      </div>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="small"
        onClick={handleSubmit}
      >
        pega localização
      </Button>
    </Modal>
  )
}

export default ModalEseye;
