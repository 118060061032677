import React, { useState } from 'react';
import {
  TableCell, IconButton, Menu, MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Permission from 'components/permissions/Permission';

export function TableColumn(props) {
  return (
    <TableCell
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >{props.children}</TableCell>
  );
}

export default function TableMenu(props) {
  const { row, rowActions, icon } = props;
  const [showActionMenu, setShowActionMenu] = useState({});
  const [currentAction, setCurrentAction] = useState();

  const handleActionMenuClick = (event, menuId) => {
    event.stopPropagation();
    event.preventDefault();
    setShowActionMenu({
      [menuId]: event.currentTarget,
    });
    setCurrentAction();
  };

  const handleMenuItemClick = async (event, action) => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentAction(action);
    setShowActionMenu({});
    if(action.onClick) {
      action.onClick(row);
    }
  };

  const handleMenuClose = () => {
    setShowActionMenu({});
  };

  return (
    <TableCell
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      {currentAction && currentAction.component(row)}
      <IconButton onClick={e => handleActionMenuClick(e, row._id)}>
        {icon || <MoreVertIcon />}
      </IconButton>
      <Menu
        open={Boolean(showActionMenu[row._id])}
        anchorEl={showActionMenu[row._id]}
        onClose={handleMenuClose}
      >
        {rowActions.map(action => (
          <Permission
            key={row._id + Math.random()}
            name={action.permission}>
            <MenuItem
              onClick={e => handleMenuItemClick(e, action)}
              onClose={handleMenuClose}
            >
              {action.labelComponent && action.labelComponent || ''}
              {action.label}
            </MenuItem>
          </Permission>
        ))}
      </Menu>
    </TableCell>
  );
}
