import { useState, useEffect, useRef } from 'react';

export function useEffectSkipFirst(fn, watchingStates) {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      fn();
    } else {
      ref.current = true;
    }
  }, [...watchingStates]);
}

export function usePaginatedData() {
  const [data, setData] = useState({
    data: [],
    count: 0,
    page: 1,
    totalItems: 0,
  });
  return [data, setData];
}

export function useDisclosure(defaultIsOpen) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const onToggle = () => setIsOpen(!isOpen);
  return { isOpen, onOpen, onClose, onToggle };
};

export function useDelayedEffect(callback, timeout, dependencies) {
  const _timeout = useRef(null);
  useEffect(() => {
    _timeout.current = setTimeout(() => {
      callback();
    }, timeout);
    return () => clearTimeout(_timeout.current);
  }, dependencies);
}
