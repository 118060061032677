import React from 'react';
import { withStyles } from '@material-ui/core';

import SubRoutes from "components/SubRoutes";
import AccessProfileList  from "pages/Admin/AccessProfile/List";
import EditAccessProfile  from "pages/Admin/AccessProfile/Edit";
import NewAccessProfile from 'pages/Admin/AccessProfile/New';

function AccessProfile (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Perfis de Acesso",
      path: '/novo',
      component: NewAccessProfile,
    },
    {
      name: "Perfis de Acesso",
      path: '/:id',
      component: EditAccessProfile,
    },
    {
      name: "Perfis de Acesso",
      path: '',
      component: AccessProfileList
    },
  ]

  return (
    <div>
      <SubRoutes
        setBreadcrumbs={setBreadcrumbs}
        defaultPage={0}
        pages={pages}
        match={match}
      />
    </div>
  )
}

const styles = theme => ({
  /** */
});

export default withStyles(styles)(AccessProfile);
