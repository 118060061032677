import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF88',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 700,
  },
}));

export default function CircularLoading(props)  {
  const { isLoading } = props;
  const classes = useStyles();

  return (
      <>
      {isLoading &&
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      }
      </>
  )

}
