import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'components/modals/Modal';

const ModalPopup = (props) => {
  const { isVisible, setIsVisible, title } = props;
  const [ showModal, setShowModal ] = useState(isVisible);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const onClose = () => {
    if(setIsVisible) {
      setIsVisible(false);
    }
  }

  return (
    <Modal
      isVisible={showModal} setIsVisible={setShowModal} title={title}
      hideButton={true}
      onClose={onClose}
      minWidth={500}
    >
      {props.children}
    </Modal>
  )
}

export default ModalPopup;
