import React, { useState,useEffect } from "react";
import { withStyles, Typography } from "@material-ui/core";

import { useAuthenticated, useFetch } from "hooks/fetch";
import { fetchAuthenticated } from 'services/fetch';
import { get, put } from "services/fetch";
import { emitEvent } from "utils/events";
import AccessProfileForm from "components/forms/AccessProfileForm";
import ModalConfirm from 'components/modals/ModalConfirm';

function EditAccessProfile (props) {
  const { classes, match, history, setBreadcrumbs } = props;
  const [ reload, setReload ] = useState(false);
  const [ accessProfile, setAccessProfile ] = useState({});
  const [ openModal, setOpenModal ] = useState(false);
  const fetchPath = 'authorization';
  const deletePath = `authorization/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      {path: `/administracao/perfil-de-acesso`, label: `Perfis de Acesso`},
      {path: ``, label: `${accessProfile && accessProfile.name || ''}`}
    ]);
    return () => {
      setBreadcrumbs([]);
    }
  }, [accessProfile]);

  // Load accessProfile info
  let { data } = useAuthenticated(useFetch, `${fetchPath}/${match.params.id}`, {
    beforeFetch: () => { emitEvent('showGlobalLinearProgress') },
    afterFetch:  () => { emitEvent('hideGlobalLinearProgress'); }
  });
  useEffect(() => setAccessProfile(data), [data]);

  // Reload accessProfile info if necessary
  useEffect(() => {
    if (reload) {
      const fn = async () => {
        emitEvent('showGlobalLinearProgress');
        setAccessProfile(await get(`${fetchPath}/${match.params.id}`));
        emitEvent('hideGlobalLinearProgress');
        setReload(false);
      };
      fn();
    }
  }, [reload, match.params.id])

  // Handle form submit
  const handleSubmit = async (data) => {
    try {
      const response = await put(`${fetchPath}/${match.params.id}`, data);
      emitEvent('showSnack', {message: 'Perfil de acesso atualizado com sucesso', type: 'success'});
    } catch (err) {
      throw err;
    }
  }


  const handleRemoveConfirm = async (event) => {
    let redirect = false;
    try {
      console.log(deletePath);
      const response = await fetchAuthenticated('delete', deletePath)
      if (response.status >= 400) {
        emitEvent('showSnack', {message: 'Não foi possível remover o perfil', type: 'error'});
      } else {
        emitEvent('showSnack', {message: 'Perfil removido com sucesso', type: 'success'});
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setOpenModal(false);
      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/administracao/perfil-de-acesso');
      }
    }
  }

  const handleRemove = async (event) => {
    setOpenModal(true);
  }

  const handleCancel = () => {
    history.push('/administracao/perfil-de-acesso');
  }

  return (
    <div>
      <AccessProfileForm
        title='Detalhes do Perfil de Acesso'
        initialData={accessProfile}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
        reload={setReload}
      />
      <ModalConfirm
        isOpen={openModal}
        setIsOpen={setOpenModal}
        onConfirm={handleRemoveConfirm}
        title="Confirmar exclusão do perfil"
      >
        <div>
          <Typography className={classes.text}>
            Tem certeza que deseja excluir o perfil?
          </Typography>
          <Typography className={classes.text}>
            Ao realizar está ação o perfil não terá mais acesso ao sistema
          </Typography>
        </div>
      </ModalConfirm>
    </div>
  );
}

const styles = theme => ({
  text: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
    maxWidth: 800,
  }
/** */
});

export default withStyles(styles)(EditAccessProfile);
