import React, {useLayoutEffect} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { filterPage } from 'services/permissions';

export default function SubRoutes(props) {
  let { pages, defaultPage } = props;
  const { match } = props.match ? props : { match: { path: '' } };
  const { setBreadcrumbs } = props;

  pages = pages.filter(item => filterPage(item))

  return (
    <Switch>
      {pages.map((page) => {
        const { component: Component } = page;
        return (
          <Route
            key={page.path}
            path={`${match.path}${page.path}`}
            render={props => <Component {...page} {...props} setBreadcrumbs={setBreadcrumbs} />}
          />
        );
      })}
      <Route
        exact
        path={`${match.path}` || '/'}
        component={() => <Redirect to={`${match.path}${pages[defaultPage || 0].path}`} />}
      />
      <Route component={() => <Redirect to="/error" />} />
    </Switch>
  );
}
