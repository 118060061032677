import React, { useEffect } from 'react';

import { fetchAuthenticated, get } from 'services/fetch';
import { emitEvent } from 'utils/events';

import EquipmentPackingForm from 'components/forms/EquipmentPackingForm';
import { logAction } from 'utils/logs';

export default function NewEquipmentPacking(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/embalagens', label: 'Embalagens' },
      { path: '', label: 'Nova Embalagem' }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs])

  // Handle form submit
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('post', 'equipment-packing', data);

    if (!response.ok) {
      throw new Error('');
    } else {
      const data = await response.json();
      if (data) {
        for (const p of data) {
          logAction(`Criação da embalagem ${p.name}`);
        }
      }
      emitEvent('showSnack', { message: 'Embalagem criada com sucesso', type: 'success' });
      history.push(`/cadastros/embalagens`);
    }
  }

  // Handle cancel click
  const handleCancel = async () => {
    history.push('/cadastros/embalagens');
  }


  return (
    <div>
      <EquipmentPackingForm
        title='Nova Embalagem'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
