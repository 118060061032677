import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 16,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'transparent'
    },
  }
}));


export default function ButtonLink(props) {
  const { onClick, title } = props;
  const classes = useStyles();

  return (
    <Button
      variant='text'
      color='primary'
      disableRipple
      className={classes.button}
      onClick={onClick}
      >
      {title}
    </Button>
  );
}
ButtonLink.defaultProps = {
  title: '',
}
