import React, { useState, useEffect, useRef } from "react";

import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import { subscribeEvent, unsubscribeEvent } from '../utils/events';

function GlobalLinearProgress(props) {
  const { classes } = props;
  const [showProgress, setProgress] = useState(false);
  const [completed, setCompleted] = useState(0);
  const [delay, setDelay] = useState(500);
  const [isFinished, setFinished] = useState(false);

  useEffect(() => {

    const showLinearProgress = (e) => {
      setCompleted(0);
      setDelay(500);
      setProgress(true);
      setFinished(false);
    }
    const hideLinearProgress = (e) => {
      setFinished(true);
    }

    subscribeEvent('showGlobalLinearProgress', showLinearProgress);
    subscribeEvent('hideGlobalLinearProgress', hideLinearProgress);

    return () => {
      unsubscribeEvent('showGlobalLinearProgress', showLinearProgress);
      unsubscribeEvent('hideGlobalLinearProgress', hideLinearProgress);
    }
  });

  useEffect(() => {
    if (completed == 100) {
      setTimeout(() => {
        setProgress(false);
      }, 500)
    }
  }, [completed]);

  useInterval(() => {
    if (completed <= 99) {
      const progress = (100 - completed) * 0.33;
      const diff = Math.abs(Math.max(Math.random(), 0.1) * progress);
      // const diff = Math.abs(Math.max(Math.random(), 0.1) * progress);
      if (isFinished) {
        setFinished(true);
        setCompleted(100)
        setDelay(null);
      } else {
        setCompleted(completed + progress);
      }
    }
  }, delay)

  return (
    <LinearProgress
      hidden={!showProgress}
      color="primary"
      variant="determinate"
      value={completed}
      className={classes.linearProgress}
    />
  )
}

const styles = theme => ({
  linearProgress: {
    position: "fixed",
    width: "100%",
    height: "2px",
    backgroundColor: "transparent",
    zIndex: 1200,
  }

});

export default withStyles(styles)(GlobalLinearProgress);


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
