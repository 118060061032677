import React, { useEffect } from 'react';

import { fetchAuthenticated, get } from 'services/fetch';
import { emitEvent } from 'utils/events';

import EquipmentForm from 'components/forms/EquipmentForm';
import { logAction } from 'utils/logs';

export default function NewEquipment(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/equipamentos', label: 'Equipamentos' },
      { path: '', label: 'Novo Equipamento' }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs])

  // Handle form submit
  const handleSubmit = async (data) => {
    const isImeiRegistered = await fetchAuthenticated(get, `equipment/is-imei-registered?imei=${data.imei}`);
    if (isImeiRegistered) {
      emitEvent('showSnack', { message: `Já existe um equipamento com o Imei: ${data.imei}`, type: 'error' });
      return;
    }

    const response = await fetchAuthenticated('post', 'equipment', data);

    if (!response.ok) {
      throw new Error('');
    } else {
      const data = await response.json();
      emitEvent('showSnack', { message: 'Equipamento criada com sucesso', type: 'success' });
      logAction(`Criação do equipamento ${data.shortImei}`)
      history.push(`/cadastros/equipamentos/${data._id}`);
    }
  }

  // Handle cancel click
  const handleCancel = async () => {
    history.push('/cadastros/equipamentos');
  }



  return (
    <div>
      <EquipmentForm
        title='Novo Equipamento'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
