import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import { AutocompleteField } from 'components/fields';
import { useFetch, useAuthenticated } from 'hooks/fetch';
import {
  InputAdornment,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';

import {
  OpenStreetMapProvider,
  GoogleProvider,
} from 'leaflet-geosearch';


const inputHeight = 38;

const styles = theme => ({
  root: {
    position: 'absolute',
    zIndex: 999,
    width: 355,
    left: 30,
    top: 28,
  },
  inputContainer: {
    marginRight: 17,
    borderRadius: 16,
    backgroundColor: 'white',
    minHeight: inputHeight,
    width: '100%',
    paddingRight: 10,
  },
  placeholder: {
    fontWeight: 600,
    color: theme.palette.gray.gray_5,
    opacity: 1,
    fontSize: 14,
    paddingLeft: 50,
  },
  input: {
    paddingLeft: 20,
    height: inputHeight,
  },
  inputRoot: {
    marginTop: '0px !important',
  },
  inputLabel: {
    paddingLeft: 20,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.gray.gray_5,
    marginTop: -12,
  },
  shrink: {
    fontSize: 17,
    marginTop: -17,
  },
  searchIcon: {
    color: theme.palette.gray.gray_5,
  }
});

const AutoCompleteMap = (props) => {
  const {
    classes,
    label,
    noOptionsMessage,
    loadingMessage,
    placeholder,
    showPlaceholder,
    hideLabel,
    containerStyle,
  } = props;

  const searchTimeoutRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const provider = new GoogleProvider({
    params: {
      key: 'AIzaSyAhFSkPTcRnoysmyr_xhMpW4t2Ixhn3yEw'
    }
  });
  // const provider = new OpenStreetMapProvider();

  const onTextChange = (value, callb) => {
    setIsLoading(true);

    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(() => {
      provider.search({ query: value }).then((results) => {
        console.log(results);
        callb(results);
        setIsLoading(false);
      });
    }, 500)
  }

  return (
    <div className={classes.root} style={{...containerStyle}}>
      <AutocompleteField
        label={!hideLabel && label}
        placeholder={(showPlaceholder && placeholder) || ''}
        async
        loadOptions={(value, callback) => {
          onTextChange(value, callback);
        }}
        isLoading={isLoading}
        getOptionLabel={opt => opt.label}
        getOptionValue={opt => opt.x}
        onChange={(opt) => {
          props.onChange(opt);
        }}
        noOptionsMessage={() => noOptionsMessage}
        loadingMessage={() => loadingMessage}
        isClearable={true}
        menuContainerStyle={{
          marginTop: 5,
          ...props.menuContainerStyle,
          zIndex: 999,
        }}
        disableUnderline={true}
        fullWidth={false}
        classes={{ placeholder: classes.placeholder }}
        InputProps={{
          ...props.inputProps,
          disableUnderline: true,
          className: classes.input,
          classes: {
            root: classes.inputRoot,
            // input: classes.input,
          },
          startAdornment: <InputAdornment position="start" className={classes.searchIcon}><Search /></InputAdornment>,
        }}
        InputLabelProps={{
          className: classes.inputLabel,
          classes: {
            shrink: classes.shrink,
          },
        }}
        InputContainerClass={classes.inputContainer}
      />
    </div>
  );
};

export default withRouter(withStyles(styles)(AutoCompleteMap));

AutoCompleteMap.defaultProps = {
  containerStyle: {
  },
}

