import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import { TextField as TF } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
import { Rule, validate } from "../../utils/formValidator";
import MobileMaskInput from 'components/inputs/mobile.input';
import validator from 'validator';
import { TimeMaskInput } from 'components/inputs/masked.input';
import { PhoneInput } from 'components/fields/Mobile.field';
import ErrorMessage from 'components/fields/ErrorMessage';

const TextField = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const { defaultValue = '', ...rest} = props;
  const [value, setValue] = useState(defaultValue);

  const [validations, setValidations] = useState({
    rules: [
      Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const onChange = e => { setValue(e.target.value) }
  const onBlur = () => { validateField() }

  const validateField = () => {
    let currentRules = (rest.required || value) ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(value, currentRules)
    })
  }

  const setFieldValue = (value) => {
    setValue(value);
  }

  const resetField = () => {
    setValue('')
    setValidations({
      ...validations,
      alreadyValidated: false,
      isInvalid: false,
      message: ''
    })
  }

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: value, // validator.trim(value),
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue,
    resetField,
  }));

  return (
    <TF
      {...rest}
      ref={inputRef}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={validations.isInvalid}
      helperText={validations.isInvalid && validations.message || ''}
    />
  )
});

export default TextField

export const TextFieldModalStyle = ({ label, name, defaultValue, onChange, containerStyle, timeMask, mobileMask, defaultStyle }) => {

  const useStyles = makeStyles(theme => ({
    textField: {
      minHeight: 'unset',
      marginRight: 10,
      width: 200,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%'
      }
    },
    input: {
      borderBottom: theme.input.borderStyle.borderBottom,
      paddingBottom: !defaultStyle && 8,
    },
    label: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_4,
    }
  }));

  const classes = useStyles();

  const InputProps = {
    classes: {
      root: classes.input,
    },
    disableUnderline: true,
  }

  if (timeMask) {
    InputProps.inputComponent = TimeMaskInput;
  }

  if(mobileMask) {
    InputProps.inputComponent = PhoneInput;
  }

  const [value, setValue] = useState(defaultValue);

  const onTextChange = (v) => {
    setValue(v);
    onChange(v);
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...containerStyle }}>
      {!defaultStyle && <Typography className={classes.label}>{label}</Typography> }
      <TF
        className={classes.textField}
        name={name}
        value={value}
        onChange={(e) => onTextChange(e.target.value)}
        InputProps={InputProps}
        label={defaultStyle && label || undefined}
      />
      <ErrorMessage name={name}/>
    </div>
  )
}

TextFieldModalStyle.defaultProps = {
  containerStyle: {},
  defaultStyle: false,
}
