import React from 'react';
import BaseList from 'components/lists/BaseList';
import { useAccessLog } from 'hooks/logs';

import { conformToMask } from 'react-text-mask';

const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

function ListCompany(props) {
  useAccessLog('Acesso ao cadastro de empresas');
  const { match } = props;
  return (
    <BaseList
      columns={[
        { value: row => row.name, label: 'Nome', sort: 'name' },
        { value: row => row.type, label: 'Tipo', sort: 'type' },
        { value: row => row.personType, label: 'Pessoa', sort: 'personType' },
        {
          value: row => (row.cnpj ? conformToMask(row.cnpj, cnpjMask).conformedValue : ''),
          label: 'CNPJ',
          sort: 'cnpj'
        },
        {
          value: row => (row.cpf ? conformToMask(row.cpf, cpfMask).conformedValue : ''),
          label: 'CPF',
          sort: 'cpf',
        },
        { value: row => row.email, label: 'Email', sort: 'emai' },
      ]}
      genericSearchFields={[
        { label: 'CPF', name: 'cpf' },
        { label: 'CNPJ', name: 'cnpj' },
        { label: 'Nome', name: 'name' },
        { label: 'Email', name: 'email' },
        { label: 'Tipo de Empresa', name: 'type' },
        { label: 'Tipo de Pessoa', name: 'personType' },
      ]}
      basePath="company"
      title="Lista de Empresas"
      addButtonText="Cadastrar Empresa"
      addUrl={`${match.path}/nova`}
    />
  );
}

export default ListCompany;
