export function subscribeEvent (event, callback) {
  window.addEventListener(event, callback);
};

export function unsubscribeEvent (event, listener) {
  window.removeEventListener(event, listener);
}

export function emitEvent (event, data) {
  window.dispatchEvent(new CustomEvent(event, {detail: data}));
};
