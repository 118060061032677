import React from 'react';
import { NavLink } from "react-router-dom";

// Material-ui imports
import { withStyles } from '@material-ui/core';


const Crumb = (props) => {
  const { classes, active, path, label } = props;

  return (
    <span>
    {!active ?
      <span>
      <NavLink
        to={path}
        className={classes.crumb}
        >{label}</NavLink>
        <span className={classes.divider}>/</span>
      </span>
    :
      <span className={classes.crumb} style={{color: '#548cc1'}}>{label}</span>
    }
  </span>
  );
}

const Breadcrumbs = (props) => {
  const { classes, crumbs } = props
  return (
    <div>
      {crumbs.map((crumb, index, arr) => {
      return (
        <Crumb
          key={index}
          path={crumb.path}
          label={crumb.label}
          active={index === arr.length - 1}
          classes={classes} />
      )})}
    </div>
  )
}

const breadCrumbsStyles = theme => ({
  crumb: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.palette.gray.gray_7,
    textDecoration: 'none',
  },
  divider: {
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.gray.gray_7,
  }
});

export default withStyles(breadCrumbsStyles)(Breadcrumbs);
