import React from 'react';
import BaseList from 'components/lists/BaseList';

function ListAccessProfile (props) {
  const { match } = props;
  return (
    <BaseList
      columns={[
        {value: (row) => row.name, label: 'Nome', sort: 'name'},
        {value: (row) => row._id, label: 'ID', sort: '_id'},
      ]}
      genericSearchFields={[
        { label: 'Nome', name: 'name', },
        { label: 'ID', name: '_id', },
      ]}
      basePath='authorization'
      title='Lista de perfis de acesso'
      addButtonText='Cadastrar perfil de acesso'
      addUrl={`${match.path}/novo`}
    />
  );
}

export default ListAccessProfile;
