import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'components/modals/Modal';
import { SelectEquipments } from 'components/forms/FenceForm';
import { fetchAuthenticated, putWithErrorHandler } from 'services/fetch';

const useStyles = makeStyles(theme => ({
}));

const ModalFenceAddEquipment = (props) => {
  const { isVisible, onSubmit, equipments, setIsVisible, setFence, fence } = props;
  const [ isOpen, setIsOpen ] = useState(isVisible);
  const [ _equipments, _setEquipments] = useState([]);
  const [ submit, setSubmit] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if(fence && fence.equipments) {
      _setEquipments(fence.equipments.map(equip => { return {...equip, checked: true}}));
    }
  }, [fence]);

  const _onSubmit = async (v) => {
    setSubmit(true);
  }

  useEffect(() => {
    if(submit) {
      const _submit = async () => {
        const onlyCheckedEquipments = _equipments.filter((equip) => equip.checked);
        const logMessage = 'Editar equipamentos em cercas.';
        const successMessage = "Equipamentos atualizados.";
        const errorMessage = "Erro ao tentar adicionar os equipamentos."
        const response = await putWithErrorHandler('fence/'+fence._id, {
          equipments: onlyCheckedEquipments.map(equip => equip._id),
        }, { logMessage, successMessage, errorMessage } );
        setFence(response);
        setSubmit(false);
        setIsOpen(false);
        setIsVisible(false);
      }
      _submit();
    }
  }, [_equipments, submit]);

  const onClose = () => {
    setIsOpen(false);
    setIsVisible(false);
  }

  return (
    <Modal
      handleSubmit={_onSubmit}
      isVisible={isOpen}
      setIsVisible={setIsOpen} title="Adicionar equipamento" buttonLabel="Salvar"
      onClose={onClose}
    >
      <SelectEquipments
        fence={fence}
        equipments={_equipments}
        setEquipments={_setEquipments}
        checkAll={true}
        hideTitle
        containerStyle={{
          padding: 0,
        }}
      />
    </Modal>
  )
}

export default ModalFenceAddEquipment;
