import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/styles';
import { Typography, CircularProgress, Zoom, Grow, Slide, Fade } from "@material-ui/core";
import { put, get, fetchAuthenticated } from 'services/fetch';

import ReactSVG from 'react-svg'


const useStyles = makeStyles({
  card: {
    borderRadius: 22,
    minWidth: props => props.cardWidth,
    maxWidth: props => props.cardWidth,
    cursor: 'pointer',
    height: props => props.cardHeight,
    backgroundColor: 'white',
    boxShadow: "0 6px 23px 2px rgba(111, 111, 111, 0.1)",
  },
  cardInfoContainer: {
    height: props => props.cardHeight - 27,
    display: 'flex',
    flexDirection: 'column',
  },
});

const styles = theme => ({
  cardIcon: {
    width: 43,
    height: 43,
    marginLeft: 18,
    marginRight: 35,
    marginTop: 35,
  },
  cardQuantity: {
    fontSize: 42,
    fontWeight: 'bold',
    marginLeft: 18,
    color: theme.palette.gray.gray_4
  },
  cardLabel: {
    fontSize: 18,
    fontWeight: 'normal',
    marginLeft: 18,
    marginBottom: 23,
    color: theme.palette.gray.gray_4
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    maxHeight: 63,
  },
});

function Card(props) {
  const { classes, icon, label, linkTo, fetch, color, refresh } = props;
  const [ isLoading, setIsLoading ] = useState(true);
  const [ quantity, setQuantity] = useState(0);

  const cardStyle = props.cardStyle || {};
  const hookClasses = useStyles({
    cardWidth: props.cardWidth,
    cardHeight: props.cardHeight,
  });

  const onClick = () => {
    if(linkTo){
      props.history.push(linkTo);
    }
  }
  useEffect(() => {
    // get current company
    const _fetch = async () => {
      const _data = await fetchAuthenticated(get, fetch);
      setQuantity(_data.totalItems);
      setIsLoading(false);
    }
    _fetch();
  }, [refresh]);

  return (
    <div onClick={onClick} className={hookClasses.card} style={{...cardStyle}}>
      <div className={hookClasses.cardInfoContainer}>
        <ReactSVG
          className={classes.cardIcon}
          src={icon}
          onClick={() => {
            console.log('wrapper onClick')
          }}
        />
        {isLoading && (
          <div className={classes.loading}>
            <CircularProgress style={{width: 35, height: 35, color: '#808492'}}/>
          </div>
        )}
        {!isLoading && (
          <Grow in={true} style={{transformOrigin: '0 0 0'}}>
            <Typography className={classes.cardQuantity}>{quantity}</Typography>
          </Grow>
        )}
        <Typography className={classes.cardLabel}>{label}</Typography>
      </div>
      <div style={{
        backgroundColor: color,
        width: "100%",
        height: 27,
        borderBottomLeftRadius: 22,
        borderBottomRightRadius: 22,
      }}/>
    </div>
  )
}

export default withRouter(withStyles(styles)(Card));
