import React from 'react';
import NavBar from '../components/NavBar';
import Dashboard from './Dashboard/Dashboard';
import Mapa from './Mapa';
import Cercas from './Fences';
import Records from 'pages/Records';
import Tools from 'pages/Tools';
import Admin from './Admin';
import Relatorios from './Relatorios';
import EquipmentDetail from './EquipmentDetail';
import ProfileEdit from './ProfileEdit';
import CompanyEdit from './Records/Companies/Edit';
import DashboardEquipmentList from './Dashboard/DashboardEquipmentList';
import SubRoutes from '../components/SubRoutes';

export const pages = [
  {
    name: 'Equipamentos',
    path: '/dashboard/:situation',
    component: DashboardEquipmentList,
    hidden: true,
  },
  { name: 'Dashboard', path: '/dashboard', component: Dashboard },
  { name: 'Mapa', path: '/mapa', component: Mapa, permission: 'ViewMap' },
  {
    name: 'Cercas', path: '/cercas', component: Cercas, permission: 'ViewFence',
  },
  { name: 'Relatórios', path: '/relatorios', component: Relatorios, permissions: [
    "ViewHistoryReport",
    "ViewObservationReport",
    "ViewBatteryReport",
    "ViewPositionReport",
    "ViewBatteryChartReport",
    "ViewTemperatureChartReport",
    "ViewPathReport",
  ]},
  { name: 'Cadastros', path: '/cadastros', component: Records, permissions: [
    "UserRecord",
    "CompanyRecord",
    "EquipmentRecord",
    "EquipmentModelRecord",
    "EquipmentPackingRecord",
    "EquipmentColorRecord",
    "EquipmentMobileOperatorRecord",
    "EquipmentExemplaryRecord",
  ]},
  { name: 'Ferramentas', path: '/ferramentas', component: Tools, permissions: [
    "Poff",
    "Transfer",
    "DisableRF",
    "BulkCommand",
  ]},
  { name: 'Administração', path: '/administracao', component: Admin, permissions: [
    "AccessProfile",
  ]},
  {
    name: 'Detalhes do Equipamento',
    path: '/detalhes-equipamento/:id',
    component: EquipmentDetail,
    hidden: true,
  },
  {
    name: 'Editar Perfil', path: '/editar-perfil/:id', component: ProfileEdit, hidden: true,
  },
  {
    name: 'Editar Empresa', path: '/editar-empresa/:id', component: CompanyEdit, hidden: true,
    basicEdit: true,
  },
];

function Home(props) {

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', flex: 1, width: '100%',
    }}
    >
      <NavBar pages={pages} />
      <SubRoutes defaultPage={1} pages={pages} />
    </div>
  );
}

export default Home;
