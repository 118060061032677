import React from 'react';
import { withStyles } from '@material-ui/core';

import SubRoutes from "components/SubRoutes";
import EditUser  from "pages/Records/Users/Edit";
import NewUser  from "pages/Records/Users/New";
import ListUser  from "pages/Records/Users/List";

function Users (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Usuários",
      path: '/novo',
      component: NewUser,
    },
    {
      name: "Usuários",
      path: '/:id',
      component: EditUser,
    },
    {
      name: "Usuários",
      path: '',
      component: ListUser
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={2}
      pages={pages}
      match={match}
    />
  )
}

const styles = theme => ({
  /** */
});

export default withStyles(styles)(Users);
