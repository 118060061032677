import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import SubBar from 'components/SubBar';
import SubRoutes from 'components/SubRoutes';
import TransferEquipment from 'pages/Tools/TransferEquipment';
import BulkCommand from 'pages/Tools/BulkCommand';
import DisableRF from 'pages/Tools/DisableRF';
import Poff from 'pages/Tools/Poff/Poff';

const pages = [
  {
    name: 'Comandos em massa',
    path: '/comandos',
    component: BulkCommand,
    permission: 'BulkCommand',
  },
  {
    name: 'POFF',
    path: '/poff',
    component: Poff,
    permission: "Poff",
  },
  {
    name: 'Desativar RF',
    path: '/desativarRF',
    component: DisableRF,
    permission: "DisableRF"
  },
  {
    name: 'Transferir Equipamentos',
    path: '/transferir',
    component: TransferEquipment,
    permission: "Transfer",
  },
];

function Tools(props) {
  const { match } = props;
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return (
    <Grid container direction="column" style={{ flex: 1 }}>
      <SubBar breadcrumbs={breadcrumbs} items={pages} match={match} />
      {/* Para definir as rotas precisamos importar o compoenente SubRoutes */}
      <SubRoutes setBreadcrumbs={setBreadcrumbs} defaultPage={0} pages={pages} match={match} />
    </Grid>
  );
}

export default Tools;
