const MAPS = {
  dashboard: { path: '/dashboard', label: 'Dashboard' },
  mapa: { path: '/mapa', label: 'Mapa' },
  estaveis: { path: '/dashboard/estaveis', label: 'Estáveis' },
  'bateria-baixa': { path: '/dashboard/bateria-baixa', label: 'Bateria baixa' },
  'sem-posicionar': { path: '/dashboard/sem-posicionar', label: 'Sem posicionar' },
  'posicoes-atrasadas': { path: '/dashboard/posicoes-atrasadas', label: 'Posições atrasadas' },
  'jammer-detectado': { path: '/dashboard/jammer-detectado', label: 'Em emergência' },
};
export const getBreadcrumbsFor = (path) => {
  const breadcrumbs = [];
  if (!path) {
    return breadcrumbs;
  }
  const exploded = path.split('/').filter(p => p);
  exploded.forEach((p) => {
    const crumb = MAPS[p];
    if (crumb) {
      breadcrumbs.push(crumb);
    }
  });
  return breadcrumbs;
};
