import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider, Button } from '@material-ui/core';
import { get } from 'services/fetch';
import { CommandSimpleToggle } from './CommandToggle';
import { CommandTextField } from './Commands';
import { MyCheckbox as Checkbox } from 'components/lists/CheckboxList';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#41475a',
  },
  input: {
    borderBottom: theme.input.borderStyle.borderBottom,
  },
  divider: {
    height: 1,
    marginTop: 26,
  },
  sendCommandBtn: {
    fontWeight: 600,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end'
    },
  },
}));

const CommandPv1GprsFields = (props) => {
  const {
    rfTriangulation,
    positionSendInterval,
    movementSensorMode,
    temperatureAlarmMode,
    decouplingSensorMode,
    handleSubmit,
    containerStyle,
    imei,
    showStatus,
    canDisable,
  } = props;

  const classes = useStyles();
  const [LR, setLR] = useState(rfTriangulation || false);
  const [MC, setMC] = useState(positionSendInterval !== '5');
  const [MM, setMM] = useState(movementSensorMode || false);
  const [MT, setMT] = useState(temperatureAlarmMode || false);
  const [MD, setMD] = useState(decouplingSensorMode || false);
  const [lowRangeTemperature, setLowRangeTemperature] = useState(props.lowRangeTemperature || 0);
  const [highRangeTemperature, setHighRangeTemperature] = useState(props.highRangeTemperature || 0);

  const [lastCommand, setLastCommand] = useState({});

  const [enabledCommands, setEnabledCommands] = useState({
    LR: true,
    MC: true,
    MM: true,
    MT: true,
    MD: true,
    rangeTemperature: true,
  });

  useEffect(() => {
    const fetch = async () => {
      const lastCommands = await get(`equipment-event/queued-command?imei=${imei}`);
      if (lastCommands.length > 0) {
        setLastCommand(lastCommands[0]);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (lastCommand.imei) {
      setLR(lastCommand.pv1Fields.LR);
      setMC(lastCommand.pv1Fields.MC);
      setMM(lastCommand.pv1Fields.MM);
      setMT(lastCommand.pv1Fields.MT);
      setMD(lastCommand.pv1Fields.MD);
      setLowRangeTemperature(lastCommand.pv1Fields.PT && lastCommand.pv1Fields.PT.split(':')[0]);
      setHighRangeTemperature(lastCommand.pv1Fields.PT && lastCommand.pv1Fields.PT.split(':')[1]);
    }
  }, [lastCommand]);

  const _handleSubmit = async () => {
    const pv1Fields = {};

    // Build commands according to enabled fields
    if (enabledCommands.LR) pv1Fields.LR = LR;
    if (enabledCommands.MC) pv1Fields.MC = MC;
    if (enabledCommands.MM) pv1Fields.MM = MM;
    if (enabledCommands.MD) pv1Fields.MD = MD;
    if (enabledCommands.MT) pv1Fields.MT = MT;
    if (enabledCommands.rangeTemperature) pv1Fields.PT = `${lowRangeTemperature}:${highRangeTemperature}`;

    handleSubmit({ pv1Fields });
  };

  return (
    <div style={containerStyle}>
      <CommandSimpleToggle
        showStatus={showStatus}
        currentStatus={rfTriangulation}
        data={{ label: 'RF' }}
        state={LR}
        setState={setLR}
        isEnabled={enabledCommands.LR}
        toggleEnabled={
          canDisable ? value => setEnabledCommands({ ...enabledCommands, LR: value }) : null
        }
      />
      <CommandSimpleToggle
        showStatus={showStatus}
        currentStatus={decouplingSensorMode}
        data={{ label: 'Alarme beacon' }}
        state={MD}
        setState={setMD}
        isEnabled={enabledCommands.MD}
        toggleEnabled={
          canDisable ? value => setEnabledCommands({ ...enabledCommands, MD: value }) : null
        }
      />
      <CommandSimpleToggle
        showStatus={showStatus}
        currentStatus={positionSendInterval !== '5'}
        data={{
          label: 'Período de transmissão',
          isOnLabel: 'Modo Emergência - 5 minutos',
          isOffLabel: 'Modo Normal - 60 minutos',
        }}
        state={MC}
        setState={setMC}
        isEnabled={enabledCommands.MC}
        toggleEnabled={
          canDisable ? value => setEnabledCommands({ ...enabledCommands, MC: value }) : null
        }
      />
      <CommandSimpleToggle
        showStatus={showStatus}
        currentStatus={movementSensorMode}
        data={{ label: 'Alarme Movimento' }}
        state={MM}
        setState={setMM}
        isEnabled={enabledCommands.MM}
        toggleEnabled={
          canDisable ? value => setEnabledCommands({ ...enabledCommands, MM: value }) : null
        }
      />
      <CommandSimpleToggle
        showStatus={showStatus}
        currentStatus={temperatureAlarmMode}
        data={{ label: 'Alarme Temperatura' }}
        state={MT}
        setState={setMT}
        isEnabled={enabledCommands.MT}
        toggleEnabled={
          canDisable ? value => setEnabledCommands({ ...enabledCommands, MT: value }) : null
        }
      />
      <div>
        <Grid
          container
          alignItems="center"
          style={{ opacity: enabledCommands.rangeTemperature ? 1 : 0.4 }}
        >
          {canDisable &&
            <Checkbox
              setValue={value => setEnabledCommands({ ...enabledCommands, rangeTemperature: value })}
              checked={enabledCommands.rangeTemperature}
              value={enabledCommands.rangeTemperature}
            />
          }
          <Grid item>
            <Grid container direction="column">
              <p className={classes.inputLabel}> Range temperatura </p>
              <Grid container>
                <Grid item className={classes.textFieldContainer}>
                  <CommandTextField
                    name="lowRangeTemperature"
                    value={lowRangeTemperature}
                    setValue={setLowRangeTemperature}
                    currentStatus={props.lowRangeTemperature}
                    showStatus={showStatus}
                    disabled={!enabledCommands.rangeTemperature}
                  />
                </Grid>
                <Grid item className={classes.textFieldContainer}>
                  <CommandTextField
                    name="highRangeTemperature"
                    value={highRangeTemperature}
                    setValue={setHighRangeTemperature}
                    currentStatus={props.highRangeTemperature}
                    showStatus={showStatus}
                    disabled={!enabledCommands.rangeTemperature}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* <Divider className={classes.divider}/> */}
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{
          marginTop: 50,
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.sendCommandBtn}
          onClick={_handleSubmit}
        >
          Enviar comando
        </Button>
      </Grid>
    </div>
  );
};

CommandPv1GprsFields.defaultProps = {
  containerStyle: {},
};

export default CommandPv1GprsFields;
