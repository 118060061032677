import { getItem, setItem } from './local-storage';

import { get } from '../services/fetch';
import { removeItem } from '../services/local-storage';
import { emitEvent } from '../utils/events';

export function isLogged() {
  return Boolean(getItem('user'));
}

export function updateUser(user) {
  setItem('user', user);
}

export async function logoutUser() {
  try {
    const res = await get('auth/logout');
  } catch (err) {
    console.log(err);
  } finally {
    removeItem('permissions');
    removeItem('user');
  }
}
export async function getUser() {
  return getItem('user');
}

export async function getUserCompany() {
  const user = await getUser();
  if (!user.company) {
    console.log(`Company of ${user.name} not found`);
  }
  return user.company;
}
