import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { TextField } from '@material-ui/core';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { Rule, validate } from "../../utils/formValidator";


const zipcodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-',  /\d/, /\d/, /\d/];
const ZipcodeInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={zipcodeMask}
      guide={false}
    />
  );
};

const ZipcodeField = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const [ value, setValue ] = useState('');

  const [ validations, setValidations ] = useState({
    rules: [
        Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
        Rule('matches', [/\d{5}-\d{3}/], true, 'Formato inválido para o campo'),
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const onChange = e => { setValue(e.target.value) }
  const onBlur = () => { validateField() }

  const validateField = () => {
    let currentRules = (props.required || value) ? validations.rules : [];
    let _validations = {
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(value, currentRules)
    }
    setValidations(_validations);
    return _validations;
  }

  const setFieldValue = (value) => {
    if (value) {
      setValue(conformToMask(value, zipcodeMask).conformedValue);
    }
  }

  useImperativeHandle(ref, () => ({
      inputRef,
      value,
      raw: value.replace(/\D/g, ''),
      isInvalid: validations.isInvalid,
      alreadyValidated: validations.alreadyValidated,
      validateField,
      setFieldValue
  }), [value, validations.isInvalid, validations.alreadyValidated]);

  return (
    <TextField
      {...props}
      ref={inputRef}
      InputProps={{inputComponent: ZipcodeInput}}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={validations.isInvalid}
      helperText={validations.isInvalid && validations.message || ''}
    />
  )
});

export default ZipcodeField
