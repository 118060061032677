import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import GlobalLinearProgress from 'components/Progress';

import theme from './styles/theme';
import Login from './pages/Login';
import Home from './pages/Home';
import PasswordRecover from './pages/Auth/PasswordRecover';
import ErrorPage from './pages/Error';
import { isLogged } from './services/user';

import Snack from './components/Snack';
import 'react-perfect-scrollbar/dist/css/styles.css';

function App() {


  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalLinearProgress></GlobalLinearProgress>
      <Snack />
      <Router>
        <Switch>
          <Route
            exact
            path="/login"
            component={Login}
          />
          <Route
            exact
            path="/error"
            component={ErrorPage}
          />
          <Route
            path="/recover/:hash?"
            component={PasswordRecover}
          />
          <PrivateRoute
            path="/"
            component={Home}
          />
        </Switch>
      </Router>
    </MuiThemeProvider>

  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isLogged() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default App;
