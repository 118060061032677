import React, { useReducer, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { ButtonSubmit } from 'components/buttons/ButtonSubmit';
import { ButtonRemove, ButtonCancel } from 'components/buttons/ButtonsForm';
import CheckboxList from 'components/lists/CheckboxList';
import CommandPv2GprsFields, { getDefaultEnabled } from 'components/commands/CommandPv2GprsFields';
import ModalFenceAddEquipment from 'components/modals/ModalFenceAddEquipment';
import TableMenu, {TableColumn} from 'components/table/TableMenu';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from 'assets/icon/lixeira.svg';
import ReactSVG from 'react-svg'

import SimpleTable from "components/table/Table";
import TableHeader from 'components/table/TableHeader';
import Add from '@material-ui/icons/Add';
import TableHeaderAction from 'components/table/TableHeaderAction';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import CircularLoading from 'components/loading/CircularLoading';
import MapFence from 'components/map/MapFence';
import AutoCompleteGray from "components/fields/AutoCompleteGray";
import ErrorMessage from "components/fields/ErrorMessage";
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';

import { fetchAuthenticated, postWithErrorHandler, putWithErrorHandler } from 'services/fetch';
import { useAccessLog } from 'hooks/logs';


import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  datetimeFormatter,
  percentageFormatter,
  latlongFormatter,
} from 'utils/formatters'
import { buildUrl, defaultQuery } from 'utils/query';
import { isRfOn, getPositionIndicator } from 'utils/helpers';

function LeftCard (props) {

  const useStyles = makeStyles(theme => ({
    root: {
      ...theme.custom.paper.root,
      height: props => props.height,
      boxShadow: 'none',
      marginRight: 25,
      paddingLeft: 29,
      paddingRight: 29,
      paddingTop: 32,
      paddingBottom: 32,
    },
    header: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_4,
    },
    container: {
      ...theme.custom.containers.center,
      flexDirection: 'column',
    },
  }));

  const classes = useStyles({
    height: 380,
  });

  const {
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    onNameBlur,
    fence,
    onDescriptionBlur,
  } = props;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setName(fence && fence.name || '');
    setDescription(fence && fence.description || '');
  }, [fence]);

  return (
    <Paper className={classes.root}>
      <Typography className={classes.header}>Informações</Typography>
      <div className={classes.container}>
        <TextField
          required
          label="Nome"
          name="name"
          className={classes.textField}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onBlur={() => {
            onNameBlur(name);
          }}
          fullWidth
          error={errors.name && touched.name}
          helperText={errors.name && touched.name && errors.name}
        />
        <TextField
          label="Descrição"
          name="description"
          className={classes.textField}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          onBlur={() => {
            onDescriptionBlur(description);
          }}
          fullWidth
          error={errors.address && touched.address}
          helperText={errors.address && touched.address && errors.address}
        />
      </div>
    </Paper>
  )
}

function EditEquipments(props) {
 const useStyles = makeStyles(theme => ({
    root: {
      ...theme.custom.paper.root,
      minHeight: props => props.minHeight,
      boxShadow: 'none',
      marginTop: 23,
    },
    placeholder: {
      fontSize: 15,
      color: theme.palette.gray.gray_4,
      width: 400,
    },
    header: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_4,
    },
    removeIcon: {
      width: 47,
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    }
  }));
  const classes = useStyles({
  });

  const {equipments, setEquipments, fence, setFence} = props;
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if(fence && Object.keys(fence).length > 0) {
      setData({ data : fence.equipments});
    }
  }, [fence]);

  const onRemoveClick = async (id) => {
    setIsRemoving(true);
    const _equipments = fence.equipments.filter(equip => equip._id !== id);
    const logMessage = 'Remover equipamento em editar cercas.';
    const successMessage = "Equipamentos atualizados.";
    const errorMessage = "Erro ao tentar atualizar os equipamentos."
    try {
      const response = await putWithErrorHandler('fence/'+fence._id, {
        equipments: _equipments.map(equip => equip._id),
      }, { logMessage, successMessage, errorMessage } );
      setFence(response);
      setIsRemoving(false);
    } catch(e) {
      setIsRemoving(false);
    }
  }

  const columns = [
    { label:'Equipamento', value: (row) => row.vehicle || row.shortImei, },
    { label:'Empresa', value: (row) => row.currentCompany && row.currentCompany.name },
    { component: (row) => (
      <TableColumn
        key={row._id}
        row={row}>
          <div className={classes.iconContainer}>
          <IconButton
            style={{ boxShadow: 'unset' }}
            title="Remover"
            className={classes.removeIcon}
            onClick={() => onRemoveClick(row._id)}>
              <ReactSVG
                src={RemoveIcon}
              />
          </IconButton>
          </div>
      </TableColumn>
    )}
  ];

  return (
    <Grid container>
      <Grid item xs={12} style={{position: 'relative'}}>
        <CircularLoading isLoading={isRemoving} />
        <ModalFenceAddEquipment
          isVisible={isAddVisible}
          setIsVisible={setIsAddVisible}
          fence={fence}
          setFence={setFence}
          equipments={equipments}
          setEquipments={setEquipments}
        />
        <SimpleTable
          columns={columns}
          showPagination={false}
          data={data}
          containerStyle={{
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 23,
            marginBottom: 0,
            paddingBottom: 0,
          }}
          paperStyle={{
            paddingTop: 0,
          }}
          HeaderComponent={(
            <TableHeader
              headerFilters={[
                <Typography className={classes.header} key='headerFilter'>
                  Equipamentos
                </Typography>
              ]}
              headerActions={[
                <TableHeaderAction
                  title="Adicionar equipamento"
                  Icon={Add}
                  onClick={() => {
                    setIsAddVisible(true);
                  }}
                />
              ]}
            >
            </TableHeader>
          )}
        />
      </Grid>
    </Grid>
  )

}

export function SelectEquipments(props) {
 const useStyles = makeStyles(theme => ({
    root: {
      ...theme.custom.paper.root,
      minHeight: props => props.minHeight,
      boxShadow: 'none',
      marginTop: 23,
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 29,
      marginBottom: 27,
    },
    container: {
      marginTop: 17,
      borderBottom: 'solid 1px #c7cad94d',
      paddingBottom: 26,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    header: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_4,
    },
    chip: {
      marginRight: 10,
      // marginTop: 10,
    },
    placeholder: {
      fontSize: 15,
      color: theme.palette.gray.gray_4,
      width: 400,
    },
  }));

  const classes = useStyles({
    minHeight: 240,
  });


  const {
    equipments,
    setEquipments,
    fence,
    containerStyle,
    hideTitle,
    checkAll
  } = props;
  const [loadingEquipments, setLoadingEquipments] = useState(false);
  const [chips, setChips] = useState([]);

  const loadEquipments = async (company) => {
    if (!company) { setEquipments([]); }
    else {
      setLoadingEquipments(true);
      const filter = {
        'currentCompany._id' : {
          value: company._id,
          toObjectId: false,
        }
      };
      const _equipments = await fetchAuthenticated('get', `equipment/list?filter=${JSON.stringify(filter)}`);
      const data = await _equipments.json();
      const _equipmentsToAdd = [];
      data.forEach(equip => {
        if(!equipments.some(_equip => _equip.imei === equip.imei)) {
          _equipmentsToAdd.push(equip);
        }
      })
      setEquipments([...equipments, ..._equipmentsToAdd]);
      setLoadingEquipments(false);
    }
  }

  const handleDelete = chip => {
    const _chips = chips.filter(item => {
      return item._id !== chip._id;
    });
    const _equipments = equipments.filter(equipment => {
      return equipment.currentCompany._id !== chip._id;
    })
    setChips(_chips);
    setEquipments(_equipments);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{position: 'relative'}}>
        <Paper className={classes.root} style={containerStyle}>
          {loadingEquipments &&
            <CircularLoading isLoading={loadingEquipments} />
          }
          {!hideTitle &&
            <Typography className={classes.header}>Equipamentos</Typography>
          }
          <Grid container>
            <Grid item xs={12} className={classes.container} >
              <AutoCompleteGray
                menuContainerStyle={{
                  marginTop: 5
                }}
                containerStyle={{
                  width: 254,
                  marginRight: 10,
                }}
                fetchUrl='company?notPaginated=true&fields=_id,name'
                placeholder='Buscar empresa'
                showPlaceholder={true}
                hideLabel={true}
                name='company'
                getOptionLabel={(opt) => opt.name}
                loadingMessage="Carregando empresas..."
                noOptionsMessage="Nenhuma empresa encontrada."
                onChange={
                  ({ opt }) => {
                    if(opt && !chips.some(chip => chip._id === opt._id)) {
                      loadEquipments(opt);
                      setChips([...chips, { name: opt.name, _id: opt._id}]);
                    }
                  }
                }
              />
              {chips.map((item, index) => {
                return (
                  <Fade key={item._id + index} in={true} timout={1000}>
                    <Chip
                      label={item.name}
                      clickable
                      color="primary"
                      onDelete={() => {
                        handleDelete(item);
                      }}
                      deleteIcon={<CloseIcon style={{ width: 15 }} />}
                      variant="outlined"
                      className={classes.chip}
                    />
                  </Fade>
                );
              })}
            </Grid>
            <Grid item xs={12} className={classes.checkboxContainer}>
              {equipments.length === 0 &&
                <Typography className={classes.placeholder}>Nenhum equipamento encontrado.</Typography>
              }
              <CheckboxList
                data={equipments}
                setData={setEquipments}
                getName={(item) => item.shortImei}
                labelStyle={{
                  fontSize: 16,
                  color: '#41475a',
                  fontWeight: 'normal',
                }}
                checkAll={checkAll}
                containerStyle={{
                  flexDirection: 'row',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
                showCheckAll={equipments.length > 0}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
SelectEquipments.defaultProps = {
  containerStyle: {},
  hideTitle: false,
  checkAll: false,
}

function Commands (props) {
  const { getCommandData, setCommandData, fence} = props;
  const [command, setCommand] = useState();
  const [defaultEnabledCommands, setDefaultEnabledCommands] = useState(null);

  const useStyles = makeStyles(theme => ({
    root: {
      ...theme.custom.paper.root,
      minHeight: props => props.minHeight,
      boxShadow: 'none',
      marginTop: 23,
    },
    header: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_4,
      marginBottom: 27,
    }
  }));

  const classes = useStyles({ });

  const handleSubmit = () => {
  }
  useEffect(() => {
    if(fence && fence.command) {
      setCommand(fence.command);
    }
  }, [fence]);

  /* set enabled commands */
  useEffect(() => {
    if (fence && Object.keys(fence).length > 0) {
      setDefaultEnabledCommands({
        ...getDefaultEnabled(false, fence.command)
      });
    }
  }, [fence]);

  return (
    <Grid container>
      <Grid item xs={12} style={{position: 'relative'}}>
        <Paper className={classes.root}>
          <Typography className={classes.header}>Comandos</Typography>
          {command &&
            <CommandPv2GprsFields
              handleSubmit={handleSubmit}
              getData={getCommandData}
              setData={setCommandData}
              defaultEnabledCommands={defaultEnabledCommands}
              canDisable
              disableSubmit
              {...command}
            />
          }
          {!command &&
            <CommandPv2GprsFields
              handleSubmit={handleSubmit}
              getData={getCommandData}
              setData={setCommandData}
              canDisable
              disableSubmit
            />
          }
        </Paper>
      </Grid>
    </Grid>
  );
}


function FenceForm(props) {
  useAccessLog('Acesso a cerca')
  const { classes, location, fence, setFence, title, history } = props;
  const [layer, setLayer] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [command, setCommand] = useState({});
  const [submit, setSubmit] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  /* data to be send */
  const [data, setData] = useState({});

  useEffect(() => {
    if (fence && Object.keys(fence).length > 0) {
      setLayer(fence.layer);
      setName(fence.name);
      setDescription(fence.description);
      setEquipments(fence.equipments);
    }
  }, [fence]);



  const _onSubmit = (values, setSubmitting) => {
    setSubmitting(false);
    setData({...values});
    setSubmit(true);
  }

  const buildDataAndSubmit = async (data, command, equipments, layer) => {
    const onlyCheckedEquipments = equipments.filter((equip) => equip.checked);
    const _data = {
      ...data,
      command,
      equipments: onlyCheckedEquipments.map(equip => equip._id),
      layer: {
        layerType: layer.layerType,
        bounds: layer.layer._bounds,
        latlngs: layer.layer._latlngs,
        latlng: layer.layer._latlng,
        radius: layer.layer._radius,
      },
      geoJSON: layer.layer.toGeoJSON(),
    }

    const successMessage = 'Cerca criada.';
    const errorMessage = 'Erro ao tentar criar uma cerca.';
    const logMessage = 'Criação de cercas.';
    try {
      const response = await postWithErrorHandler('fence', _data, {
        successMessage, errorMessage, logMessage
      });
      // history.push(`/cercas/${response._id}`);
      history.push(`/cercas`);
    } catch(e) {
      console.log(e);
    }
  };

  const editSubmit = async (data, command, equipments, layer, fence) => {
    const onlyCheckedEquipments = equipments.filter((equip) => equip.checked);
    const _data = {
      ...data,
      command,
    }

    if(layer.needParse) {
      _data.layer = {
        layerType: layer.layerType,
        bounds: layer.layer._bounds,
        latlngs: layer.layer._latlngs,
        latlng: layer.layer._latlng,
        radius: layer.layer._radius,
      }
      _data.geoJSON = layer.layer.toGeoJSON();
    }

    const successMessage = 'Informações atualizadas.';
    const errorMessage = 'Erro ao tentar atualizar a cerca.';
    const logMessage = 'Edição de cercas.';
    const response = await putWithErrorHandler('fence/'+fence._id, _data, {
      successMessage, errorMessage, logMessage
    });

    history.push(`/cercas`);
  };

  /* Listen for user submit, and then ask for command data by
   * setting setCommand(true). After that, when command is set,
   * we are ready to submit.
  */
  useEffect(() => {
    if(submit) {
      const isCommandSet = command && Object.keys(command).length > 0;
      /* command is set and new fence */
      if(isCommandSet && !fence) {
        buildDataAndSubmit(data, command, equipments, layer);
        setSubmit(false);
        return;
      }

      /* command is set and edit fence */
      if(isCommandSet && fence) {
        editSubmit(data, command, equipments, layer, fence);
        setSubmit(false);
        return;
      }

      /* if user pressed submit, lets get command data */
      if (command !== null) {
        setCommand(null);
        return;
      }

    }
    // equipments and layer are here so their values are
    // updated when buildDataAndSubmit is fired
  }, [submit, data, command, equipments, layer]);

  const FenceSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    description: Yup.string(),
    layer: Yup.mixed().test({
      name: 'layerTest',
      message: 'Forma geométrica obrigatória',
      test: value => {
        return Object.keys(value).length > 0;
      },
    }),
  });

  return (
      <Formik
        initialValues={{
          name: name,
          description: description,
          layer: layer,
        }}
        enableReinitialize
        validationSchema={FenceSchema}
        onSubmit={(values, { setSubmitting }) => {
          _onSubmit(values, setSubmitting);
        }}
      >
        {({values, handleChange, handleSubmit, setFieldValue, errors, touched, isSubmitting}) => (
          <div className={classes.root}>
            <div className={classes.header}>
              <Typography className={classes.headerText}>{title}</Typography>
            </div>
            <Grid container className={classes.mapGrid}>
              <Grid item xs={3}>
                <LeftCard
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  onNameBlur={setName}
                  onDescriptionBlur={setDescription}
                  fence={fence}
                />
              </Grid>
              <Grid item xs={9}>
                <ErrorMessage name="layer"></ErrorMessage>
                <MapFence
                  name="layer"
                  fence={fence}
                  height={380}
                  disableExpand
                  containerStyle={{}}
                  equipments={[]}
                  onLayerChange={(layer) => {
                    setLayer(layer);
                  }}
                />
              </Grid>
            </Grid>
            {!fence &&
              <SelectEquipments fence={fence} equipments={equipments} setEquipments={setEquipments}/>
            }
            {fence &&
              <EditEquipments fence={fence} setFence={setFence} equipments={equipments} setEquipments={setEquipments}/>
            }
            <Commands
              getCommandData={command}
              setCommandData={setCommand}
              fence={fence}
            />
            <Grid container justify="flex-end" spacing={1} style={{marginTop: 20, marginBottom: 28}}>

              {props.handleCancel &&
                <Grid item xs={12} md={'auto'}>
                  <ButtonCancel
                    onClick={props.handleCancel}
                  >
                  </ButtonCancel>
                </Grid>
              }
              {props.handleRemove &&
                <Grid item xs={12} md={'auto'}>
                  <ButtonRemove
                    onClick={props.handleRemove}
                    disabledCondition={isSubmitting.form}
                  >
                  </ButtonRemove>
                </Grid>
              }
              <Grid item xs={12} md={'auto'}>
                <ButtonSubmit
                  disabledCondition={isSubmitting}
                  onSubmit={handleSubmit}
                >
                </ButtonSubmit>
              </Grid>
            </Grid>
          </div>
        )}
    </Formik>
  );
};

const styles = theme => ({
  root: {
    position: 'relative',
    paddingTop: theme.main.padding.top,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },headerText: {
    fontSize: theme.text.header.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  leftCardPaper: {
    borderRadius: 16,
  },
  mapGrid: {
    paddingTop: theme.main.padding.top,
  }
})
export default withRouter(withStyles(styles)(FenceForm));
