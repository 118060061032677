import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    position: 'unset',
  },
  summaryRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  summaryContent: {
    alignItems: 'center',
    flexGrow: 'unset',
  },
  panelRoot: {
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.text.subheader.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  checkboxLabel: {
    fontSize: theme.text.content.fontSize,
    fontWeight: theme.text.content.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  checkbox: {
    width: 28,
    height: 28,
    borderRadius: 7,
    marginRight: 21,
  },
  detailCheckbox: {
    width: 28,
    height: 28,
    borderRadius: 7,
    marginRight: 10,
  },
  detailContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 24,
  }
}));


function CollapseCheckbox (props) {
  const classes = useStyles();
  const { item, index, handleOnChange, handleCheckboxOnChange, row } = props;

  return (
    <ExpansionPanel classes={{
      'root': classes.root
    }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          'root': classes.summaryRoot,
          'content': classes.summaryContent,
        }}
      >
        <Checkbox
          onChange={ (e) => {
            handleOnChange(e, index);
          }}
          className={classes.checkbox}
          color="primary"
          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} />}
          checkedIcon={<CheckBoxIcon style={{ fontSize: 28 }} />}
          checked={item.checked}
          value={item.checked}
        />
        <Typography className={classes.heading}>{item.label}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: classes.panelRoot,
        }}
        style={{
          flexDirection: row ? 'row' : 'column',
          paddingBottom: row ? '24px' : '10px'
        }}
      >
        {item.items.sort((a, b) => a.label.localeCompare(b.label)).map((checkbox, _index) => {
          return (
            <div key={checkbox.label} className={classes.detailContainer}>
              <Checkbox
                onChange={ (e) => { handleCheckboxOnChange(e, checkbox); }}
                className={classes.detailCheckbox}
                color="primary"
                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} />}
                checkedIcon={<CheckBoxIcon style={{ fontSize: 28 }} />}
                checked={checkbox.checked}
                value={checkbox.checked}
              />
              <Typography className={classes.checkboxLabel}>{checkbox.label}</Typography>
            </div>
          )
        })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )

}

export default function CollapseCheckboxList(props) {
  const classes = useStyles();
  const { data, setData, dividerStyle = {}, row = false } = props;

  const handleCollapseOnChange = (e, item) => {
    if (item.items) {
      item.items.forEach((item) => {
        item.checked = e.target.checked;
      });
      setData([...data]);
    }
  };

  const handleCheckboxOnChange = (e, item) => {
    item.checked = e.target.checked;
    setData([...data]);
  }

  // Update root item state if any of its child has changed
  useEffect(() => {
    let update = false;
    data.forEach((item) => {
      if(item.items) {
        const isAnyChecked = item.items.some((e) => {
          return e.checked;
        });
        if (item.checked !== isAnyChecked) {
          item.checked = isAnyChecked;
          update = true;
        }
      }
    });
    if (update) {
      setData([...data]);
    }
  }, [data, setData]);

  return (
    <div className={classes.root}>
        {data.map((item, index) => {
          if (item.items) {
            return (
              <>
                <CollapseCheckbox
                  row={row}
                  key={item.label + index}
                  item={item} index={index}
                  handleOnChange={(e) => handleCollapseOnChange(e, item)}
                  handleCheckboxOnChange={handleCheckboxOnChange}
                  checked={item.checked}
                  value={item.checked}
                />
                <div style={dividerStyle} />
              </>

            )
          } else {
            return (
              <div className={classes.detailContainer} key={item.label + index}>
                <Checkbox
                  onChange={ (e) => {
                    handleCheckboxOnChange(e, item);
                  }}
                  className={classes.checkbox}
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} />}
                  checkedIcon={<CheckBoxIcon style={{ fontSize: 28 }} />}
                  checked={item.checked}
                  value={item.checked}
                />
                <Typography className={classes.heading}>{item.label}</Typography>
              </div>
            )
          }
        })}
   </div>
  );
}
