import { datetimeFormatter, defaultFormatter, imeiFormatter } from 'utils/formatters';

export const REPORT_COLUMNS = [
  {
    label: 'Empresa',
    value: ({ equipment }) => (equipment
      ? equipment.currentCompany
        ? defaultFormatter(equipment.currentCompany.name)
        : defaultFormatter(undefined)
      : defaultFormatter(undefined)),
    sort: 'equipment.currentCompany.name',
  },
  {
    label: 'Equipamento',
    value: ({ equipment }) => equipment && equipment.shortImei || '',
    sort: 'equipment.shortImei',
    headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
  },
  {
    label: 'Data/Hora',
    value: ({ timestamp }) => datetimeFormatter(timestamp),
    sort: 'timestamp',
    headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
  },
  {
    label: 'Protocolo',
    value: ({ protocolVersion }) => defaultFormatter(protocolVersion),
    sort: 'protocolVersion',
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    label: 'Dados',
    value: ({ rawEvent }) => defaultFormatter(rawEvent),
    sort: '',
  },
];
