import React, { useState, useEffect } from "react";
import { useEffectSkipFirst } from 'hooks/common';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Link,
} from '@material-ui/core';
import Modal from 'components/modals/Modal';
import { TextFieldModalStyle as TextField } from 'components/fields/Text.field';
import CollapseCheckboxList from 'components/fields/CollapseCheckboxList';
import { ButtonSubmit } from 'components/buttons/ButtonSubmit';

import { Formik } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #e3e4e8',
    paddingBottom: 43,
  },
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 21,
  },
  notificationHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  buttonContinue: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 40,
  }

}));

const buildOption = (label, name, item) => {
  return {
    label,
    name,
    checked: item.sms || item.email,
    items: [
      { label: 'Email', name: 'email', checked: item.email },
      { label: 'SMS', name: 'sms', checked: item.sms},
    ]
  }
}

const getDefaultData = () => {
  return {
    name: '',
    email: '',
    mobile: '',
    options: [
      buildOption('Cercas', 'fence', {sms: false, email: false}),
      // buildOption('Ancoragem', 'beacon', {sms: false, email: false}),
      buildOption('Suspeita', 'suspect', {sms: false, email: false}),
      buildOption('Emergência', 'emergency', {sms: false, email: false}),
    ],
  };
}

const ModalEmergencyContact = (props) => {
  const {
    isVisible,
    setIsVisible,
    onSubmit,
    onEdit,
   } = props;
  const classes = useStyles();

  const [ showModal, setShowModal ] = useState(isVisible);
  const [ contact, setContact ] = useState(props.defaultData);
  const [ options, setOptions ] = useState(contact.options);
  const [ editMode, setEditMode ] = useState(false);

  useEffectSkipFirst(() => {
    if (props.contact) {
      const options = [
        buildOption('Cercas', 'fence', props.contact.fence),
        // buildOption('Ancoragem', 'beacon', props.contact.beacon),
        buildOption('Suspeita', 'suspect', props.contact.suspect),
        buildOption('Emergência', 'emergency', props.contact.emergency),
      ];
      const formattedContact = {
        name: props.contact.name,
        email: props.contact.email,
        mobile: props.contact.mobile,
        options,
      };
      setContact(formattedContact);
      setOptions(options);
      setEditMode(true);
    }
  }, [props.contact]);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const _onClose = () => {
    if(setIsVisible) {
      props.onClose();
      const defaultData = getDefaultData();
      setContact(defaultData);
      setOptions(defaultData.options);
      setEditMode(false);
      setIsVisible(false);
    }
  }

  const onContinue = (values, setSubmitting) => {
    const newContact = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      fence: { sms: false, email: false},
      beacon: { sms: false, email: false},
      emergency: { sms: false, email: false},
      suspect: { sms: false, email: false},
    }
    /* parse values to contact schema */
    values.options.forEach(c => {
      c.items.forEach(item => {
        if(item.checked) {
          newContact[c.name][item.name] = true;
        }
      })
    })

    if(editMode) {
      newContact._id = props.contact._id;
      onEdit(newContact);
    } else {
      newContact._id = new Date().valueOf();
      onSubmit(newContact);
    }
    _onClose();
  }

  const ContactSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string().email('Email inválido'),
    mobile: Yup.string(),
  });


  return (
    <Modal
      isVisible={showModal}
      setIsVisible={setShowModal}
      title='Editar contato de emergência'
      onClose={_onClose}
      hideButton
    >

    <Formik
      initialValues={{
        name: contact.name,
        email: contact.email,
        mobile: contact.mobile,
        options: contact.options,
      }}
      enableReinitialize
      validationSchema={ContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        onContinue(values, setSubmitting);
      }}
    >
      {({values, handleChange, handleSubmit, setFieldValue, errors, touched, isSubmitting}) => (
          <div className={classes.root}>
            <div className={classes.contactContainer}>
              <TextField
                defaultStyle
                label="Nome"
                name="name"
                defaultValue={values.name}
                onChange={handleChange('name')}
                containerStyle={{
                  marginRight: 70,
                  flex: 1,
                }}
              />
              <TextField
                defaultStyle
                label="Email"
                name="email"
                defaultValue={values.email}
                onChange={handleChange('email')}
                containerStyle={{
                  marginRight: 70,
                  flex: 1,
                }}
              />
              <TextField
                defaultStyle
                label="Celular"
                name="mobile"
                defaultValue={values.mobile}
                onChange={handleChange('mobile')}
                containerStyle={{
                  marginRight: 70,
                  flex: 1,
                }}
                mobileMask
              />
            </div>
            <div className={classes.notificationsContainer}>
              <Typography className={classes.notificationHeader}>Notificações</Typography>
              <CollapseCheckboxList
                row
                data={options}
                setData={setOptions}
                dividerStyle={{borderBottom: '1px solid #e3e4e8'}}
              />
              <div className={classes.buttonContinue}>
              <ButtonSubmit label="Continuar" onSubmit={handleSubmit}/>
              </div>
            </div>
          </div>
      )}
      </Formik>
    </Modal>
  )
}

ModalEmergencyContact.defaultProps = {
  defaultData: getDefaultData(),
}
export default ModalEmergencyContact;
