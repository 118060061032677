import React from 'react';
import { TableCell, Checkbox } from '@material-ui/core';

export default function CheckboxColumn(props) {
  const { onChange, checked } = props;
  return (
    <TableCell style={{ padding: 10, width: 15 }}>
      <Checkbox color="primary" onChange={(e) => onChange(e.target.checked)} checked={checked} />
    </TableCell>
  );
}

CheckboxColumn.defaultProps = {
  onChange: () => null,
};
