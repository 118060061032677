import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Link,
} from '@material-ui/core';
import { get, fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import Modal from 'components/modals/Modal';
import { getUser, updateUser } from 'services/user';
import { useEffectSkipFirst } from 'hooks/common';
import { datetimeFormatter } from 'utils/formatters';

import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from 'assets/icon/lixeira.svg';
import ReactSVG from 'react-svg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  itemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 21,
    paddingTop: 21,
    borderBottom: 'solid 1px #80849d4d',
  },
  itemText: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    flex: 1,
  },
  itemTextBold: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    color: theme.palette.gray.gray_5,
  },
  userTextLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    // justifyContent: 'flex-end',
    color: theme.palette.gray.gray_5,
  },
  userText: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
    wordWrap: 'break-word',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    // justifyContent: 'flex-end',
  },
}));

const ModalEquipmentHistory = (props) => {
  const { isVisible, setIsVisible, equipment } = props;
  const classes = useStyles();

  const [ showModal, setShowModal ] = useState(isVisible);
  const [ isLoading, setIsLoading] = useState(true);
  const [ records, setRecords ] = useState([]);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if(equipment && isVisible) {
      const fn = async () => {
        setIsLoading(true);
        const _data = await fetchAuthenticated(get, `equipment-history?filter={"equipmentId": "${equipment._id}"}&sort={"date": -1}`);
        setRecords(_data.data);
        setIsLoading(false);
      }
      fn();
    }
  }, [equipment, isVisible]);

  const onClose = () => {
    if(setIsVisible) {
      setIsVisible(false);
    }
  }


  return (
    <Modal
      isVisible={showModal} setIsVisible={setShowModal} title='Histórico do equipamento'
      hideButton={true}
      isLoading={isLoading}
      onClose={onClose}
    >
      <div className={classes.root}>
        {records.map((item) => {
         return (
          <div key={item._id} className={classes.itemContainer}>
            <Typography className={classes.itemText} style={{maxWidth: 'fit-content'}}>
              {datetimeFormatter(item.date)}
            </Typography>
            <Typography className={classes.itemTextBold}>
              {item.tag}
            </Typography>
            <Typography className={classes.itemText}>
              {item.description}
            </Typography>
            <div style={{display:'flex', flexDirection: 'column',flex: 1, justifyContent: 'flex-end'}}>
              <Typography className={classes.userTextLabel} style={{fontSize: 12}}>
                Responsável
              </Typography>
              <Typography className={classes.userText}>
                {item.username}
              </Typography>
            </div>
          </div>
        )})}
      </div>
    </Modal>
  )
}

export default ModalEquipmentHistory;
