import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import { Grid, Typography } from '@material-ui/core';

import theme from 'styles/theme';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import ReportFilters from 'components/filter/ReportFilters';

import { fetchAuthenticated } from 'services/fetch';
import { usePaginatedData } from 'hooks/common';
import { useAccessLog } from 'hooks/logs';
import { useQuery } from 'hooks/fetch';
import { emitEvent } from 'utils/events';

import { REPORT_COLUMNS } from './columns';

import ReportFilterContext from 'pages/Reports/ReportFilterContext';
import { getDateFiltersFromContext } from 'pages/Reports/helpers';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
}));


function BatteryReport() {
  useAccessLog('Acesso ao relatório de bateria');
  const classes = useStyles();
  const filterContext = useContext(ReportFilterContext);
  const [data, setData] = usePaginatedData();

  const [dataInitalDateFilled, setDataInitalDateFilled] = useState(false);
  const [dataFinalDateFilled, setDataFinalDateFilled] = useState(false);

  const PATH = 'equipment/battery-report';
  const defaultFilter = {
    'imei': filterContext.equipment ? filterContext.equipment.imei : undefined,
    $expr: { $and: getDateFiltersFromContext(filterContext, '$lastEvent.timestamp') },
  }
  if (filterContext.company) {
    defaultFilter['currentCompany._id'] = {
      value: filterContext.company._id,
      toObjectId: false,
    }
  }

  const [query, setQuery, isLoading] = useQuery(PATH, setData,
    [
      'currentCompany.name',
      'shortImei',
      'vehicle',
      'lastEvent.timestamp',
      'lastEvent.positionSendInterval',
      'lastEvent.internalBatteryPercentage',
      'lastEvent.temperature',
      'lastEvent.gsmModelSignal',
      'lastEvent.gpsSpeed',
      'lastEvent.gpsSatelliteNumber',
      'lastEvent.local',
      'situation',
    ],
    {
      filter: defaultFilter,
      sort: {
        'lastEvent.timestamp': -1,
      }
    },
    (q) => {
      if(!dataInitalDateFilled|| !dataFinalDateFilled) {
        return false;
      }
      const obj = q.queryObject;
      return Boolean(obj && obj.filter && obj.filter.imei);
    },
  );

  const [isGeneratingReport, setIsGeneratingReport] = useState(null);

  const exportSpreadsheet = async () => {
    setIsGeneratingReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', { message: 'Exportação limitada a 5000 itens', type: 'info' });
    }
    const response = await fetchAuthenticated(
      'GET',
      `equipment/report/battery/xls${query.queryString}`,
    );
    logAction('Exportou relatório de bateria em XLS');
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Bateria.xls');
    setIsGeneratingReport(false);
  };

  const exportPdf = async () => {
    setIsGeneratingReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', { message: 'Exportação limitada a 5000 itens', type: 'info' });
    }
    const response = await fetchAuthenticated(
      'GET',
      `equipment/report/battery/pdf${query.queryString}`,
    );
    logAction('Exportou relatório de bateria em PDF');
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Bateria.pdf');
    setIsGeneratingReport(false);
  };

  const onCompanyChange = async ({ opt, filterHandler }) => {
    filterContext.updateCompany(opt);
    if (!opt) {
      filterHandler.removeFilter('currentCompany._id');
    } else {
     filterHandler.updateQueryFilter({
        'currentCompany._id': {
          value: opt._id,
          toObjectId: false,
        }
      });
    }
  };

  const onEquipmentChange = async ({ opt, filterHandler }) => {
    filterContext.updateEquipment(opt);
    if (!opt) {
      filterHandler.removeFilter('imei');
    } else {
      filterHandler.updateQueryFilter({ imei: opt.imei });
    }
  };

  const onDateChange = async ({ date, filterHandler }, comparator) => {
    let _andClause = [];

    if (query && query.queryObject) {
      const _obj = { ...query.queryObject.filter };

      // Check if filter already exists and removes it
      if (_obj && _obj.$expr && _obj.$expr.$and) {
        _andClause = _obj.$expr.$and.filter(exp => !exp.hasOwnProperty(`${comparator}`));
      }
    }

    if (date) {
      _andClause.push({
        [`${comparator}`]: ['$lastEvent.timestamp', { $dateFromString: { dateString: date } }],
      });
    }

    // It removes add an empty _andClause if no value is supplied and the
    // correct value otherwise
    filterHandler.updateQueryFilter({
      $expr: { $and: _andClause },
    });
  };

  const _tableHeaderActions = [
    <TableHeaderAction
      title="Exportar XLS"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>XLS</Typography>}
      onClick={exportSpreadsheet}
    />,
    <TableHeaderAction
      title="Exportar PDF"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>PDF</Typography>}
      onClick={exportPdf}
    />,
  ];

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <ReportFilters
          path={PATH}
          query={query}
          setQuery={setQuery}
          values={filterContext}
          onCompanyChange={onCompanyChange}
          onEquipmentChange={onEquipmentChange}
          onInitialDateChange={({ date, filterHandler }) => {
            setDataInitalDateFilled(date ? true : false);
            filterContext.updateInitialDate(date);
            onDateChange({ date, filterHandler }, '$gte');
          }}
          onFinalDateChange={({ date, filterHandler }) => {
            setDataFinalDateFilled(date ? true : false);
            filterContext.updateFinalDate(date);
            onDateChange({ date, filterHandler }, '$lte');
          }}
        />
      </Grid>
      <Table
        columns={REPORT_COLUMNS}
        data={data}
        query={query}
        setQuery={setQuery}
        HeaderComponent={<TableHeader headerActions={_tableHeaderActions} />}
        containerStyle={{
          marginTop: 0,
        }}
        isLoading={isLoading || isGeneratingReport}
      />
    </Grid>
  );
}

BatteryReport.defaultProps = {};

export default BatteryReport;
