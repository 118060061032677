import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';

function PaginationActions(props) {
  const { classes, onChangePage, theme } = props;
  const { page = 1, rowsPerPage = 10, count = 0 } = props;

  const lastPage = Math.ceil(count / rowsPerPage);

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handlePageClick = (event, page_) => {
    onChangePage(event, page_);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, lastPage - 1));
  };

  const createRange = (page, range) => {
    const start = Math.max(1, page - range);
    const end = Math.min(lastPage, page + range);

    const arrLeft = Array.from({ length: page - start }, (v, k) => k + start);
    const arrRight = Array.from({ length: 1 + end - page }, (v, k) => k + page);

    return arrLeft.concat(arrRight).map(i => (
      <Fade key={i} in={true}>
        <Button
          onClick={(e) => {
            handlePageClick(e, i - 1);
          }}
          size="small"
          variant={(i === page && 'contained') || 'text'}
          color="primary"
          className={classes.button}
        >
          {i}
        </Button>
      </Fade>
    ));
  };

  return (
    <div className={classes.root}>
      <Button
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
        className={classes.iconButton}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </Button>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
        className={classes.iconButton}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      {createRange(page + 1, 2)}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
        className={classes.iconButton}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
        className={classes.iconButton}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
const styles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
  button: {
    margin: theme.spacing(1),
    padding: '4px 8px',
    minWidth: 'unset',
    borderRadius: 5,
    boxShadow: 'none',
  },
  iconButton: {
    margin: theme.spacing(1),
    padding: '4px 8px',
    minWidth: 'unset',
    borderRadius: 5,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.gray.gray_3,
  },
});

export default withStyles(styles, { withTheme: true })(PaginationActions);
