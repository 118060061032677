import { datetimeFormatter, imeiFormatter, defaultFormatter } from 'utils/formatters';

export const REPORT_COLUMNS = [
  {
    label: 'Empresa',
    value: ({ equipment: { currentCompany } }) => defaultFormatter(currentCompany ? currentCompany.name : undefined),
    sort: 'equipment.currentCompany.name',
  },
  {
    label: 'Data',
    value: row => datetimeFormatter(row.date),
    sort: 'date',
    headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
  },
  {
    label: 'Usuário',
    value: row => row.user && row.user.username || '',
    sort: 'user.username',
    headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
  },
  {
    label: 'Equipamento',
    value: row => row.equipment.shortImei,
    sort: 'equipment.shortImei',
    headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
    cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
  },
  {
    label: 'Observação',
    value: row => row.text,
    sort: 'text',
  },
];
