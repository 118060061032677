import React from 'react';
import BaseList from 'components/lists/BaseList';
import ActionList from 'components/lists/ActionList';
import Edit from '@material-ui/icons/Edit';
import TableHeaderAction from 'components/table/TableHeaderAction';
import { CheckboxColumn } from 'components/table/columns';
import ModalEquipmentEdit from 'components/modals/ModalEquipmentEdit';
import { useAccessLog } from 'hooks/logs';

function ListEquipment(props) {
  useAccessLog('Acesso ao cadastro de equipamentos');
  const { match } = props;
  return (
    <ActionList
      modals={{
        'Edit': {
          isVisible: false,
          component: (props) => (
            <ModalEquipmentEdit key='EditModal' {...props} />
          ),
        },
      }}
      headerActions={[
        (setVisibleModal, checkedList) => (
          <TableHeaderAction
            title="Editar em massa"
            Icon={Edit}
            disabled={checkedList.length === 0}
            onClick={() => { setVisibleModal('Edit', true) }}
          />
        )
      ]}

      columns={[
        { value: row => row.shortImei, label: 'IMEI', sort: 'shortImei' },
        {
          value: row => (row.currentCompany ? row.currentCompany.name : 'Desconhecida'),
          label: 'Empresa',
          sort: 'currentCompany.name'
        },
        { value: row => (row.isActive ? 'Sim' : 'Não'), label: 'Ativo', sort: 'isActive' },
        { value: row => (row.mobileOperator ? row.mobileOperator.name : ''), label: 'Operadora', sort: 'mobileOperator.name' },
        {
          value: row => (row.equipmentType ? row.equipmentType.name : ''),
          label: 'Tipo de Equipamento',
          sort: 'equipmentType.name'
        },
      ]}
      genericSearchFields={[
        { label: 'Imei', name: 'imei' },
        { label: 'Código', name: 'code' },
        { label: 'Descrição', name: 'description' },
        { label: 'Tipo', name: 'equipmentType.name' },
        { label: 'Nome da Empresa', name: 'currentCompany.name' },
        { label: 'Cidade', name: 'currentCompany.addressCity.nome' },
        { label: 'Estado', name: 'currentCompany.addressState.nome' },
      ]}
      basePath="equipment"
      title="Lista de Equipamentos"
      addButtonText="Cadastrar Equipamento"
      addUrl={`${match.path}/novo`}
    />
  );
}

export default ListEquipment;
