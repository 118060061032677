import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Button } from '@material-ui/core';

import { get } from '../../services/fetch';
import CommandModal from './CommandModal';
import CommandToggle from './CommandToggle';
import { emitEvent } from 'utils/events';
import { postWithErrorHandler } from 'services/fetch';

const useStyles = makeStyles(theme => ({
  sendCommandBtn: {
    fontWeight: 600,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
  },
}));

const CommandPv2Admin = (props) => {
  const classes = useStyles();
  const { showStatus } = props;
  const [showModal, setShowModal] = useState(true);
  const [lastCommand, setLastCommand] = useState({});
  const [imei, setImei] = useState(props.imei || '');

  const [status, setStatus] = useState(
    props.status || {
      buzzer: false,
      internalListener: false,
      io_1: false,
      io_2: false,
      wifiMode: false,
      expirationClockReset: false,
      erbMode: false,
      snifferMode: false,
    },
  );

  useEffect(() => {
    if (lastCommand.imei) {
      if (lastCommand.status) {
        setStatus(lastCommand.status);
      }
    }
  }, [lastCommand]);

  useEffect(() => {
    console.log(status);
  }, [status]);

  useEffect(() => {
    const fetch = async () => {
      const lastCommands = await get(`equipment-event/queued-command?imei=${imei}`);
      if (lastCommands.length > 0) {
        setLastCommand(lastCommands[0]);
      }
    };
    fetch();
  }, []);

  const handleSubmit = async () => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }
    const logMessage = `Comando administrativo enviado ao equipamento ${imei.slice(-8)}`;
    const successMessage = `Comando enviado com sucesso.`;
    const errorMessage = `Erro ao tentar enviar comando.`;
    let response = await postWithErrorHandler('equipment-event/command', {
      imei,
      status,
      action: 'Admin',
    }, {
      successMessage, errorMessage, logMessage
    });
  };

  const onClose = () => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }
  };

  return (
    <CommandModal
      onClose={onClose}
      isOpen={showModal}
      handleSubmit={handleSubmit}
      title="Comandos Admin Protocolo 2"
    >
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.expirationClockReset}
        data={{ label: 'Reseta Relógio Expiração', name: 'expirationClockReset' }}
        status={status}
        setStatus={setStatus}
      />
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.buzzer}
        data={{ label: 'Buzzer', name: 'buzzer' }}
        status={status}
        setStatus={setStatus}
      />
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.internalListener}
        data={{ label: 'Escuta Interna', name: 'internalListener' }}
        status={status}
        setStatus={setStatus}
      />
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.io_1}
        data={{ label: 'Saída 1', name: 'io_1' }}
        status={status}
        setStatus={setStatus}
      />
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.io_2}
        data={{ label: 'Saída 2', name: 'io_2' }}
        status={status}
        setStatus={setStatus}
      />
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.wifiMode}
        data={{ label: 'Ativar WIFI', name: 'wifiMode' }}
        status={status}
        setStatus={setStatus}
      />
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.erbMode}
        data={{ label: 'Ativar ERB', name: 'erbMode' }}
        status={status}
        setStatus={setStatus}
      />
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.snifferMode}
        data={{ label: 'Ativar Farejador', name: 'snifferMode' }}
        status={status}
        setStatus={setStatus}
      />
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{
          marginTop: 50,
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.sendCommandBtn}
          onClick={handleSubmit}
        >
          Enviar comando
        </Button>
      </Grid>
    </CommandModal>
  );
};

export default CommandPv2Admin;
