import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

function Command(props) {
  const {
    classes, title, onClose, isOpen,
  } = props;

  const closeModal = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      maxWidth={false}
      classes={{ paper: classes.paper }}
      onClose={closeModal}
    >
      <PerfectScrollbar>
        <div className={classes.modalHeader}>
          <Typography className={classes.headerText} id="modal-title">
            {title}
          </Typography>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div>
        {props.children}
      </PerfectScrollbar>
    </Dialog>
  );
}

const styles = theme => ({
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 932,
    borderRadius: 22,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 300,
  },
  headerText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  submitButton: {
    minWidth: 145,
    minHeight: 45,
    fontSize: 17,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    marginTop: 10,
  },
});
export default withStyles(styles)(Command);
