import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditEquipmentModel from "pages/Records/EquipmentModels/Edit";
import ListEquipmentModel from "pages/Records/EquipmentModels/List";
import NewEquipmentModel from "pages/Records/EquipmentModels/New";

export default function EquipmentModels (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Modelos",
      path: '/novo',
      component: NewEquipmentModel,
    },
    {
      name: "Modelos",
      path: '/:id',
      component: EditEquipmentModel,
    },
    {
      name: "Modelos",
      path: '',
      component: ListEquipmentModel
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
