import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditEquipmentExemplary from "pages/Records/EquipmentExemplaries/Edit";
import ListEquipmentExemplary from "pages/Records/EquipmentExemplaries/List";
import NewEquipmentExemplary from "pages/Records/EquipmentExemplaries/New";

export default function EquipmentExemplaries (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Exemplares",
      path: '/novo',
      component: NewEquipmentExemplary,
    },
    {
      name: "Exemplares",
      path: '/:id',
      component: EditEquipmentExemplary,
    },
    {
      name: "Exemplares",
      path: '',
      component: ListEquipmentExemplary
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
