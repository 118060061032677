import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Divider from '@material-ui/core/Divider';
import { emitEvent } from 'utils/events';
import { getPositionIndicator, getEventName, isPositionValid } from 'utils/helpers';
import { get } from 'services/fetch';

import { useEffectSkipFirst } from 'hooks/common';
import L from 'leaflet';
import PinIcon from 'assets/icon/pin-blue.png';

import InfoCardField from './InfoCardField';
import InfoCard from './InfoCard';

import 'leaflet-draw/dist/leaflet.draw';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import './Map.css';

const expandedHeight = 700;

const EventsConfig = {
  lastEvent: {
    iconColor: 'blue',
    headerColor: '#548cc1',
  },
  lastEventGps: {
    iconColor: 'darkgray',
    headerColor: '#42475a',
  },
  lastEventLbsQuectel: {
    iconColor: 'blue',
    circle: {
      color: '#548cc1',
      radius: () => { return 600 },
    },
    headerColor: '#548cc1',
  },
  lastEventLbsXglobal: {
    iconColor: 'blue',
    circle: {
      color: '#548cc1',
      radius: () => { return 600 },
    },
    headerColor: '#548cc1',
  },
  lastEventLbsGoogle: {
    iconColor: 'purple',
    circle: {
      color: '#673E9A',
      radius: () => { return 600 },
    },
    headerColor: '#673E9A',
  },
  lastEventLbsCellID: {
    iconColor: 'gray',
    circle: {
      color: '#8E96B0',
      radius: (event) => {
        return event.cellIdRange || 0;
      },
    },
    headerColor: '#8E96B0',
  },
  lastEventEseye: {
    iconColor: 'lightblue',
    circle: {
      color: '#37b0c9',
      radius: () => { return 600 },
    },
    headerColor: '#37b0c9',
  },
}

const getXGlobalEventNames = (equipment) => {
  const events = ['lastEventEseye', 'lastEventLbsCellID'];
  const currentEventName = getEventName(equipment.lastEvent);
  if(currentEventName && events.indexOf(currentEventName) === -1) {
    events.push(currentEventName);
  }
  return events;
}

function Map (props) {
  const {
    classes,
    disablePopup,
    disableExpand,
    showXglobal,
    onEventClick,
    showAllFences,
    showCurrentEquipmentFences,
  } = props;
  const height = props.height || 400;
  const containerStyle = props.containerStyle || {};

  let [equipments, setEquipments] = useState([]);
  let [markers, setMarkers] = useState([]);
  let [circles, setCircles] = useState([]);
  let [fences, setFences] = useState([]);
  let [isFitBoundsFinished, setIsFitBoundsFinished] = useState(false);
  let [fenceLayers, setFenceLayers] = useState([]);

  const [showInfo, setShowInfo] = useState(false);
  const [toggleMap, setToggleMap] = useState(false);
  const [mapHeight, setMapHeight] = useState(height);
  const [mapInstance, setMapInstance] = useState();
  const [currentEquipment, setCurrentEquipment] = useState({});
  const [currentHeaderColor, setCurrentHeaderColor] = useState(null);
  // const [centerMapOn, setCenterMapOn] = useState(props.centerMapOn);

  useEffect(() => {
    if(props.currentEquipment) {
      setCurrentEquipment(props.currentEquipment);
    }
  }, [props.currentEquipment]);

  useEffect(() => {

    if(props.equipments) {
      if(props.equipments.data) {
        setEquipments(props.equipments.data);
      } else {
        // only update when equipment changes
        if(JSON.stringify(props.equipments) !== JSON.stringify(equipments)) {
          setEquipments(props.equipments);
        }
      }
    }
  }, [props.equipments])

  useEffect(() => {
    if (props.centerMapOn) {
      centerMap(props.centerMapOn.lastEvent.latitude, props.centerMapOn.lastEvent.longitude);
      setCurrentEquipment(props.centerMapOn);
      if(!disablePopup) {
        setShowInfo(true);
      }
      const _lastEvent = props.centerMapOn.lastEvent;
      if(!isPositionValid(_lastEvent)) {
        emitEvent('showSnack', {message: 'Posição inválida', type: 'error'});
      }
    }
  }, [props.centerMapOn]);

  const centerMap = (lat, long) => {
    mapInstance.setView(L.latLng(lat, long), 15, { animation: true });
  }
  // Shouw popup, set current equipment / current equipment-event
  const handleMarkClick = (equipment, equipmentEvent, e) => {

    // update current equipment
    setCurrentEquipment(equipment);

    // update current equipment-event if xglobal position is set
    if(showXglobal) {
      if (!onEventClick) {
        throw new Error('onEventClick not provided when showXglobal=true');
      }
      onEventClick(equipmentEvent);
    }

    // center map on click
    const latLng = e.target.getLatLng();
    centerMap(latLng.lat, latLng.lng);

    if(!disablePopup) {
      setShowInfo(true);
    }
  }

  const toogleMapSize = () => {
    setMapHeight((toggleMap && height) || expandedHeight);
    setToggleMap(!toggleMap);
  }

  const getIcon = (equipment, color) => {
    const iconTypes = {
      'Isca': {
        name: 'Pin',
        iconSize: [27, 40],
        iconAnchor: [13, 40],
      },
      'Moto': {
        name: 'Moto',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      'Carreta': {
        name: 'TruckTrailer',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      'Truck': {
        name: 'Truck',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      'Veículo Passeio': {
        name: 'Car',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      'Cavalo': {
        name: 'Horse',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      'Safeloggy': {
        name: 'Safeloggy',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
    };

    let icon = iconTypes['Isca'];
    if ( Object.keys(iconTypes).indexOf(equipment.equipmentType.name) > -1 ) {
      icon = iconTypes[equipment.equipmentType.name];
    }

    return L.icon({
      iconUrl: require(`assets/icon/${icon.name}_${color}.png`),
      iconSize: icon.iconSize,
      iconAnchor: icon.iconAnchor,
      // iconSize: [33, 45],
    });
  }

  // returns a list with every Xglobal positions if showXglobal is set to true
  // otherwise just return last event position
  const getPositions = (equipment) => {
    if(Object.keys(equipment.lastEvent).length === 0) { return []; }
    if(equipment.lastEvent.positionIndicator.length === 0) { return []; };
    const eventConfig = EventsConfig[getEventName(equipment.lastEvent)];
    let positions = [{
      event: equipment.lastEvent,
      position: [equipment.lastEvent.latitude, equipment.lastEvent.longitude],
      icon: getIcon(equipment, eventConfig.iconColor),
      circle: eventConfig.circle,
    }];

    if (showXglobal) {
      positions = [];
      for(const eventName of getXGlobalEventNames(equipment)) {
        const event = equipment[eventName];
        if(event && isPositionValid(event)) {
          positions.push({
            event: equipment[eventName],
            position: [equipment[eventName].latitude, equipment[eventName].longitude],
            icon: getIcon(equipment, EventsConfig[eventName].iconColor),
            circle: EventsConfig[eventName].circle,
          });
        }
      }
    }
    return positions;
  }

  const getCircle = (position) => {
    if(position.circle) {
      return L.circle(position.position, {
        radius: position.circle.radius(position.event),
        color: position.circle.color
      });
    }
  }

  useEffect(() => {
    const map = L.map('map', {
      zoom: 1,
      zoomControl: true,
    });
    // create map
    map.zoomControl.setPosition('bottomright');

    // setup layers
    const layer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    })
    layer.on('loading', () => {
      emitEvent('showGlobalLinearProgress')
    })
    layer.on('load', () => {
      emitEvent('hideGlobalLinearProgress');
    });
    layer.addTo(map);
    setMapInstance(map);

  }, [])

  /* load fences */
  useEffect(() => {
    if(showAllFences) {
      const _fetch = async () => {
        const fences = await get('fence/list');
        setFences(fences);
      }
      _fetch();
    }
  }, []);

  /* load fence by equipment */
  useEffect(() => {
    if(showCurrentEquipmentFences && currentEquipment._id) {

      const _fetch = async () => {
        const fences = await get('fence/get-by-equipment/'+currentEquipment._id);
        setFences(fences);
      }
      _fetch();
    }
  }, [currentEquipment]);

  useEffect(() => {
    if(fences.length > 0 && isFitBoundsFinished) {
      /* remove old layers */
      if(fenceLayers.length > 0) {
        fenceLayers.forEach(_layer => {
          mapInstance.removeLayer(_layer);
        });
      }
      const newLayers = [];
      fences.forEach((fence) => {
        const layers = L.geoJSON(fence.geoJSON);
        layers.addTo(mapInstance);
        newLayers.push(layers);
      });
      setFenceLayers(newLayers);
    }
  }, [fences, isFitBoundsFinished, mapInstance]);

  // re renders when map height is changed
  useEffect(() => {
    if(mapInstance) {
      mapInstance.invalidateSize();
    }
  }, [mapHeight]);

  // setup markers/pins/circles
  useEffect(() => {
    // delete old markers and circles
    if(markers.length > 0) {
      markers.forEach(marker => mapInstance.removeLayer(marker));
      circles.forEach(circle => circle.removeFrom(mapInstance));
    }

    if(equipments.length > 0) {
      const newMarkers = [];
      const newCircles = [];
      equipments.filter((equip) => equip.lastEvent).forEach(equipment => {
        const positions = getPositions(equipment);
        for(let position of positions) {
          try{
            if(position.event && isPositionValid(position.event)) {
              const circle = getCircle(position);
              if(circle) {
                circle.addTo(mapInstance);
                newCircles.push(circle);
              }
              const mark = L.marker(position.position, {icon: position.icon}, );
              mark.addTo(mapInstance).on('click', (e) => handleMarkClick(equipment, position.event, e));
              newMarkers.push(mark);
            } else {
              console.log(`Equipment: ${equipment.imei} with position ${JSON.stringify(position.position)} is not valid`);
            }
          }catch(err) {
            console.log(err);
            console.log(`Equipment: ${equipment.imei} lat/long is not valid`);
          }
        }
      });

      if(newMarkers.length > 0) {
        // center map
        const bounds = new L.LatLngBounds(
          newMarkers.map(mark => [mark.getLatLng().lat, mark.getLatLng().lng])
        );
        mapInstance.fitBounds(bounds.pad(0.5), {maxZoom: 15});
        setIsFitBoundsFinished(true);
      }
      setMarkers(newMarkers);
      setCircles(newCircles);
    }
  }, [equipments, showXglobal]);

  useEffect(() => {
    if(currentEquipment.lastEvent) {
      setCurrentHeaderColor(EventsConfig[getEventName(currentEquipment.lastEvent)].headerColor);
    }
  }, [currentEquipment]);

  return (
    <div className={classes.root} style={{...containerStyle}} >
      <div style={{ height: mapHeight}} >
        <div id='map' className={props.mapClass || classes.map}/>
      </div>

      {showInfo &&
      <Fade in style={{ transitionDelay: `100ms` }}>
        <InfoCard
          title={getPositionIndicator(currentEquipment.lastEvent)}
          onCloseClick={() => setShowInfo(false)}
          detailURI={`/detalhes-equipamento/${currentEquipment._id}`}
          headerColor={currentHeaderColor}
        >
          <div style={{display: 'inline-flex' }}>
            <InfoCardField label='imei' value={currentEquipment.shortImei} />
            <InfoCardField label='veículo' value={currentEquipment.vehicle} style={{marginLeft: 45}} />
          </div>
          <InfoCardField label='empresa' value={currentEquipment.currentCompany && currentEquipment.currentCompany.name} style={{marginTop:17}} />
          <InfoCardField label='local' value={currentEquipment.lastEvent && currentEquipment.lastEvent.local} style={{marginTop: 17}} />
        </InfoCard>
       </Fade>
      }

      {!disableExpand &&
        <IconButton disableRipple={true} id="expandMapIcon" className={classes.toggleMap} classes={{colorSecondary: 'white'}} onClick={toogleMapSize}>
          <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
        </IconButton>
      }
    </div>
  );
}
const styles = theme => ({
  root: {
    position:'relative',
  },
  map: {
    height: "100%",
    borderRadius: theme.border.radius,
    // marginTop: 50,
    marginBottom: 45,
  },
  toggleMap: {
    right: 36,
    top: 25,
    zIndex: 700,
    position: 'absolute',
    borderRadius: 14,
    color: theme.palette.gray_1,
    backgroundColor: theme.palette.background.white,
    width: 43,
    height: 43,
  },
})

export default withStyles(styles)(Map);
