import React, { useEffect } from 'react';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';

import FenceForm from 'components/forms/FenceForm';
import { logAction } from 'utils/logs';

export default function NewUser(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: `/cercas`, label: `Cercas` },
      { path: ``, label: `Nova cerca` }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs]);


  // Handle cancel
  const handleCancel = () => {
    history.push('/cercas');
  }

  return (
    <div>
      <FenceForm
        title='Nova cerca'
        handleCancel={handleCancel}
      />
    </div>
  );
}
