import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditEquipmentColor from "pages/Records/EquipmentColors/Edit";
import ListEquipmentColor from "pages/Records/EquipmentColors/List";
import NewEquipmentColor from "pages/Records/EquipmentColors/New";

export default function EquipmentColors (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Cores",
      path: '/novo',
      component: NewEquipmentColor,
    },
    {
      name: "Cores",
      path: '/:id',
      component: EditEquipmentColor,
    },
    {
      name: "Cores",
      path: '',
      component: ListEquipmentColor
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
