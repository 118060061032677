import React, { useState,useEffect } from "react";
import { withStyles } from "@material-ui/core";

import { post } from "services/fetch";
import { emitEvent } from "utils/events";
import AccessProfileForm from "components/forms/AccessProfileForm";

function NewAccessProfile (props) {
  const { history, setBreadcrumbs } = props;
  const fetchPath = 'authorization';
  const [ reload, setReload ] = useState(false);
  const [ newAccessProfile, setNewAccessProfile ] = useState(false);

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      {path: `/administracao/perfil-de-acesso`, label: `Perfis de Acesso`},
      {path: ``, label: `Novo`}
    ]);
    return () => {
      setBreadcrumbs([]);
    }
  }, []);

  useEffect(() => {
    if (reload && newAccessProfile) {
      history.push(`/administracao/perfil-de-acesso/${newAccessProfile._id}`);
    }
  }, [reload])



  // Handle form submit
  const handleSubmit = async (data) => {
    try {
      const response = await post(`${fetchPath}`, data);
      if (!response.ok) {
        throw new Error('')
      } else {
        const body = await response.json();
        setNewAccessProfile(body);
        emitEvent('showSnack', {message: 'Perfil de acesso criado com sucesso', type: 'success'});
      }
    } catch (err) {
      throw err;
    }
  }

  // Handle cancel
  const  handleCancel = () => {
    history.push('/administracao/perfil-de-acesso');
  }

  return (
    <AccessProfileForm
      title='Criar novo'
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      reload={setReload}
    />
  );
}

const styles = theme => ({
/** */
});

export default withStyles(styles)(NewAccessProfile);
