import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import Logo from '../assets/img/logo_xglobal.png';
import BackgroundLogin from '../assets/img/background_login.jpg';
import LogoSthima from '../assets/img/logo_sthima_small.png';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Redirect } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { post, get } from '../services/fetch';
import { getItem, setItem } from '../services/local-storage';
import { emitEvent } from '../utils/events';
import { logAction } from '../utils/logs';

function Login(props) {
  const { classes } = props;

  return (
    <Grid container justify="center" className={classes.main}>
      <Hidden smDown>
        <Grid item xs={12} md={7}>
          <LoginLeftPane classes={classes}></LoginLeftPane>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={5}>
        <LoginRightPane classes={classes}></LoginRightPane>
      </Grid>
    </Grid>
  );
}

function LoginLeftPane(props) {
  const { classes } = props;
  return (
    <Paper square={true} className={classes.leftPane} elevation={0}>
      <Grid container direction="column" justify="space-around" alignItems="flex-start" className={classes.leftPaneGrid}>
        <Grid item >
          <img className={classes.logo} src={Logo}></img>
        </Grid>
        <Grid item >
          <Typography className={classes.welcome} component="p">
            Bem-vindo(a) à<br />
            <span className={classes.bold600}>área do cliente</span>
          </Typography>
        </Grid>
        <Grid item >
          <Typography className={classes.contact} component="p">
            Central de Sinistros <span className={classes.bold800}>0800 771 34 16</span><br />
            Fone <span className={classes.bold800}>+55(11) 4130-7800</span><br />
            Email <span className={classes.bold800}>contato@xglobal.com</span>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

function LoginRightPane(props) {
  const { classes } = props;
  return (
    <Paper square={true} className={classes.rightPane} elevation={0}>
      <Grid container direction="column" className={classes.rightPaneGrid}>
        <Grid item xs={2} sm={3} className={classes.gridItem} style={{ alignItems: "flex-end" }}>
          <Typography className={classes.loginHeader} component="p">
            Faça login para acessar o sistema
          <span className={classes.bold600} style={{ whiteSpace: "nowrap" }}> X-Global</span>
          </Typography>
        </Grid>

        <Grid item xs={9} sm={7} style={{ maxWidth: "none" }}>
          <LoginForm classes={classes}></LoginForm>
        </Grid>

        <Grid item xs={1} sm={2} className={classes.gridItem} style={{ alignItems: "center" }}>
          <div className={classes.spanPoweredBy}>powered by <a href="https://www.sthima.com" target="_blank"><img src={LogoSthima} className={classes.sthimaLogoLink} /></a></div>
        </Grid>

      </Grid>
    </Paper>
  )
}

function LoginForm(props) {
  const { classes } = props;

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [user, setUser] = useState({ username: '', password: '' });
  const [isLogged, setLogged] = useState(getItem('user'));

  // Handle field update
  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value })
  };

  // Handle form submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    emitEvent('showGlobalLinearProgress');
    setSubmitDisabled(true);
    try {
      const response = await post('auth/login', user);
      const body = await response.json();

      // Login not authorized
      if (response.status >= 400) {
        setSubmitDisabled(false);
        emitEvent('showSnack', { message: body.message, type: 'error' });
      }
      // Login authorized
      else {
        const user = body;
        setItem('user', user);

        const permissions = await get(`authorization/user-permissions?id=${user._id}`);
        setItem('permissions', permissions);

        setLogged(body);
        logAction('Login');
      }
    } catch (err) {
      // setSubmitDisabled(false);
      setSubmitDisabled(false);
      emitEvent('showSnack', { message: err.message });
    } finally {
      emitEvent('hideGlobalLinearProgress');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container direction="column" justify="space-evenly" style={{ height: "100%" }}>
        <Grid item>
          <FormControl required className={classes.formControl} fullWidth>
            <InputLabel htmlFor="email" className={classes.inputLabel}>Login</InputLabel>
            <Input
              id="username"
              name="username"
              autoComplete="username"
              autoFocus
              value={user.username}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl required fullWidth>
            <InputLabel htmlFor="password" className={classes.inputLabel}>Senha</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              value={user.password}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl margin="none" fullWidth>
            <Link to='/recover' className={classes.link}>Esqueci minha senha</Link>
          </FormControl>
        </Grid>
        <Grid item className={classes.buttonSubmit}>
          <FormControl className={classes.buttonSubmit} margin="normal">
            <Button
              disabled={submitDisabled}
              type="submit"
              variant="contained"
              color="primary"
            >
              Entrar
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      {isLogged && (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />)
      }
    </form>
  )
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  main: {
    minHeight: '100vh',
    borderRadius: 0
  },
  leftPane: {
    height: '100%',
    paddingLeft: 61,
    backgroundImage: `url(${BackgroundLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
  },
  welcome: {
    color: theme.palette.text.white,
    fontSize: 45,
    lineHeight: 'normal'
  },
  contact: {
    color: theme.palette.text.white,
    fontSize: 18,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  leftPaneGrid: {
    height: '100%',
    width: '100%',
  },
  rightPaneGrid: {
    height: '100%',
    width: '100%',
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  form: {
    height: "100%",
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.0rem",
    },
  },
  formControl: {
    margin: "normal",
  },
  logo: {
    maxWidth: '100%',
  },
  rightPane: {
    height: '100%',
    width: '100%',
  },
  loginHeader: {
    color: theme.palette.gray.gray_1,
    fontSize: "2rem",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    // marginTop: height * 0.25,
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.2rem",
    },
    lineheight: 1.35,
    letterUpacing: "normal",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: "none",
  },
  bold600: {
    fontWeight: 600
  },
  bold800: {
    fontWeight: 600
  },
  spanPoweredBy: {
    fontSize: "15px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.gray.gray_1,
  },
  gridItem: {
    maxWidth: "none",
    display: "flex",
  },
  divPoweredBy: {
    // marginTop: height * 0.15,
  },
  inputLabel: {
    fontSize: "1.3rem",
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.0rem",
    },
  },
  sthimaLogoLink: {
    display: 'inline',
    maxHeight: 15
  },
  buttonSubmit: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    // marginTop: height * 0.03,
  }
});

export default withStyles(styles)(Login);
