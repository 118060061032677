export const getDateFiltersFromContext = (context, field) => {
  const dateFilters = [];
  if (context.initialDate) {
    dateFilters.push({
      $gte: [
        field,
        {
          $dateFromString: {
            dateString: context.initialDate ? context.initialDate : '',
          },
        },
      ],
    });
  }

  if (context.finalDate) {
    dateFilters.push({
      $lte: [
        field,
        {
          $dateFromString: {
            dateString: context.finalDate ? context.finalDate : '',
          },
        },
      ],
    });
  }
  return dateFilters;
};
