import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_5,
    textTransform: 'uppercase',
  },
  value: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
}));

function InfoCardField({ label, value, style }) {
  const classes = useStyles();

  return (
    <div style={style}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </div>
  );
}

InfoCardField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  style: PropTypes.object,
};

InfoCardField.defaultProps = {
  style: {},
};

export default InfoCardField;
