import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';

import ModalRemove from 'components/modals/ModalRemove';
import EquipmentExemplaryForm from 'components/forms/EquipmentExemplaryForm';

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

export default function EditEquipmentExemplaries(props) {
  const classes = useStyles();
  const { match, history, setBreadcrumbs } = props;
  const [equipmentExemplaries, setEquipmentExemplaries] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  // Use base equipmentExemplaries url
  console.log(match);
  const equipmentExemplariesURL = `equipment-exemplary/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/exemplares', label: 'Exemplares' },
      { path: '', label: `${equipmentExemplaries && equipmentExemplaries.name || ''}` }
    ]);
    return () => { setBreadcrumbs([]); }
  }, [setBreadcrumbs, match.params.id, equipmentExemplaries]);

  // Load equipmentExemplaries info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const response = await fetchAuthenticated('get', equipmentExemplariesURL);

      // Any error will redirect to equipmentExemplariess list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/empresas');
      } else {
        const data = await response.json();

        // Update protocol version to expected format

        setEquipmentExemplaries(data);
        logAction(`Acesso ao cadastro do exemplar ${data.name}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, equipmentExemplariesURL]);

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', equipmentExemplariesURL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();
      setEquipmentExemplaries(data);
      logAction(`Edição do exemplar ${data.name}`);
      emitEvent('showSnack', { message: 'Exemplar atualizado com sucesso', type: 'success' });
    }
  }

  // Handle cancel button click
  const handleCancel = () => {
    history.push('/cadastros/exemplares');
  }

  // Handle remove button click
  const handleRemove = () => {
    console.log('Handle remove');
    setShowDialog(true);
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  // Handle remove confirmation
  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', equipmentExemplariesURL);

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover o exemplar', type: 'error' });
      } else {
        logAction(`Remoção do exemplar ${equipmentExemplaries.name}`);
        emitEvent('showSnack', { message: 'Exemplar removido com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/exemplares');
      }
    }
  }

  return (
    <div>
      <EquipmentExemplaryForm
        title='Detalhes da Exemplar'
        data={equipmentExemplaries}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
      />

      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='do exemplar'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='o exemplar'
        extraContent='Ao realizar está ação o exemplar não estará mais disponível'
      />
    </div>
  );
}
