import React from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Modal from './Modal';
import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';

const ERROR_MESSAGES = {
  required: 'Campo obrigatório',
  match: 'Valores informados não são os mesmos'
};

const PasswordChangeSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(ERROR_MESSAGES.required),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], ERROR_MESSAGES.match)
    .required(ERROR_MESSAGES.required),

  currentPassword: Yup.string()
    .required(ERROR_MESSAGES.required),
});

function ModalChangePassword({ isVisible, setIsVisible }) {
  const onSubmit = async (values, {setSubmitting}) => {
    try {
      const response = await fetchAuthenticated('put', 'user/change-password', values);

      if (!response.ok) {
        throw new Error();
      } else {
        emitEvent('showSnack', {message: 'Senha alterada com sucesso', type: 'success'});
        setSubmitting(false);
        setIsVisible(false);
      }

    } catch (err) {
      emitEvent('showSnack', {message: 'Não foi possível alterar a senha', type: 'error'});
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={{
        newPassword: '',
        confirmPassword: '',
        currentPassword: '',
      }}
      validationSchema={PasswordChangeSchema}
      onSubmit={onSubmit}
      render={({
        errors, touched, handleChange, handleSubmit, submitForm, isSubmitting
      }) => (
        <Grid
          container
          title="Alterar senha"
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          buttonLabel="Salvar"
          preventDefaultSubmit
          handleSubmit={submitForm}
          component={Modal}
          isLoading={isSubmitting}
        >
          <Grid
            container
            direction="column"
            justify="space-between"
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid container justify="space-between" wrap="nowrap">
              <Grid
                item
                xs={6}
                component={TextField}
                type="password"
                name="currentPassword"
                label="Senha atual"
                onChange={handleChange}
                error={touched.currentPassword && Boolean(errors.currentPassword)}
                helperText={touched.currentPassword ? errors.currentPassword : ''}
                style={{ paddingRight: 20 }}
              />
            </Grid>
            <Grid container justify="space-between" wrap="nowrap">
              <Grid
                item
                xs={6}
                component={TextField}
                type="password"
                name="newPassword"
                label="Nova senha"
                onChange={handleChange}
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword ? errors.newPassword : ''}
                style={{ marginRight: 20 }}
              />

              <Grid
                item
                xs={6}
                component={TextField}
                type="password"
                name="confirmPassword"
                label="Confirmar nova senha"
                onChange={handleChange}
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                helperText={touched.confirmPassword ? errors.confirmPassword : ''}
                style={{ marginLeft: 20 }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    />
  );
}

ModalChangePassword.propTypes = {
  setIsVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

ModalChangePassword.defaultProps = {
  isVisible: true,
};

export default ModalChangePassword;
