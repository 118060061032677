import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { FixedSizeList as List } from "react-window";
import { CircularProgress } from '@material-ui/core';
import { Rule, validate } from "../../utils/formValidator";
import PerfectScrollbar from 'react-perfect-scrollbar'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    lineHeight: 1,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
    lineHeight: 1,
    whiteSpace: 'nowrap',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: -10,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage(props) {
  return (
    <MenuItem
      disabled={true}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    </MenuItem>
  );
}

function LoadingMessage(props) {
 return (
    <MenuItem
      disabled={true}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.loadingMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    </MenuItem>
  );

}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const fullWidth = props.selectProps.fullWidth === undefined ? true : props.selectProps.fullWidth;
  const InputProps = props.selectProps.InputProps || {};
  const InputContainerClass = props.selectProps.InputContainerClass || '';

  let InputLabelProps = props.selectProps.InputLabelProps;
  if (InputLabelProps) {
    InputLabelProps = { InputLabelProps };
  }

  return (
    <TextField
      fullWidth={fullWidth}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
        ...InputProps,
      }}
      className={InputContainerClass}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  const menuContainerStyle = props.selectProps.menuContainerStyle || {};
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps} style={menuContainerStyle}>
      <PerfectScrollbar>
        {props.children}
      </PerfectScrollbar>
    </Paper>
  );
}

function MenuList(props) {
    const itemSize = 46;
    let { children } = props;
    children = Array.isArray(children) ? children : [children];
    return (
      <PerfectScrollbar>
      <List
        height={Math.min(250, children.length * itemSize)}
        itemSize={itemSize}
        itemCount={children.length}
      >
        {({index, style}) => <div style={style}>{children[index]}</div>}
      </List>
      </PerfectScrollbar>
    );
  }

function IndicatorSeparator(props) {
  return (<span style={{display: 'none'}}></span>);
};

function LoadingIndicator(props) {
  return <CircularProgress style={{width: 15, height: 15, color: '#808492'}}></CircularProgress>
}


const components = {
  Control,
  Menu,
  MenuList,
  MultiValue,
  NoOptionsMessage,
  LoadingMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingIndicator,
  IndicatorSeparator,
};


const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  width: 15,
  height: 15,
});

const DropdownIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  width: 15,
  height: 15,
});

const AutocompleteField = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const [ isActive, setIsActive ] = useState(false);
  const [ value, setValue ] = useState(props.value || '');

  const [ validations, setValidations ] = useState({
    rules: [
      Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const onBlur = () => {
    setIsActive(false);
    validateField()
  }

  const onChange = (opt) => {
    setValue(opt);
    if (props.onChange) props.onChange(opt);
  }

  const validateField = () => {
    let currentRules = (props.required || value) ? validations.rules : [];
    let valueToValidate = value && props.getOptionValue ? props.getOptionValue(value) : value;
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(valueToValidate || '', currentRules)
    })
  }

  const setFieldValue = (value) => {
    setValue(value);
  }

  const resetField = () => {
    setValue('')
    setValidations({
      ...validations,
      alreadyValidated: false,
      isInvalid: false,
      message: ''
    })
  }

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: value && props.getRawValue ? props.getRawValue(value) : value,
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    resetField,
    setFieldValue
  }));

  let InputLabelProps = {
    shrink: Boolean(value) || isActive,
  };

  if(props.InputLabelProps) {
    InputLabelProps = { ...InputLabelProps, ...props.InputLabelProps }
  }

  const newProps = {
    ...props,
    placeholder: props.placeholder || '',
    ref: inputRef,
    onFocus:() => setIsActive(true),
    onBlur:() => onBlur(),
    onChange: (opt) => onChange(opt),
    value:value,
    components,
    styles:{
      clearIndicator: ClearIndicatorStyles,
      dropdownIndicator: DropdownIndicatorStyles,
    },
    textFieldProps:{
      required: Boolean(props.required),
      label: props.label,
      error: validations.isInvalid,
      helperText: validations.isInvalid && validations.message || '',
      disabled: props.disabled,
      onChange: (e) => { props.onTextChange && props.onTextChange(e)},
      InputLabelProps,
    },
  }

  return props.async ? (<AsyncSelect {...newProps} />) : (<Select {...newProps} />);
});

AutocompleteField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AutocompleteField);
// export default AutocompleteField
