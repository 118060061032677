import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditEquipment from "pages/Records/Equipments/Edit";
import ListEquipment from "pages/Records/Equipments/List";
import NewEquipment from "pages/Records/Equipments/New";

export default function Equipments (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Equipamentos",
      path: '/novo',
      component: NewEquipment,
    },
    {
      name: "Equipamentos",
      path: '/:id',
      component: EditEquipment,
    },
    {
      name: "Equipamentos",
      path: '',
      component: ListEquipment
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
