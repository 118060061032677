import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
  CircularProgress,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    height: '100%',
    mouseEvents: 'none',
    backgroundColor: '#FFFFFF88',
  },
  paper: {
    borderRadius: 22,
    padding: 32,
  },
});

export default function ModalConfirm(props) {
  const classes = useStyles();
  const {
    isOpen,
    setIsOpen,
    onConfirm,
    onCancel,
    confirmButtonLabel,
    cancelButtonLabel,
    title,
    isLoading,
  } = props;

  const onClose = () => {
    setIsOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      disableBackdropClick
      maxWidth="sm"
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <DialogTitle style={{ padding: 0 }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item style ={{
            fontSize: 20,
            fontWeight: 'bold',
            color: '#41475a',
          }}>{title || 'Confirmar ação'}</Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <IconButton aria-label="Close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <PerfectScrollbar>{props.children}</PerfectScrollbar>
      <DialogActions style={{padding: 0, marginTop: 50}}>
        <Button
          style={{ padding: '12px 16px', textTransform: 'none', boxShadow: 'none' }}
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
      {isLoading && (
        <Grid container justify="center" alignItems="center" className={classes.overlay}>
          <CircularProgress color="primary" />
        </Grid>
      )}
    </Dialog>
  );
}

ModalConfirm.defaultProps = {
  setIsOpen: () => null,
  cancelButtonLabel: 'Cancelar',
  confirmButtonLabel: 'Confirmar',
};
