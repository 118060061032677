export const buildUrl = (path, location, initialFilter={}) => `${path}${buildQuery(location.search, initialFilter).queryString}`;

export const buildQuery = (location, data) => {
  const params = new URLSearchParams(location.search);
  data = data || {};
  let query = '?';
  const _build = (array, get) => {
    let _query = '';
    for (const key of array) {
      const paramValue = get(key);
      if (key === 'filter') {
        continue;
      }
      if (key === 'sort') {
        _query += `${key}=${JSON.stringify(data[key]) || paramValue}&`;
        continue;
      }
      if (key === 'fields') {
        _query += `${key}=${data[key].join(',')}&`;
        continue;
      }
      _query += `${key}=${data[key] || paramValue}&`;
    }
    return _query;
  };

  query += _build(params.keys(), key => params.get(key));
  query += _build(Object.keys(data), key => data[key]);

  let filter = data.filter || params.get('filter');
  if (filter) {
    if (typeof filter === 'object') {
      filter = encodeURIComponent(JSON.stringify(filter));
    }
    query += `filter=${filter}`;
  } else {
    query = query.slice(0, -1);
  }

  const newQueryParams = new URLSearchParams(query);
  const queryObject = Object.fromEntries(newQueryParams.entries());
  if (queryObject.filter) {
    try {
      queryObject.filter = JSON.parse(queryObject.filter);
    } catch (err) {
      console.log(err);
    }
  }

  if (queryObject.sort) {
    try {
      queryObject.sort = JSON.parse(queryObject.sort);
    } catch (err) {
      console.log(err);
    }
  }

  if (queryObject.fields) {
    try {
      queryObject.fields = queryObject.fields.split(',');
    } catch (err) {
      console.log(err);
    }
  }

  return {
    queryString: query,
    queryObject,
  };
};

export const defaultQuery = {
  queryString: '',
  queryObject: {},
};

// updates query filter attribute and return a new FetchUrl
export const updateQueryFilter = (fetchUrl, attr, concat = false) => {
  const [fetchBasePath, params] = fetchUrl.split('?');
  const newQueryParams = new URLSearchParams(params);
  const queryObject = Object.fromEntries(newQueryParams.entries());
  let filterObject;
  if (queryObject.filter) {
    try {
      filterObject = JSON.parse(queryObject.filter);
    } catch (err) {
      console.log('error', err);
    }
  }
  return `${fetchBasePath}?filter=${JSON.stringify({ ...filterObject, ...attr })}`;
};
