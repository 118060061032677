import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles(theme => ({
  header: {
    borderBottomWidth: 1,
    borderBottomColor: `${theme.palette.gray.gray_6}4d`,
    borderBottomStyle: "solid"
  }
}));

function TableHeader(props) {
  const classes = useStyles();
  const { headerActions, headerFilters, headerFiltersContainerStyle, headerActionsContainerStyle } = props;

  return (
    <Grid
      container
      alignItems="baseline"
      justify="space-between"
      classes={{ root: classes.header }}
    >
      <Grid container style={{ flex: 1, ...headerFiltersContainerStyle }}>
        {headerFilters}
      </Grid>
      <Grid
        container
        justify="flex-end"
        style={{ flex: 1, marginTop: 15, marginBottom: 15, ...headerActionsContainerStyle }}
      >
        {headerActions.map((action, index) => (
          <Zoom in style={{ transitionDelay: `${index * 100}ms` }} key={index}>
            {action}
          </Zoom>
        ))}
      </Grid>
    </Grid>
  );
}

TableHeader.defaultProps = {
  options: [],
  headerActions: [],
  headerFilters: [],
  headerFiltersContainerStyle: {},
  headerActionsContainerStyle: {},
  value: null,
  onChange: () => {}
};

export default TableHeader;
