import React from 'react';
import { validatePermission, validatePermissions } from 'services/permissions';

export default function Permission(props) {
  const { name, names, ifNoPermission } = props;

  /* if one permission matches */
  if (names) {
    if (validatePermissions(names)) {
      return props.children;
    }
    if (ifNoPermission) {
      return ifNoPermission;
    }
    return <></>;
  }

  if (name) {
    if (!validatePermission(name)) {
      if (ifNoPermission) {
        return ifNoPermission;
      }
      return <></>;
    }
  }
  return props.children;
}
Permission.defaultProps = {
  name: '',
};
