import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';

import SubBar from "components/SubBar";
import SubRoutes from "components/SubRoutes";

import Users from 'pages/Records/Users';
import Companies from 'pages/Records/Companies';
import Equipments from 'pages/Records/Equipments';
import EquipmentModels from 'pages/Records/EquipmentModels';
import EquipmentPackings from 'pages/Records/EquipmentPackings';
import EquipmentColors from 'pages/Records/EquipmentColors';
import EquipmentMobileOperators from 'pages/Records/EquipmentMobileOperators';
import EquipmentExemplaries from 'pages/Records/EquipmentExemplaries';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
}));

const pages = [
  {
    name: "Usuários",
    path: '/usuarios',
    component: Users,
    permission: "UserRecord",
  },
  {
    name: "Empresas",
    path: '/empresas',
    component: Companies,
    permission: "CompanyRecord",
  },
  {
    name: "Equipamentos",
    path: '/equipamentos',
    component: Equipments,
    permission: "EquipmentRecord",
  },
  {
    name: "Modelos",
    path: '/modelos',
    component: EquipmentModels,
    permission: "EquipmentModelRecord",
  },
  {
    name: "Embalagens",
    path: '/embalagens',
    component: EquipmentPackings,
    permission: "EquipmentPackingRecord",
  },
  {
    name: "Cores",
    path: '/cores',
    component: EquipmentColors,
    permission: "EquipmentColorRecord",
  },
  {
    name: "Operadoras",
    path: '/operadoras',
    component: EquipmentMobileOperators,
    permission: "EquipmentMobileOperatorRecord",
  },
  {
    name: "Exemplares",
    path: '/exemplares',
    component: EquipmentExemplaries,
    permission: "EquipmentExemplaryRecord",
  }

];

function Records(props) {
  const classes = useStyles();
  const { match } = props;
  const [ breadcrumbs, setBreadcrumbs ] = useState([]);

  return (
    <div className={classes.root}>
      <SubBar breadcrumbs={breadcrumbs} items={pages} match={match}></SubBar>
      {/* Para definir as rotas precisamos importar o compoenente SubRoutes */}
      <SubRoutes setBreadcrumbs={setBreadcrumbs} defaultPage={0} pages={pages} match={match}></SubRoutes>
    </div>
  )
};

export default Records;
