import * as React from "react";
import { motion } from "framer-motion";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: '#FFFFFF88',
    // backgroundColor: '#FFFFFF',
    backgroundColor: '#FFFFFFe6',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 700,
  },
  container: {
    background: `url("${require('assets/img/logo_xglobal_menu.png')}") no-repeat fixed center`,
    width: 72,
    height: 100,
  }
}));

export default function XGlobalLoading({isLoading}) {
  const classes = useStyles();
  return (
    <>
      {isLoading &&
        <div className={classes.loader}>
          <motion.div className={classes.container}
            animate={{
              scale: [0.8, 1.0, 1.0, 0.8, 0.8],
              rotate: [0, 0, 360, 360, 0],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              loop: Infinity,
              repeatDelay: 1
            }}
          />
        </div>
      }
    </>
  );
};
