import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Grid, TextField, Divider, Button,
} from '@material-ui/core';

import { postWithErrorHandler } from 'services/fetch';
import CommandModal from './CommandModal';
import { CommandSimpleToggle } from './CommandToggle';
import { emitEvent } from 'utils/events';
import { CommandTextField } from './Commands';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_5,
  },
  input: {
    borderBottom: theme.input.borderStyle.borderBottom,
  },
  sendCommandBtn: {
    fontWeight: 600,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
  },
}));

const CommandPv1Admin = (props) => {
  const { showStatus } = props;
  const [showModal, setShowModal] = useState(true);
  const classes = useStyles();
  const [DI, setDI] = useState(false);
  const [RC, setRC] = useState(false);
  const [RA, setRA] = useState(false);
  const [BeaconImei, setBeaconImei] = useState('000000000000000');
  const [imei, setImei] = useState(props.imei || '');

  const handleSubmit = async () => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }

    const logMessage = `Comando administrativo enviado ao equipamento ${imei.slice(-8)}`;
    const successMessage = `Comando enviado com sucesso.`;
    const errorMessage = `Erro ao tentar enviar comando.`;
    const response = await postWithErrorHandler('equipment-event/command', {
      imei,
      pv1Fields: {
        DI,
        RC,
        RA,
        BeaconImei,
      },
      action: 'Admin',
    }, {
      successMessage, errorMessage, logMessage
    });
  };

  const onClose = () => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }
  };

  return (
    <CommandModal
      isOpen={showModal}
      onClose={onClose}
      handleSubmit={handleSubmit}
      title="Comandos administrativos protocolo 1"
    >
      <CommandSimpleToggle
        showStatus={showStatus}
        currentStatus={false}
        data={{ label: 'Desativa Módulo' }}
        state={DI}
        setState={setDI}
      />
      <CommandSimpleToggle
        showStatus={showStatus}
        currentStatus={false}
        data={{ label: 'Reseta Firmware' }}
        state={RC}
        setState={setRC}
      />
      <CommandSimpleToggle
        showStatus={showStatus}
        currentStatus={false}
        data={{ label: 'Reseta Ativação' }}
        state={RA}
        setState={setRA}
      />
      <Grid container>
        <Grid item xs={3}>
          <p className={classes.inputLabel}> Ancoragem Beacon </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <CommandTextField
            name="BeaconImei"
            value={BeaconImei}
            setValue={setBeaconImei}
            currentStatus={BeaconImei}
            showStatus={showStatus}
          />
        </Grid>
      </Grid>
      {/* <Divider className={classes.divider}/> */}
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{
          marginTop: 50,
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.sendCommandBtn}
          onClick={handleSubmit}
        >
          Enviar comando
        </Button>
      </Grid>
    </CommandModal>
  );
};
export default CommandPv1Admin;
