import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from "react-router";

import Typography  from "@material-ui/core/Typography";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: props => ({
    left: 40,
    top: 10,
    zIndex: 700,
    position: 'absolute',
    width: 294,
    height: 300,
    borderRadius: theme.border.radius,
    paddingBottom: 0,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    // border: 'solid 1px #d9dde9',
  }),
  header: props => ({
    borderTopLeftRadius: theme.border.radius,
    borderTopRightRadius: theme.border.radius,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 5,
    height: 42,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  content: {
    overflow: 'hidden',
    position: 'relative',
    paddingTop: 16,
    paddingBottom: 0,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  title: props => ({
    fontSize: 16,
    fontWeight: '600',
    color: props.textColor || theme.palette.gray.gray_4,
  }),
  action: {
    marginTop: 'unset',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 60,
    borderTop: 'solid 1px #8084924D',
    marginLeft: 24,
    marginRight: 24,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 16,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  closeIcon: props => ({
    color: props.textColor || theme.palette.gray.gray_4,
  }),
}));

function InfoCard({
  title, detailURI, onCloseClick, children, location,
  headerColor,
}) {
  const classes = useStyles({
    textColor: headerColor && 'white',
  });
  return (
    <div className={classes.container}>
      <div className={classes.header} style={{backgroundColor: headerColor}}>
        <Typography className={classes.title}>{title}</Typography>
          <IconButton className={classes.closeIcon} onClick={onCloseClick}>
            <CloseIcon />
          </IconButton>
      </div>
      <PerfectScrollbar>
        <div className={classes.content}>
          {children}
        </div>
      </PerfectScrollbar>
      {detailURI && (
        <div className={classes.footer}>
          <Link to={{ pathname: detailURI, state: { from: location } }} className={classes.link}>
            Ver detalhes
          </Link>
        </div>
      )}
    </div>
  );
}

InfoCard.propTypes = {
  title: PropTypes.string,
  detailURI: PropTypes.string,
  onCloseClick: PropTypes.func,
  scrollable: PropTypes.bool,
};

InfoCard.defaultProps = {
  onCloseClick: () => null,
  titl: '',
  detailURI: '',
  scrollable: false,
};

export default withRouter(InfoCard);
