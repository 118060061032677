import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { emitEvent } from 'utils/events';
import CircularLoading from 'components/loading/CircularLoading';
import Logo from 'assets/img/header_xglobal.jpg';

const URL = process.env.REACT_APP_SERVER_URL;

function PasswordRecover({ match, history }) {
  const [form, setForm] = useState({
    password: { value: '', error: false, message: '' },
    passwordConfirm: { value: '', error: false, message: '' },
    email: { error: false, message: '' },
  });

  const [errors, setErrors] = useState({
    password: { error: false, message: '' },
    passwordConfirm: { error: false, message: '' },
    email: { error: false, message: '' },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: { ...form[name], value } });
    setErrors({ ...errors, [name]: { error: false, message: '' } });
  };

  const validateForm = () => {
    let isValid = true;
    let _errors = { ...errors };
    if (match.params.hash) {
      if (!form.password.value) {
        _errors = { ..._errors, password: { error: true, message: 'Preenchimento obrigatório' } };
        isValid = false;
      }

      if (form.passwordConfirm.value !== form.password.value) {
        _errors = {
          ..._errors,
          passwordConfirm: { error: true, message: 'Confirmação de senha incorreta' },
        };
        isValid = false;
      }

      if (!form.passwordConfirm.value) {
        _errors = {
          ..._errors,
          passwordConfirm: { error: true, message: 'Preenchimento obrigatório' },
        };
        isValid = false;
      }
    } else {
      if (!form.email.value) {
        _errors = { ..._errors, email: { error: true, message: 'Preenchimento obrigatório' } };
        isValid = false;
      }
    }


    if (!isValid) setErrors(_errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setIsLoading(true);

        let response = {};
        if ( match.params.hash ) {
          response = await fetch(`${URL}auth/update-password`, {
            method: 'post',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ hash: match.params.hash, password: form.password.value }),
          });
        } else {
          response = await fetch(`${URL}auth/recover-password`, {
            method: 'post',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: form.email.value }),
          });
        }

        if (!response.ok) {
          throw new Error();
        } else {
          emitEvent('showSnack', {
            message: `${match.params.hash ? "Nova senha cadastrada com sucesso" : "Solicitação de recuperação realizada"}`,
            type: 'success',
            autoHide: 1000,
          });
          setTimeout(() => {
            history.push('/');
          }, 1500);
        }
      } catch (err) {
        emitEvent('showSnack', {
          message: `Não foi possível realizar esta ação`,
          type: 'error',
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid
        container
        direction="column"
        component={Paper}
        elevation={0}
        style={{ maxWidth: 350, padding: 25, borderRadius: 22 }}
      >
        <Link to="/" style={{ alignSelf: 'center', marginBottom: 25 }}>
          <img alt="Logo XGlobal" src={Logo} style={{ height: 56 }} />
        </Link>
        {match.params.hash ? (
          <>
            <TextField
              required
              label="Nova senha"
              type="password"
              name="password"
              style={{ marginBottom: 15 }}
              autoFocus
              onChange={onChange}
              error={errors.password.error}
              helperText={errors.password.message}
            />
            <TextField
              required
              label="Confirmar nova senha"
              type="password"
              name="passwordConfirm"
              onChange={onChange}
              error={errors.passwordConfirm.error}
              helperText={errors.passwordConfirm.message}
            />
          </>
        ) : (
          <TextField
            required
            label="Email para recuperação de senha"
            type="email"
            name="email"
            onChange={onChange}
            error={errors.email.error}
            helperText={errors.email.message}
          />
        )}
        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: 30, boxShadow: 'unset' }}
          onClick={handleSubmit}
        >
          {match.params.hash ? 'Salvar' : 'Enviar'}
        </Button>
        <CircularLoading isLoading={isLoading} />
      </Grid>
    </Grid>
  );
}

export default PasswordRecover;
